/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormInstance } from 'antd/lib/form';
import _, { isEmpty } from 'lodash';
import { pascalCaseToCamelCase } from './functions';

const getFormItemName = (name: string) => {
  const splitter = name.split(/[\\[.\]]/).filter(Boolean);
  const result = splitter.map((value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      return parsedValue;
    } else {
      return pascalCaseToCamelCase(value);
    }
  });
  return result;
};

export const showErrorAtFormFields = (form?: FormInstance, error?: any): string[] => {
  const inputFieldErrors = _.get(error, 'response.data.errors');

  if (!inputFieldErrors) {
    return [];
  }

  const actualFieldErrors = Object.entries(inputFieldErrors);

  if (!isEmpty(actualFieldErrors)) {
    form?.setFields(
      actualFieldErrors.map(([key, value]) => ({
        name: getFormItemName(key),
        errors: value as string[],
      }))
    );
  }

  return actualFieldErrors.map(([_, value]) => `${value}`);
};
