import React, { useContext, useState } from 'react';

import { LanguageContext } from 'context/language';

import { Tooltip, Typography } from 'antd';

type TooltipParagraphProps = {
  value: string;
};

const TooltipParagraph = ({ value }: TooltipParagraphProps) => {
  const { translate } = useContext(LanguageContext);
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? translate(value) : undefined}>
      <Typography.Paragraph ellipsis={{ rows: 1, onEllipsis: setTruncated }} style={{ marginBottom: 0 }}>
        {translate(value)}
      </Typography.Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
