import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import get from 'lodash/get';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import thTH from 'antd/lib/locale/th_TH';
import msMY from 'antd/lib/locale/ms_MY';
import viVN from 'antd/lib/locale/vi_VN';
import * as LangDictionary from '../languages';
import { APP_REGION, CURRENT_LANGUAGE } from 'utils/constants';

export type Language = 'en' | 'ph' | 'zh' | 'th' | 'ms' | 'vi';
type LanguageObject = {
  value: Language;
  label: string;
};

export interface LanguageContextInterace {
  currentLang: Language;
  listLanguages: LanguageObject[];
  changeLanguage: (input: Language) => void;
  translate: (
    input: string,
    params?: {
      [key in string]: string | number;
    }
  ) => string;
}

const defaultProps: LanguageContextInterace = {
  currentLang: 'en',
  listLanguages: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  translate: () => '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeLanguage: () => {},
};

export const LanguageContext = React.createContext<LanguageContextInterace>(defaultProps);

const LanguageContextProvider: FunctionComponent = ({ children }: PropsWithChildren<unknown>) => {
  const getDefaultLanguage = useCallback(() => {
    const env = APP_REGION;
    let defaultLanguage: Language;
    switch (env) {
      case 'ph':
        defaultLanguage = 'ph';
        break;
      case 'my':
        defaultLanguage = 'en';
        break;
      case 'th':
        defaultLanguage = 'th';
        break;
      case 'vn':
        defaultLanguage = 'vi';
        break;
      default:
        defaultLanguage = 'en';
        break;
    }
    return (localStorage.getItem(CURRENT_LANGUAGE) || defaultLanguage) as Language;
  }, []);

  const [currentLang, setCurrentLang] = React.useState(getDefaultLanguage());

  const translate = useCallback(
    (
      key: string,
      params: {
        [key in string]: string | number;
      } = {}
    ) => {
      let translated = get(LangDictionary, `${currentLang}.${key}`) || key;

      if (params) {
        Object.entries(params).forEach(([key, val]) => {
          translated = translated.replace(key, val?.toString());
        });
      }

      return translated;
    },
    [currentLang]
  );

  const changeLanguage = useCallback((newLang: Language) => {
    localStorage.setItem(CURRENT_LANGUAGE, newLang);
    setCurrentLang(newLang);
    window.location.reload();
  }, []);

  const locale = React.useMemo(() => {
    switch (currentLang) {
      case 'zh':
        return zhCN;
      case 'th':
        return thTH;
      case 'ms':
        return msMY;
      case 'vi':
        return viVN;
      default:
        return enUS;
    }
  }, [currentLang]);

  return (
    <LanguageContext.Provider
      value={{
        listLanguages: [
          {
            value: 'en',
            label: 'EN',
          },
          {
            value: 'ph',
            label: 'PH',
          },
          {
            value: 'zh',
            label: 'ZH',
          },
          {
            value: 'th',
            label: 'TH',
          },
          {
            value: 'ms',
            label: 'MS',
          },
          {
            value: 'vi',
            label: 'VI',
          },
        ],
        currentLang,
        changeLanguage,
        translate,
      }}
    >
      <ConfigProvider locale={locale}>{children}</ConfigProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
