import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import useSWR from 'swr';

import { LanguageContext } from 'context/language';
import Order from 'models/Order';
import { DeliveryStatus, ArchivedStatusDisplayOption, StatusUpdateLog } from 'models/DeliveryStatus';
import { updateCartReason, updateCartNote } from 'apis/carts';
import {
  updateDeliveryStatus,
  massUpdateDeliveryStatus,
  updateShipmentReason,
  updateShipmentNote,
  getCustomerAvatar,
  getCartDetailList,
  mergeOrders,
  updateDeliveryInstruction,
  unMergeOrders,
  updateInternalNote,
} from 'apis/orders';
import { useSegement, useShowError } from 'utils/hooks';
import { getGenericErrors } from 'utils/error';
import { Dictionary, compact, isEmpty, keyBy, capitalize } from 'lodash';
import { DownloadInvoicesContext } from 'context/invoices';
import Cart from 'models/Cart';
import { requestParamsFromObject } from 'utils/request';
import { countOrderIds, getOrderIds } from 'apis/search';
import { ChildOrder, MergeableOrder } from 'models/MergeableOrder';
import { AssociateAccount } from 'models/AssociateAccount';
import { convertESCartOrderData, generateCommonTrackingData } from 'utils/functions';
import { removeDuplicateString } from 'utils/functions2';
import { DELIVERY_INSTRUCTION_MAX_LENGTH } from 'utils/constants';

export const useGetOrdersDetail = ({ ids }: { ids?: (string | undefined)[] | undefined | null }) => {
  const queryIds = (ids || [])
    .filter((id) => !!id)
    .map((id) => `ids=${id}`)
    .join('&');

  const result = useSWR<Order[]>(ids ? `/cart-service/api/orders/detail?${queryIds}` : null);

  const dataSort = result.data
    ? (ids || []).map((id) => result.data?.find((item) => item.cartId === id) as Order)
    : undefined;

  return {
    ...result,
    data: dataSort,
    isLoading: (!result.data && !result.error) || result.isValidating,
  };
};

export const useUpdateDeliveryStatus = () => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const { analyticsTrack } = useSegement();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateDeliveryStatus = useCallback(
    async ({
      // cartId,
      shipmentId,
      newDeliveryStatus,
      // oldDeliveryStatus,
      archivedStatusDisplayOption,
      isSendShippingNoti = false,
      onUpdateSuccess,
    }: {
      // cartId?: string;
      shipmentId?: string;
      // oldDeliveryStatus?: DeliveryStatus;
      newDeliveryStatus?: DeliveryStatus;
      archivedStatusDisplayOption?: ArchivedStatusDisplayOption;
      isSendShippingNoti?: boolean;
      onUpdateSuccess?: () => void;
    }) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsLoading(true);
      try {
        await updateDeliveryStatus({
          shipmentIds: [shipmentId || ''],
          newDeliveryStatus,
          archivedStatusDisplayOption,
          isSendShippingNoti,
        });
        message.success(translate('update_status_successfully'));
        onUpdateSuccess?.();
        // analyticsTrack('Updated Status at Paid Orders', {
        //   cart_id: [cartId],
        //   num_updated: 1,
        //   current_status: oldDeliveryStatus,
        //   updated_status: newDeliveryStatus,
        //   ...commonTrackingEventData,
        // });
        analyticsTrack('Shipping Noti Actions Taken', {
          num_orders: 1,
          action: isSendShippingNoti ? 'Confirmed Sending Noti at Paid Order' : 'Denied Sending Noti at Paid Order',
          page: 'paid order',
          ...commonTrackingEventData,
        });
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('update_status_error'), translate('update_status_error_description'));
      } finally {
        setIsLoading(false);
      }
    },
    [analyticsTrack, showError, translate]
  );

  return { handleUpdateDeliveryStatus, isUpdatingStatus: isLoading };
};

export const useMassUpdateDeliveryStatus = () => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const { analyticsTrack } = useSegement();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMassUpdateDeliveryStatus = useCallback(
    async ({
      // cartIds,
      shipmentIds,
      newDeliveryStatus,
      archivedStatusDisplayOption,
      isSendShippingNoti = false,
      onUpdateSuccess,
    }: {
      // cartIds?: string[];
      shipmentIds?: string[];
      newDeliveryStatus?: DeliveryStatus;
      archivedStatusDisplayOption?: ArchivedStatusDisplayOption;
      isSendShippingNoti?: boolean;
      onUpdateSuccess?: (updatedSuccessQuantity: number) => void;
    }) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsLoading(true);
      try {
        const response = await massUpdateDeliveryStatus({
          shipmentIds,
          newDeliveryStatus,
          archivedStatusDisplayOption,
          isSendShippingNoti,
        });
        onUpdateSuccess?.(response?.length || 0);
        // analyticsTrack('Updated Status at Paid Orders', {
        //   cart_id: cartIds,
        //   num_updated: shipmentIds?.length || 0,
        //   updated_status: newDeliveryStatus,
        //   ...commonTrackingEventData,
        // });
        analyticsTrack('Shipping Noti Actions Taken', {
          num_orders: shipmentIds?.length || 0,
          action: isSendShippingNoti ? 'Confirmed Sending Noti at Paid Order' : 'Denied Sending Noti at Paid Order',
          page: 'paid order',
          ...commonTrackingEventData,
        });
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('update_status_error'), translate('update_status_error_description'));
      } finally {
        setIsLoading(false);
      }
    },
    [showError, translate, analyticsTrack]
  );

  return { handleMassUpdateDeliveryStatus, isMassUpdatingStatus: isLoading };
};

export const useUpdateReason = () => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateReason = useCallback(
    async (id?: string, reason?: string, type?: 'cart' | 'order', onUpdateSuccess?: () => void) => {
      setIsLoading(true);
      try {
        type === 'cart'
          ? await updateCartReason({ cartId: id, reason })
          : await updateShipmentReason({ shipmentId: id, reason });
        message.success(translate('add_reason_successfully'));
        onUpdateSuccess?.();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('add_reason_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [showError, translate]
  );

  return { handleUpdateReason, isUpdatingReason: isLoading };
};

export const useUpdateNote = () => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateNote = useCallback(
    async (ids?: string[], note?: string, type?: 'cart' | 'order', onUpdateSuccess?: () => void) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsLoading(true);
      try {
        type === 'cart' ? await updateCartNote({ ids, note }) : await updateShipmentNote({ ids, note });
        analyticsTrack(type === 'cart' ? 'Invoice Note Added To Pending Orders' : 'Invoice Note Added To Paid Orders', {
          characters: note,
          order_ids: ids,
          character_length: note?.length || 0,
          ...commonTrackingEventData,
        });
        onUpdateSuccess?.();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('add_invoice_note_error'), translate('add_invoice_note_error_description'));
      } finally {
        setIsLoading(false);
      }
    },
    [showError, translate, analyticsTrack]
  );

  return { handleUpdateNote, isUpdatingNote: isLoading };
};

export const useFetchCustomerAvatars = (customerIds: string[]) => {
  const ids = compact(customerIds);
  const [avatars, setAvatars] = useState<{ [id: string]: string }>({});
  const { data: customerAvatars, isValidating: isFetchingAvatars } = useSWR<{ [id: string]: string }>(
    ['customer-avatar', ...ids],
    getCustomerAvatar(ids)
  );

  useEffect(() => {
    setAvatars((prev) => ({ ...prev, ...(customerAvatars || {}) }));
  }, [customerAvatars]);

  return {
    customerAvatars: avatars,
    isFetchingAvatars,
  };
};

export const useFetchStatusUpdateLogs = ({ shipmentId }: { shipmentId: string | boolean }) => {
  const currentQueryString: string = useMemo(() => {
    return `cart-service/api/shipments/logs/${shipmentId}`;
  }, [shipmentId]);

  const { data, isValidating: isLoadingStatusUpdateLogs } = useSWR<{ total: number; data: StatusUpdateLog[] }>(
    shipmentId ? currentQueryString : null
  );

  return { statusUpdateLogs: data?.data || [], total: data?.total || 0, isLoadingStatusUpdateLogs };
};

export const useGetInvoiceProcessing = (type: 'orders' | 'carts') => {
  const { setValue } = useContext(DownloadInvoicesContext);

  const { data } = useSWR<string>(`cart-service/api/${type}/invoices/processing`);

  useEffect(() => {
    if (data) {
      setValue((prevValue) => {
        if (prevValue[data]) {
          return prevValue;
        }
        return {
          ...prevValue,
          [data]: {
            processingId: data,
            type,
          },
        };
      });
    }
  }, [data, setValue, type]);
};

// use for VN only, don't have shipment
export const useOrderDetail = (id: string) => {
  const idList = useMemo(() => {
    if (id) {
      return [id];
    }
  }, [id]);

  const { data: detailList, revalidate: fetchOrderDetail } = useSWR<{ data: Order[] }>(
    !isEmpty(idList) ? `/cart-service/api/search/carts/ids${idList}` : null,
    getCartDetailList({ Ids: idList })
  );
  const orders = useMemo<Dictionary<Cart>>(() => {
    return detailList?.data?.reduce((obj: Dictionary<Order>, cur) => ({ ...obj, [cur.id || '']: cur }), {}) || {};
  }, [detailList]);
  const order = useMemo(() => {
    if (id && orders?.[id]) {
      return orders?.[id];
    }

    return null;
  }, [id, orders]);

  return {
    order,
    fetchOrderDetail,
  };
};

// use for VN only, doesn't have shipment
export const useFetchAllOrders = ({
  enabled = true,
  filter,
  pageSize = 10,
  orderBy,
  orderDirection,
}: {
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: any;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: string;
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[] | null>(null);
  const { data: total = 0 } = useSWR(`totalOrdersFiltered${requestParamsFromObject(filter)}`, countOrderIds(filter));

  const handleFetchOrders = useCallback(async () => {
    setIsFetching(true);
    let orders: Order[] | null = null,
      pageNumber = 1;
    while (pageNumber === 1 || pageNumber <= Math.ceil(total / pageSize)) {
      const resES = await getOrderIds(
        filter,
        pageNumber,
        pageSize || 10,
        orderBy || 'created',
        orderDirection || 'desc'
      )();

      const resDetail = convertESCartOrderData('order', resES.data.data);

      orders = [...(orders || []), ...resDetail];
      pageNumber++;
    }
    setOrders(orders);
    setIsFetching(false);
  }, [filter, orderBy, orderDirection, pageSize, total]);

  useEffect(() => {
    if (enabled) {
      setOrders(null);
      handleFetchOrders();
    }
  }, [enabled, handleFetchOrders]);

  return {
    ordersData: orders,
    isFetching,
    setOrders,
  };
};

export const useGetMergeableOrders = ({ enabled }: { enabled: boolean }) => {
  const { data, isValidating } = useSWR<{ childOrders: ChildOrder[] }[]>(
    enabled ? '/cart-service/api/orders/merge' : null
  );

  const { mergeableOrders, childOrders } = useMemo(() => {
    let mergeableOrders: MergeableOrder[] | undefined, childOrders: Order[] | undefined;
    data?.forEach((item, index) => {
      const orders = item.childOrders.map((child) => {
        let buyerApp: AssociateAccount[] = [],
          commenter: AssociateAccount[] = [];
        if (!isEmpty(child.buyers)) {
          buyerApp =
            child?.buyers?.map((buyer) => ({
              id: buyer.id,
              name: buyer.name,
              type: 'buyerApp',
            })) || [];
        }
        const buyerIds = child?.buyers?.map((item) => item.id);
        if (child.customerId && !buyerIds?.includes(child.customerId)) {
          commenter = [
            {
              id: child.customerId || '',
              name: capitalize(child.customerName || ''),
              type: child.customerType || 'buyerApp',
            },
          ];
        }
        const associatedAccounts = [...commenter, ...buyerApp];
        return { ...child, associatedAccounts };
      });
      if (!mergeableOrders) {
        mergeableOrders = [];
      }
      if (!childOrders) {
        childOrders = [];
      }
      mergeableOrders.push({
        id: String(index + 1),
        orders: keyBy(orders, 'id'),
      });
      childOrders.push(...orders);
    });
    return { mergeableOrders, childOrders };
  }, [data]);

  return { mergeableOrders, childOrders, isLoading: isValidating };
};

export const useMergeOrders = ({ onSucess }: { onSucess: () => void }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMerge = useCallback(
    async (data: Dictionary<MergeableOrder>) => {
      setIsLoading(true);
      try {
        const payload = Object.keys(data).map((item) => {
          const order = data[item];
          const recipientNames: string[] = [],
            deliveryInstructions: string[] = [];
          let deliveryFee = 0;
          const childIds = Object.keys(order.orders);
          childIds.forEach((id) => {
            const child = order.orders[id];
            recipientNames.push(child.deliveryDetails?.recipient || '');
            deliveryInstructions.push(child.deliveryDetails?.deliveryInstruction || '');
            deliveryFee += child.deliveryDetails?.deliverySurcharge || 0;
          });
          return {
            childIds,
            deliveryFee: order.newDeliveryFee ?? deliveryFee,
            recipientName: order.recipientName || removeDuplicateString(recipientNames),
            internalNote: order.internalNote || '',
            deliveryInstruction:
              order.deliveryInstruction ||
              removeDuplicateString(deliveryInstructions).substring(0, DELIVERY_INSTRUCTION_MAX_LENGTH),
          };
        });
        await mergeOrders(payload);
        onSucess();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('merge_orders_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [onSucess, showError, translate]
  );

  return { handleMerge, isLoading };
};

export const useUpdateDeliveryInstruction = ({ onSucess }: { onSucess: () => void }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = useCallback(
    async (data: { orderId: string; deliveryInstruction: string }) => {
      setIsLoading(true);
      try {
        await updateDeliveryInstruction(data);
        message.success(translate('updated_successfully'));
        onSucess();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('update_delivery_instruction_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [onSucess, showError, translate]
  );

  return { handleUpdate, isLoading };
};

export const useGetOrderDetail = ({
  orderId,
  rawData,
  enable = true,
  shipmentIds,
}: {
  orderId: string;
  rawData?: Order;
  enable?: boolean;
  shipmentIds?: string[];
}) => {
  const url = useMemo(() => {
    if (!enable) return null;
    if (shipmentIds) {
      const queryString = shipmentIds.map((o) => `shipmentIds=${o}`).join('&');
      return `/cart-service/api/orders/${orderId}?${queryString}`;
    }
    return `/cart-service/api/orders/${orderId}`;
  }, [enable, orderId, shipmentIds]);

  const { data, isValidating, revalidate } = useSWR<Order>(url);

  const order = useMemo(() => {
    if (!rawData) return data;
    return {
      ...rawData,
      ...(data || {}),
      childrenOrders: rawData.childrenOrders?.map((item) => {
        const children = data?.childrenOrders?.find((o) => o.id === item.id);
        return {
          ...item,
          ...children,
          associatedAccounts: item?.associatedAccounts,
        };
      }),
    };
  }, [rawData, data]);

  return { order, isLoading: isValidating, revalidate };
};

export const useUnmergeOrders = ({ onSucess }: { onSucess: () => void }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUnmerge = useCallback(
    async (shipmentId: string) => {
      setIsLoading(true);
      try {
        await unMergeOrders(shipmentId);
        onSucess();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('un_merge_orders_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [onSucess, showError, translate]
  );

  return { handleUnmerge, isLoading };
};

export const useUpdateInternalNote = ({ onSucess }: { onSucess: () => void }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = useCallback(
    async (data: { orderId: string; internalNote: string }) => {
      setIsLoading(true);
      try {
        await updateInternalNote(data);
        onSucess();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('update_internal_note_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [onSucess, showError, translate]
  );

  return { handleUpdate, isLoading };
};
