import { SubLevelLocation } from './Address';
import Order from './Order';

export enum FulfillStep {
  COURIER = 1,
  PARCEL_INFO = 2,
  PICKUP_POINT = 3,
  VERIFY = 4,
  COMPLETE = 5,
}

export enum FulfillSourcePage {
  PAID_ORDERS = 'PAID_ORDERS',
  TO_SHIP = 'TO_SHIP',
}

export enum FulfillStatus {
  TO_SHIP = 'to-ship',
  SHIPPING = 'shipping',
  DELIVERED = 'delivered',
  FAILED_DELIVERY = 'failed_delivery',
  // cancelled is removed from the BE. When canceled the order will be return to paid order.
  CANCELLED = 'cancelled',
  ARCHIVE = 'archive',
}

export enum ToShipOrderStatus {
  ADDRESS_NOT_SERVICEABLE = 'address_not_servicable',
  TO_SHIP = 'to_ship',
  DELIVERY_ADDRESS_NOT_SERVICEABLE = 'delivery_address_not_serviceable',
  PICKUP_ADDRESS_NOT_SERVICEABLE = 'pickup_address_not_serviceable',
  BOTH_ADDRESSES_NOT_SERVICEABLE = 'both_addresses_not_serviceable',
}

export interface ToShipOrder {
  id?: number;
  cartId?: string;
  status?: ToShipOrderStatus;
  incorrectInformation?: string[];
  shippingGroupId?: string;
  fulfillmentId?: string;
}

export interface FulfillOrder {
  id?: string;
  step?: FulfillStep;
  createdAt?: string;
  updatedAt?: string;
  shipping?: {
    courierId?: string;
    courierDeliveryPlanId?: string;
  };
  orderDetails?: {
    shippingGroupId?: string;
    cartId?: string;
    width?: string;
    length?: string;
    height?: string;
    weight?: string;
    estimatedFee?: number;
    addressEditable?: boolean;
    deliveryPhoneEditable?: boolean;
    deliveryAddress?: {
      address1: string;
      address2: string;
      postalCode: string;
      countryCode: string;
      deactivate: boolean;
      unitNumber: string;
      subLevelLocations: SubLevelLocation[];
      addressId: number;
      cartId: string;
      recipientName: string;
      recipientPhoneNumber: string;
    };
  }[];
  pickupDetail?: {
    pickupPointId?: string;
    pickupTime?: string;
    pickupInstruction?: string;
    pickupStartTime?: string;
    pickupEndTime?: string;
  };
  fulfillmentId?: string;
}
export interface DimensionLimitData {
  minLength: number;
  maxLength: number;

  minWidth: number;
  maxWidth: number;

  minHeight: number;
  maxHeight: number;

  minWeight: number;
  maxWeight: number;

  maximumDimension: number;
}

export type OrderWithToShipStatus = Order & {
  toShipStatus?: ToShipOrderStatus;
  incorrectInformation?: string[];
  shippingGroupId?: string | null;
  paidOrderId?: number;
  fulfillmentId?: string;
};

export interface RequestArrangeShipResponse {
  fulfillmentId: string;
}

export interface FulfillmentResult {
  orderId: string;
  orderReferenceCode: string;
  errorCode: string;
  errorMessage?: string;
  isSuccess: boolean;
}
