import React, { useContext, useRef, useState } from 'react';
import { Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { Profile } from 'models/Profile';
import UserSpace from 'models/UserSpace';
import { useAddHelppierScripts, useSegement, useViewportWidth } from 'utils/hooks';
import { listBlockDomains, pages } from 'utils/functions';
import { listBlockedMerchant } from 'utils/functions';
import { COLLAPSED_SIDER_WIDTH, CURRENT_SHOP_STORAGE_KEY, PROFILE_ID, SIDER_WIDTH } from 'utils/constants';
import { useLogout } from 'hooks/logout';

import Navbar from '../Navbar/Navbar';
import BlockModal from 'shared-components/BlockModal';
import Header from 'shared-components/Header';
import HeaderProvider from 'shared-components/Header/HeaderProvider';

import { ReactComponent as UpmeshLogo } from 'assets/upmesh_logo.svg';
import { ReactComponent as UpmeshLogoSmall } from 'assets/upmesh_logo_small.svg';

import './styles.css';

const { Content, Sider } = Layout;

export interface TemplateWrapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
}

export const ResponsiveState = {
  ALL: 0,
  SM: 1,
  MD: 2,
  LG: 3,
  XL: 4,
  XXL: 5,
};

interface LayoutContextInterface {
  responsiveState: number;
  collapsedSideBar: boolean;
  showSideNav: boolean;
  setShowSideNav: (value: boolean) => void;
}

export const LayoutContext = React.createContext<LayoutContextInterface>({
  responsiveState: ResponsiveState.ALL,
  collapsedSideBar: false,
  showSideNav: true,
  setShowSideNav: () => null,
});

export const useLayout = () => useContext(LayoutContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LayoutTemplate: React.FunctionComponent<TemplateWrapperProps & any> = ({
  component: Component,
  children,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TemplateWrapperProps & any) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showModalBlock, setShowModalBlock] = useState(false);
  const [showSideNav, setShowSideNav] = useState(true);

  const viewportWidth = useViewportWidth();
  const { analyticsPage, analyticsIdentify } = useSegement();
  const { logout } = useLogout();

  const { data: profile } = useSWR<Profile>('/profile-service/api/profile');

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const [responsiveState, setResponsiveState] = React.useState<number>(0);

  const handleRedirect = React.useCallback(() => {
    setShowModalBlock(false);
    logout();
  }, [logout]);

  React.useEffect(
    () =>
      setResponsiveState(
        viewportWidth >= 1900
          ? ResponsiveState.XXL
          : viewportWidth >= 1280
          ? ResponsiveState.XL
          : viewportWidth >= 1024
          ? ResponsiveState.LG
          : viewportWidth >= 768
          ? ResponsiveState.MD
          : viewportWidth >= 640
          ? ResponsiveState.SM
          : ResponsiveState.ALL
      ),
    [viewportWidth]
  );

  React.useEffect(() => {
    if (profile && pages[location.pathname]) {
      analyticsPage(pages[location.pathname]);
      analyticsIdentify(localStorage.getItem(PROFILE_ID) || profile.id, {
        name: profile.name,
        business_name: profile.businessName,
        email: profile.email,
        link_to_contact: profile.linkToContact,
        estimated_streaming_hours: profile.estimatedStreamingTime,
        cart_expiry: profile.cartExpiry,
        online_payment_enabled: profile.onlinePaymentEnabled,
        offline_payment_enabled: profile.offlinePaymentEnabled,
        payment_proof_required: profile.offlinePaymentProofRequired,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, profile]);

  React.useEffect(() => {
    const tmpShop = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);
    const defaultShopStorage: UserSpace = tmpShop && JSON.parse(tmpShop);
    if (
      defaultShopStorage &&
      listBlockDomains.includes(window.location.hostname) &&
      listBlockedMerchant.includes(defaultShopStorage.ownerKey)
    ) {
      setShowModalBlock(true);
    }
  }, []);

  React.useEffect(() => {
    const tmpShop = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);
    const defaultShopStorage: UserSpace = tmpShop && JSON.parse(tmpShop);
    if (
      defaultShopStorage &&
      listBlockDomains.includes(window.location.hostname) &&
      listBlockedMerchant.includes(defaultShopStorage.ownerKey)
    ) {
      setShowModalBlock(true);
    }
  }, []);
  useAddHelppierScripts(profile);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutContext.Provider
      value={{
        responsiveState,
        collapsedSideBar: collapsed,
        setShowSideNav,
        showSideNav,
      }}
    >
      <Layout className="h-full">
        {showSideNav && (
          <Sider
            id="dashboard-sider"
            collapsible
            width={SIDER_WIDTH}
            collapsedWidth={COLLAPSED_SIDER_WIDTH}
            collapsed={collapsed}
            onCollapse={onCollapse}
            className="z-10 bg-gray-300"
            breakpoint="lg"
            theme="light"
          >
            <div className="h-full" ref={parentRef}>
              <Link to="/" className="flex w-full justify-center items-center h-16 bg-white">
                {collapsed ? <UpmeshLogoSmall /> : <UpmeshLogo />}
              </Link>
              <Navbar parentRef={parentRef} collapsed={collapsed} />
            </div>
          </Sider>
        )}
        <Content className="relative">
          <HeaderProvider>
            <Header />
            {Component ? <Component {...props} /> : children}
          </HeaderProvider>
        </Content>
        <BlockModal show={showModalBlock} onAgree={handleRedirect} />
      </Layout>
    </LayoutContext.Provider>
  );
};

export default LayoutTemplate;
