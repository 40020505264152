import React, { createContext, FunctionComponent, PropsWithChildren, useState } from 'react';

interface UserSettingsContextInterface {
  showPinCode: boolean;
  showPostRevenue: boolean;
  setShowPinCode: (value: boolean) => void;
  setShowPostRevenue: (value: boolean) => void;
}

export const UserSettingsContext = createContext<UserSettingsContextInterface>({
  showPinCode: false,
  showPostRevenue: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowPinCode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowPostRevenue: () => {},
});

const AppSettings: FunctionComponent = ({ children }: PropsWithChildren<unknown>) => {
  const [showPinCode, setShowPinCode] = useState(false);
  const [showPostRevenue, setShowPostRevenue] = useState(false);

  return (
    <UserSettingsContext.Provider
      value={{
        showPinCode,
        setShowPinCode,
        showPostRevenue,
        setShowPostRevenue,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export default AppSettings;
