import React from 'react';
import { Button, Modal } from 'antd';

import T from 'shared-components/Translator';

interface CompletedProfileModalProps {
  onClose: () => void;
}

const CompletedProfileModal = ({ onClose }: CompletedProfileModalProps) => {
  return (
    <Modal
      className="profile-modal"
      open={true}
      title={
        <T value="please_fill_information_section" params={{ '[SECTION]': 'Delivery Settings and Payment Settings' }} />
      }
      onCancel={onClose}
      centered
      width={500}
      footer={null}
    >
      <div className="flex justify-between">
        <Button
          type="primary"
          onClick={() => {
            window.location.href = '/payment';
            onClose();
          }}
        >
          <T value="go_to_payment_settings" />
        </Button>
        <Button
          type="primary"
          onClick={() => {
            window.location.href = '/delivery';
            onClose();
          }}
        >
          <T value="go_to_delivery_settings" />
        </Button>
      </div>
    </Modal>
  );
};

export default CompletedProfileModal;
