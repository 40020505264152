enum PaymentStatus {
  PaymentPending,
  PaymentRejected,
  PaymentCancelled,
  PaymentFailed,
  PaymentPaid,
  Unpaid,
  PartiallyPaid,
}

export default PaymentStatus;
