import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GoogleAnalytics = () => {
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_CODE) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    }
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
  return null;
};

export default withRouter(GoogleAnalytics);
