import { PaymentProvider } from './PaymentKYC';
import { Profile } from './Profile';

export enum TransactionStatus {
  Created = 0,
  Succeeded = 1,
  Failed = 2,
  Cancelled = 3,
  Rejected = 4,
  Settled = 5,
  Refunded = 6,
  Withdrawable = 7,
}

export enum PaymentMethod {
  Card = 1,
  Paynow = 2,
  Grab = 3,
  Offline = 4,
  GCashPaymongo = 5,
  CardPaymongo = 6,
  GrabPaymongo = 7,
  CardStripe = 8,
  GrabStripe = 9,
  PaynowStripe = 10,
  Boost2c2p = 11,
  FPX2c2p = 12,
  GrabPay2c2p = 13,
  Card2c2p = 14,
  AliPay2c2p = 15,
  WechatPay2c2p = 16,
  Shopeepay2c2p = 17,
  PromptPay2c2p = 18,
  TouchNGo2c2p = 19,
  OfflineBDO = 21,
  OfflineBPI = 22,
  OfflinePayMaya = 23,
  OfflineGCash = 24,
}

export const OFFLINE_PAYMENT = [
  PaymentMethod.Offline,
  PaymentMethod.OfflineBDO,
  PaymentMethod.OfflineBPI,
  PaymentMethod.OfflineGCash,
  PaymentMethod.OfflinePayMaya,
];

export enum PaymentMethodName {
  CARD = 'CARD',
  PAYNOW = 'PAYNOW',
  GRAB = 'GRAB',
  BOOST2C2P = 'BOOST2C2P',
  GCASHPAYMONGO = 'GCASHPAYMONGO',
  CARDPAYMONGO = 'CARDPAYMONGO',
  GRABPAYMONGO = 'GRABPAYMONGO',
  CARDSTRIPE = 'CARDSTRIPE',
  GRABSTRIPE = 'GRABSTRIPE',
  PAYNOWSTRIPE = 'PAYNOWSTRIPE',
  CARD2C2P = 'CARD2C2P',
  FPX2C2P = 'FPX2C2P',
  GRABPAY2C2P = 'GRABPAY2C2P',
  ALIPAY2C2P = 'ALIPAY2C2P',
  WECHATPAY2C2P = 'WECHATPAY2C2P',
  SHOPEEPAY2C2P = 'SHOPEEPAY2C2P',
  PROMPTPAY2C2P = 'PROMPTPAY2C2P',
  TOUCHNGO2C2P = 'TOUCHNGO2C2P',
}

export type Transaction = {
  id: string;
  cardHolderNumber?: string;
  cardHolderIP?: string;
  created?: string;
  lastModified?: string;
  paymentMethodType?: PaymentMethod;
  gatewayProvider?: PaymentProvider;
  transactionStatus?: TransactionStatus;
  fraudResponseCode?: string;
  fraudResponseDescription?: string;
  voidedAt?: string;
  settledAt?: string;
  refundedAt?: string;
  totalAmount?: number;
  fee?: number;
  paymentId?: string;
  cartId?: string;
  merchantId?: string;
  merchantName?: string;
  orderId?: string;
  providerCreatedDate?: string;
  cardType?: string;
  netAmount?: number;
  withdrawableAt?: string;
  businessPaymentProviderId?: string;
  qrCode?: string;
  businessProfileId?: string;
  deletedAt?: string;
  isDelete?: boolean;
  paymentBalanceId?: string;
  currency?: string;
  payoutId?: string;
  buyerFeeAmount?: number;
  merchantFeeAmount?: number;
};

export type AccountHistory = {
  id: string;
  date: string;
  description: string;
  debit: string;
  withdrawableFee: number;
  credit: number;
  availableBalance: number;
};

export type AccountOptions = {
  merchantId: string;
  tradingName: string;
  merchantName: string;
  merchantLogo: string;
  contactPerson?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  contactEmail?: string;
  merchantSupportEmail?: string;
  address?: string;
};

export enum MerchantStatus {
  Reject = -1,
  Incomplete = 0,
  Verified = 1,
  Pending = 2,
}

export type Merchant = {
  id: string;
  merchantName: string;
  fullname: string;
  email: string;
  lastLogin: string;
  entityName: string;
  businessRegistrationNo: number;
  expiryDate: string;
  phoneNumber: string;
  status: MerchantStatus;
  cardsPayments?: string;
  eWalletPayments?: string;
  feePercent?: number;
};

export type MerchantDetails = {
  businessName?: string;
  email?: string;
  id: string;
  mobileNumber?: string;
  name?: string;
  registrationNo?: string;
  status: MerchantStatus;
  entityName?: string;
};

export type SettlementTimingType = {
  businessProfileId: string;
  id: string;
  paymentProvider: string;
  payoutDelayDays: number;
};

export type MdrRateType = {
  amount: number;
  fixedCost: number;
  percentage: number;
  source: string;
  isDomestic: boolean;
  isInternational: boolean;
};
export interface PaymentTransaction {
  id?: string;
  availableOn: string;
  providerCreatedDate: string;
  amount: number;
  net: number;
  fee: number;
  feeInDollar: number;
  exchangeRate: number;
  currency: string;
  description?: string;
  sourceId?: string;
  type: string;
  status: string;
  paymentAccountId: string;
  businessProfileId: string;
  businessProfile: Profile;
  amountInDollar: number;
  netInDollar: number;
}
export interface PayoutHistory {
  id: string;
  amount: number;
  arrivalDate: string;
  automatic: boolean;
  balanceTransactionId: string | null;
  providerCreatedDate: string;
  currency: string;
  description: string;
  destination: string;
  failureBalanceTransactionId: string | null;
  failureCode: string;
  failureMessage: string | null;
  metadata: unknown;
  method: string;
  originalPayoutId: string | null;
  reversedById: string;
  sourceType: string | null;
  statementDescriptor: string | null;
  status: string;
  type: string | null;
  paymentProvider: string | null;
  amountInDollar: number;
  totalTransfer: number;
  totalGross: number;
  totalFee: number;
  totalNet: number;
}

export interface ExportPayoutHistoryQuery {
  from?: string;
  to?: string;
}

export interface GetPayoutQuery extends ExportPayoutHistoryQuery {
  pageNumber?: number;
  pageSize?: number;
}
export interface ExportCardTransactionsQuery {
  from?: string;
  to?: string;
  orderId?: string;
  transactionAmountFrom?: string;
  transactionAmountTo?: string;
  settlementFrom?: string;
  settlementTo?: string;
  transactionStatus?: string;
  paymentMethodType?: string;
  cardLast4Digit?: string;
}
