import { Button, Space, Typography } from 'antd';
import React, { ReactNode, useContext, useMemo } from 'react';
import classnames from 'classnames';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { LanguageContext } from 'context/language';
import { useViewportWidth } from 'utils/hooks';
import { COLLAPSED_SIDER_WIDTH, MAX_WIDTH, SIDER_WIDTH } from 'utils/constants';

import { LayoutContext } from './layout/LayoutTemplate';
import T from './Translator';

type CancelSaveFooterProps = {
  isLoading?: boolean;
  okText?: string;
  cancelText?: string;
  maxWidth?: number;
  helpLink?: string;
  error?: React.ReactNode;
  noMaxWidth?: boolean;
  extraContent?: ReactNode;
  fullWidth?: boolean;
  onCancel?: () => void;
  onOk: () => void;
  disabledOk?: boolean;
  extraActionBtn?: ReactNode;
};

const CancelSaveFooter = ({
  isLoading,
  okText = 'save',
  cancelText = 'cancel',
  maxWidth = MAX_WIDTH,
  helpLink,
  error,
  noMaxWidth = false,
  extraContent,
  fullWidth = true,
  disabledOk = false,
  onCancel,
  onOk,
  extraActionBtn,
}: CancelSaveFooterProps) => {
  const { translate } = useContext(LanguageContext);
  const { collapsedSideBar } = useContext(LayoutContext);

  const viewportWidth = useViewportWidth();
  const siderWidth = useMemo(() => (collapsedSideBar ? COLLAPSED_SIDER_WIDTH : SIDER_WIDTH), [collapsedSideBar]);
  const bodyWidth = useMemo(
    () => (viewportWidth > siderWidth ? viewportWidth - siderWidth : 0),
    [siderWidth, viewportWidth]
  );
  const width = useMemo(() => (noMaxWidth ? bodyWidth : maxWidth), [bodyWidth, maxWidth, noMaxWidth]);

  return (
    <div
      className={classnames('fixed w-full shadow-md', { 'bg-white': fullWidth })}
      style={{ left: 0, bottom: 0, zIndex: 4 }}
    >
      <div className="ml-auto" style={{ width: bodyWidth }}>
        <div
          className={classnames('flex w-full mx-auto py-2', {
            'justify-between': helpLink || extraContent,
            'justify-end': !helpLink && !extraContent,
            'items-center': extraContent,
            'bg-white px-4': !fullWidth,
            'px-8': bodyWidth <= width,
          })}
          style={{
            maxWidth: width,
          }}
        >
          {helpLink && (
            <Space className="cursor-pointer">
              <QuestionCircleOutlined style={{ color: '#1890FF' }} />
              <Typography className="text-primary">
                <a href={helpLink} target="_blank" rel="noreferrer">
                  <T value="help" />
                </a>
              </Typography>
            </Space>
          )}
          {extraContent}
          <div>
            {onCancel && (
              <Button className="mr-4" onClick={onCancel}>
                {translate(cancelText)}
              </Button>
            )}
            {extraActionBtn}
            <Button disabled={disabledOk} loading={isLoading} type="primary" onClick={onOk}>
              {translate(okText)}
            </Button>
          </div>
        </div>
        {error && (
          <div
            className="flex justify-end px-2"
            style={{
              maxWidth: width,
              paddingRight: bodyWidth > width ? 0 : 32,
              paddingLeft: bodyWidth > width ? 0 : 32,
            }}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelSaveFooter;
