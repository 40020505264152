import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Divider, Badge } from 'antd';
import { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';
import { useInView } from 'react-intersection-observer';

import { getMediaInfo } from 'apis/patchnote';
import { LanguageContext } from 'context/language';
import { convertStringtoDate } from 'utils/date';

type PatchNotePostProps = {
  isNewPost?: boolean;
  title: string;
  body: string;
  publishDate: string;
  hyperlink: string;
  id: string;
  featured_media: number;
  search: string;
  handleClickNewsPost: (postId: string, publishDate: Date) => void;
};

const PatchNotePost: React.FunctionComponent<PatchNotePostProps> = ({
  id,
  title,
  body,
  publishDate,
  handleClickNewsPost,
  hyperlink,
  featured_media,
  isNewPost = false,
  search,
}: PatchNotePostProps) => {
  const { translate } = useContext(LanguageContext);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [media, setMedia] = useState<{ link: string; media_details: { width: number; height: number } } | null>(null);
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const regex = useMemo(() => {
    return new RegExp(`(${search})(?![^<]*>|[^<>]<\\/)`, 'gi');
  }, [search]);
  useEffect(() => {
    if (inView) {
      handleClickNewsPost?.(id, convertStringtoDate(publishDate, 'dd/MM/yyyy'));
    }
  }, [handleClickNewsPost, id, inView, publishDate]);

  const includedText = useMemo(() => {
    return title.search(regex) !== -1 || body.search(regex) !== -1;
  }, [body, title, regex]);

  const highLightTitle = useMemo(() => {
    if (search.trim().length !== 0) {
      return title.replaceAll(
        regex,
        (match) => `<span class='bg-yellow-300' data-testid='highlight-text-title'>${match}</span>`
      );
    } else {
      return title;
    }
  }, [search, title, regex]);

  const paragraphs: string[] = useMemo(() => {
    const modifiedBody = body.replaceAll('<a ', `<a class="text-blue-500" target="_blank" rel="noopener noreferrer" `);
    const splitBody = modifiedBody.split('\n').filter((item) => item.trim().length > 0);
    if (search.trim().length === 0) {
      return splitBody || [];
    } else {
      return (
        splitBody?.map((item) => {
          return item.replaceAll(regex, (match) => {
            return `<span class='bg-yellow-300' data-testid='highlight-text-title'>${match}</span>`;
          });
        }) || []
      );
    }
  }, [body, search, regex]);

  const postBody = useMemo(() => {
    if (paragraphs.length === 0) {
      return '';
    } else if (isShowAll === false) {
      return paragraphs[0];
    } else if (isShowAll === true) {
      return paragraphs.join('<br/>');
    }
  }, [isShowAll, paragraphs]);

  const sanitizePublishDate: string = useMemo(() => {
    return DOMPurify.sanitize(publishDate);
  }, [publishDate]);

  const sanitizeTitle: string = useMemo(() => {
    return DOMPurify.sanitize(highLightTitle);
  }, [highLightTitle]);

  const sanitizeBody: string = useMemo(() => {
    return DOMPurify.sanitize(postBody as string, { ADD_ATTR: ['target'] });
  }, [postBody]);

  useEffect(() => {
    const getMedia = async () => {
      const response = await getMediaInfo(featured_media + '');
      setMedia(response);
    };
    featured_media && getMedia();
  }, [featured_media]);

  if (!search || includedText) {
    return (
      <>
        <div
          className="flex flex-col p-4 cursor-pointer"
          data-testid="patchnote-post"
          onClick={() => handleClickNewsPost(id, convertStringtoDate(publishDate, 'dd/MM/yyyy'))}
          ref={ref}
        >
          <div
            className="opacity-50"
            dangerouslySetInnerHTML={{ __html: sanitizePublishDate }}
            data-testid="patchnote-post-publish-date"
          />

          <div className="flex flex-row relative">
            {isNewPost && <Badge color="blue" className="absolute" style={{ left: '-10px' }} />}
            <b
              dangerouslySetInnerHTML={{ __html: sanitizeTitle }}
              data-testid="patchnote-post-title"
              className={isEmpty(hyperlink) ? '' : 'no-underline hover:underline cursor-pointer'}
              onClick={() => {
                !isEmpty(hyperlink) ? window.open(hyperlink, '_blank')?.focus() : null;
              }}
            />
          </div>
          <p
            data-testid="patchnote-post-body"
            className="patchnote-post-body"
            dangerouslySetInnerHTML={{ __html: sanitizeBody as string }}
          ></p>
          <div className="flex flex-row justify-end">
            {paragraphs.length > 1 && isShowAll === false && (
              <span
                className="cursor-pointer text-blue-500 font-bold"
                onClick={() => {
                  setIsShowAll(true);
                }}
              >
                {translate('read_more')}
              </span>
            )}
          </div>
          <div className="flex flex-row justify-end">
            {paragraphs.length > 1 && isShowAll === true && (
              <span
                className="cursor-pointer text-blue-500 font-bold"
                onClick={() => {
                  setIsShowAll(false);
                }}
              >
                {translate('show_less')}
              </span>
            )}
          </div>

          {media !== null && <img src={media.link} />}
        </div>
        <Divider />
      </>
    );
  }
  return null;
};

export default React.memo(PatchNotePost);
