import React, { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { Input, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { LanguageContext } from 'context/language';
import debounce from 'lodash/debounce';
import { setTaglishToggleStatus } from 'utils/storage';
import { useAppRegion } from 'hooks/app';
import T from 'shared-components/Translator';
type DrawerHeaderProps = {
  handleChangePatchNoteDrawerVisible: Dispatch<SetStateAction<boolean>>;
  search: string;
  handleSearch: Dispatch<SetStateAction<string>>;
  months: { value: Date; label: string }[];
  selectedMonth?: Date | 'older';
  handleClickMonthButton: (month: Date | 'older') => void;
  handleClearMonth: () => void;
  isTaglishOn: boolean;
  setIsTaglishOn: Dispatch<SetStateAction<boolean>>;
};

const DrawerHeader: React.FunctionComponent<DrawerHeaderProps> = ({
  handleChangePatchNoteDrawerVisible,
  months,
  selectedMonth,
  handleClickMonthButton,
  handleSearch,
  isTaglishOn,
  setIsTaglishOn,
}: DrawerHeaderProps) => {
  const { translate } = useContext(LanguageContext);
  const { region } = useAppRegion();
  const isPH = region === 'ph';
  const renderFilterMonths = useCallback(
    () =>
      months.map((month) => (
        <button
          data-testid="month-box"
          key={month.label}
          className={`font-bold ${
            selectedMonth === month.value ? 'patchnote-drawer-selected-button' : 'patchnote-drawer-button'
          }`}
          onClick={() => {
            handleClickMonthButton(month.value);
          }}
        >
          {month.label}
        </button>
      )),
    [handleClickMonthButton, months, selectedMonth]
  );

  return (
    <div className="flex flex-col" data-testid="patchnote-drawer-header">
      {/* WHAT NEWS AND CLOSE ICON */}
      <div className="flex flex-row items-center">
        <span className="font-bold text-xl color-black">{translate('what_new')}</span>
        {/* TAGLISH TOGGLE */}
        {isPH && (
          <div className="flex flex-row items-end ml-5">
            <Switch
              checked={isTaglishOn}
              className="w-5 mr-3"
              onChange={(status) => {
                setIsTaglishOn(status);
                setTaglishToggleStatus(status);
              }}
            />
            <span className="text-base">Taglish</span>
          </div>
        )}
        <div
          className="cursor-pointer ml-auto"
          onClick={() => handleChangePatchNoteDrawerVisible(false)}
          data-testid="close-drawer-button"
        >
          <CloseIcon />
        </div>
      </div>
      {/* SEARCH BAR */}
      <div className="mt-5">
        <Input
          placeholder={translate('search_here')}
          suffix={<SearchOutlined color="#D9D9D9" />}
          size="large"
          onChange={debounce((e) => handleSearch(e.target.value), 500)}
        />
      </div>

      {/* MONTH SELECTION */}
      <div className="flex flex-col mt-5">
        <span className="font-bold text-base capitalize">
          <T value="month" />
        </span>
        <div className="grid grid-cols-3 gap-2" data-testid="month-box-container">
          {renderFilterMonths()}
          <button
            className={`font-bold ${
              selectedMonth === 'older' ? 'patchnote-drawer-selected-button' : 'patchnote-drawer-button'
            }`}
            onClick={() => {
              handleClickMonthButton('older');
            }}
          >
            {translate('older')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawerHeader;
