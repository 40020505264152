import React, { useState } from 'react';
import { HeaderContext } from './context';

const HeaderProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState<React.ReactNode>(null);
  const [extra, setExtra] = useState<React.ReactNode>(null);

  return <HeaderContext.Provider value={{ title, setTitle, extra, setExtra }}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;
