import React, { FunctionComponent, useContext } from 'react';

import { LanguageContext } from 'context/language';

export interface TranslatorProps {
  value: string;
  params?: {
    [key in string]: string | number;
  };
}

const T: FunctionComponent<TranslatorProps> = ({ value, params }: TranslatorProps) => {
  const { translate } = useContext(LanguageContext);
  const translated = translate(value, params);

  return <>{translated}</>;
};

export default T;
