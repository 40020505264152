import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Divider, Space, Typography } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import useSWR from 'swr';

import { brandColor } from 'utils/colors';
import { LOGIN_CONFIRMATION_REDIRECT, TOKEN_STORAGE_KEY, isPH, isVN } from 'utils/constants';
import { useSegement } from 'utils/hooks';
import { Me } from 'models/Me';
import { useLogout } from 'hooks/logout';
import { generateRegionName } from 'utils/delivery';
import { useAppRegion } from 'hooks/app';
import { LanguageContext } from 'context/language';
import { Profile } from 'models/Profile';

import SwitchUserModal from './SwitchUserModal';
import T from 'shared-components/Translator';
import LanguagesSelect from 'shared-components/LanguagesSelect';
import BlockSpinner from 'shared-components/BlockSpinner';
import CustomerAvatar from 'shared-components/CustomerAvatar';

import fbIcon from 'assets/Facebook.svg';
import { ReactComponent as WhatsappIcon } from 'assets/whatsapp.svg';
import { ReactComponent as MessengerIcon } from 'assets/messenger.svg';

const WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=6587997947';
const MESSENGER_LINK = isPH
  ? 'https://m.me/upmeshph'
  : 'https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F109050467382218%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink%26source_id%3D1441792%26recurring_notification%3D0';
const CONTACT_LINK = 'https://www.upmesh.io/contact-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  completedAuth?: boolean;
}

const LoginConfirmation: React.FC<Props> = ({ completedAuth }) => {
  const { isAuthenticated } = useAuth0();
  const { analyticsTrack } = useSegement();
  const { logout } = useLogout();
  const { region } = useAppRegion();
  const { translate } = useContext(LanguageContext);

  const [openModal, setOpenModal] = useState(false);
  const isLoggedIn = isAuthenticated || !!localStorage.getItem(TOKEN_STORAGE_KEY);
  const [isValidating, setLoading] = useState(false);
  const [dataResponse, setData] = useState<Me>();
  const [tocAgreed, setTocAgreed] = useState(false);
  const [betaAccessAgreed, setBetaAccessAgreed] = useState(false);
  const canLogin = useMemo(() => {
    if (isPH) {
      return true;
    }
    if (isVN) {
      return tocAgreed;
    }
    return tocAgreed && betaAccessAgreed;
  }, [betaAccessAgreed, tocAgreed]);

  const onChangeToc = (e: CheckboxChangeEvent) => setTocAgreed(e.target.checked);
  const onChangeBetaAccess = (e: CheckboxChangeEvent) => setBetaAccessAgreed(e.target.checked);

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get('/user-service/api/users/me');
      setData(res.data);
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    isLoggedIn && completedAuth && fetchUser();
  }, [completedAuth, fetchUser, isLoggedIn]);

  useEffect(() => {
    try {
      if (!localStorage.getItem(LOGIN_CONFIRMATION_REDIRECT)) {
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('redirect_url') || '/';
        window.location.replace(redirectUrl);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, []);

  const handleNext = () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect_url') || '';
      localStorage.removeItem(LOGIN_CONFIRMATION_REDIRECT);

      window.location.replace(redirectUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const handleLogout = () => {
    analyticsTrack('Switch User & Logout');
    localStorage.removeItem(LOGIN_CONFIRMATION_REDIRECT);
    logout();
  };

  const hasRegistered = useMemo(() => !!dataResponse?.activatedAt, [dataResponse?.activatedAt]);

  const { data: profile, isValidating: profileValidating } = useSWR<Profile>(
    hasRegistered ? '/facebook-service/api/posts/check-valid-profile' : null
  );

  useEffect(() => {
    if (profile) return handleNext();
  }, [profile]);

  const content = useMemo(() => {
    if (!hasRegistered)
      return (
        <Space size={16} direction="vertical" split={<Divider className="m-0" />} className="w-full">
          <Space size={24} direction="vertical">
            <Typography className="text-2xl">
              <T value="your_current_facebook_account_is_not_registered_with_upmesh" />
            </Typography>
            <div className="flex items-center px-4 py-[11px] border fade-in bg-gray-100" style={{ minWidth: 260 }}>
              <CustomerAvatar size={40} avtSrc={dataResponse?.profile?.picture} badgeSrc={fbIcon} />
              <div className="ml-2">
                <div className="fade-in">{`${dataResponse?.firstName} ${dataResponse?.lastName}`}</div>
                <div className="text-gray-600 fade-in">{dataResponse?.email}</div>
              </div>
            </div>
          </Space>
          <div className="text-center">
            <Space size={16} direction="vertical" className="w-full">
              <Typography>
                <T value="to_register_please_contact_us_via" />
              </Typography>
              <Button className="w-full p-2" size="large" onClick={() => window.open(WHATSAPP_LINK, '_blank')}>
                <Space>
                  <WhatsappIcon />
                  {translate('whatsapp')}
                </Space>
              </Button>
              <Button className="w-full p-2" size="large" onClick={() => window.open(MESSENGER_LINK, '_blank')}>
                <Space>
                  <MessengerIcon />
                  {translate('messenger')}
                </Space>
              </Button>
              <Button
                className="w-full p-2"
                size="large"
                type="primary"
                ghost
                onClick={() => window.open(CONTACT_LINK, '_blank')}
              >
                {translate('our_contact_form')}
              </Button>
            </Space>
            <Button className="mt-20" type="link" onClick={() => setOpenModal(true)}>
              <T value="switch_user" />
            </Button>
          </div>
        </Space>
      );
    if (!profile)
      return (
        <>
          <div className="mb-4 text-xl text-center">
            <T value="login_as" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center px-4 py-2 mb-4 border border-brand-500 fade-in" style={{ minWidth: 260 }}>
              <Avatar className="fade-in" src={dataResponse?.profile?.picture} />
              <div className="ml-2">
                <div className="fade-in">{`${dataResponse?.firstName} ${dataResponse?.lastName}`}</div>
                <div className="text-gray-600 fade-in">{dataResponse?.email}</div>
              </div>
            </div>
            <div>
              {!isPH && (
                <div className="mb-2">
                  <div>
                    <Checkbox onChange={onChangeToc}>
                      <T value="accept_term" />
                    </Checkbox>
                  </div>
                  {!isVN && (
                    <div>
                      <Checkbox onChange={onChangeBetaAccess}>
                        <T value="accept_agreement" />
                      </Checkbox>
                    </div>
                  )}
                </div>
              )}
            </div>
            <Button
              type="primary"
              block
              onClick={handleNext}
              className="uppercase hover:opacity-75"
              disabled={!canLogin}
              size="large"
              style={{ background: canLogin ? brandColor[5] : brandColor[3] }}
            >
              <T value="next" />
            </Button>
            <Button className="mt-1 hover:opacity-75" type="link" onClick={() => setOpenModal(true)}>
              <T value="switch_user" />
            </Button>
            <Divider type="horizontal" />
            <Button
              type="link"
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
              rel="noreferrer"
              target="_blank"
              style={{ fontSize: '12px' }}
            >
              <T value="privacy_policy" />
            </Button>
          </div>
        </>
      );
  }, [
    hasRegistered,
    dataResponse?.profile?.picture,
    dataResponse?.firstName,
    dataResponse?.lastName,
    dataResponse?.email,
    translate,
    profile,
    canLogin,
  ]);

  if (isValidating || !dataResponse || profileValidating || profile) return <BlockSpinner />;

  return (
    <>
      <SwitchUserModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={handleLogout} />
      <div className="relative flex flex-col items-stretch h-full md:flex-row">
        <div className="absolute right-4 top-4">
          <LanguagesSelect type="dropdown" />
        </div>
        <div
          className="flex flex-col items-center justify-center p-8 bg-brand-glossy md:pr-16"
          style={{ flex: '2 2 auto' }}
        >
          <img alt="logo" src="/images/logo.svg" className="w-48 text-white md:w-full" style={{ maxWidth: 360 }} />
          <div className="mx-auto text-center">
            <Space
              split={<Divider type="vertical" className="h-8 border-white" />}
              className="text-2xl text-white md:text-3xl"
            >
              <T value="upmesh_seller_dashboard" />
              <span className="font-bold">
                <T value={generateRegionName(region)} />
              </span>
            </Space>
          </div>
        </div>
        <div className="flex-1 justify-end flex flex-col items-center py-8 px-8">
          <div className="flex flex-col justify-end" style={{ maxWidth: 600 }}>
            {content}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginConfirmation;
