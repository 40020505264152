import Axios from 'axios';
import { FreqUsedVariationReq, ProductAttribute } from 'models/ProductAttribute';

import { ProductVariant } from 'models/ProductVariant';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 1000;

export type VariantPaginationRequest = {
  pageSize?: number;
  current?: number;
};

const variantQuery = (id: string, pagination?: VariantPaginationRequest) =>
  `/product-service/api/products/${id}/variants?pageSize=${pagination?.pageSize || DEFAULT_PAGE_SIZE}&pageNumber=${
    pagination?.current || DEFAULT_PAGE_NUMBER
  }`;

export const getProductVariants = (productIds: string[], pagination?: VariantPaginationRequest) => async () => {
  if (!productIds?.length) {
    return {};
  }

  const listPromises = productIds.map(async (id) => {
    const res = await Axios.get(variantQuery(id, pagination));
    return res;
  });

  const res = await Promise.all(listPromises);
  const result: {
    [key in string]: ProductVariant[];
  } = {};

  productIds.forEach((id) => {
    result[id.toLowerCase()] = res.find((i) => i.config.url === variantQuery(id, pagination))?.data?.data;
  });

  return result;
};

export const getProductVariant = async (productId: string, pagination?: VariantPaginationRequest) => {
  const res = await Axios.get(variantQuery(productId, pagination));
  return res.data;
};

export const getAttributesByCategoryId = async (categoryId: string) => {
  const res = await Axios.get<ProductAttribute[]>(`product-service/api/categories/attributes-excel/${categoryId}`);
  return res.data;
};

export const updateFreqUsedVariation = async (req: { items: FreqUsedVariationReq[] }) => {
  await Axios.put('/product-service/api/variations/frequently-used', req);
  return null;
};
