import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { Button, Divider, Drawer } from 'antd';
import { get, isEmpty } from 'lodash';
import { useSWRInfinite } from 'swr';

import { News, NewsResponse } from 'models/News';
import { getOlderStartAndEndDate, getPreviousMonths, getStartAndEndDateOfMonth } from 'utils/date';
import DrawerBody from './DrawerBody';
import DrawerHeader from './DrawerHeader';
import { fetcher as getPatchNotes } from 'apis/patchnote';
import T from 'shared-components/Translator';
import { LOCALE_ID_MAPPING, STAGING_LOCALE_ID_MAPPING } from 'enums/locales';
import { COUNTRY_ID_MAPPING, STAGING_COUNTRY_ID_MAPPING } from 'enums/country';
import { LanguageContext } from 'context/language';
import { useAppRegion } from 'hooks/app';
import { APP_REGION } from 'utils/constants';

type PatchNoteDrawerProps = {
  handleChangePatchNoteDrawerVisible: Dispatch<SetStateAction<boolean>>;
  decreaseNewsCount: () => void;
  visible: boolean;
  isTaglishToggleOn: boolean;
  setIsTaglishToggleOn: Dispatch<SetStateAction<boolean>>;
};

const DEFAULT_RENDERED_MONTH = 6;
const POST_LIMIT = 5;

const PatchNoteDrawer: React.FunctionComponent<PatchNoteDrawerProps> = ({
  handleChangePatchNoteDrawerVisible,
  decreaseNewsCount,
  visible,
  isTaglishToggleOn,
  setIsTaglishToggleOn,
}: PatchNoteDrawerProps) => {
  const { region } = useAppRegion();
  const isPH = region === 'ph';
  const isUseTaglish = useMemo(() => isPH && isTaglishToggleOn, [isPH, isTaglishToggleOn]);
  const { currentLang } = useContext(LanguageContext);
  const [search, setSearch] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<Date | 'older'>();
  const patchNoteURL = process.env.REACT_APP_PATCH_NOTE_API;
  const localeId = useMemo(() => {
    const langKey = isUseTaglish ? 'tgl' : currentLang.toString();
    return patchNoteURL?.includes('staging')
      ? get(STAGING_LOCALE_ID_MAPPING, langKey) || '0'
      : get(LOCALE_ID_MAPPING, langKey) || '0';
  }, [currentLang, isUseTaglish, patchNoteURL]);
  const countryId = useMemo(() => {
    return patchNoteURL?.includes('staging')
      ? get(STAGING_COUNTRY_ID_MAPPING, APP_REGION as string) || '0'
      : get(COUNTRY_ID_MAPPING, APP_REGION as string) || '0';
  }, [patchNoteURL]);
  const months: { value: Date; label: string }[] = useMemo(() => getPreviousMonths(DEFAULT_RENDERED_MONTH), []);

  const getNewsKey = useCallback(
    (pageIndex: number, previousPageData: NewsResponse | null) => {
      if (previousPageData && !previousPageData.news.length) return null; // reached the end
      const url = new URL(`${process.env.REACT_APP_PATCH_NOTE_API}/wp-json/wp/v2/patchnotes`);
      url.searchParams.append('countries', countryId);
      url.searchParams.append('locales', localeId);
      // TODO: temp to hotfix prod
      url.searchParams.append('orderby', 'releaseDate');
      url.searchParams.append('per_page', String(POST_LIMIT));
      url.searchParams.append('page', String(pageIndex + 1));
      url.searchParams.append('search', search);
      if (selectedMonth) {
        if (selectedMonth !== 'older') {
          const { firstDate, lastDate } = getStartAndEndDateOfMonth(selectedMonth as Date);
          url.searchParams.append('start', firstDate);
          url.searchParams.append('end', lastDate);
        } else if (!isEmpty(selectedMonth) && selectedMonth === 'older') {
          const { firstDate, lastDate } = getOlderStartAndEndDate(DEFAULT_RENDERED_MONTH);
          url.searchParams.append('start', firstDate);
          url.searchParams.append('end', lastDate);
        }
      }
      return url.href;
    },
    [search, selectedMonth, countryId, localeId]
  );

  const { data, size, setSize, error } = useSWRInfinite<NewsResponse | null>(getNewsKey, getPatchNotes, {
    revalidateOnFocus: false,
    revalidateAll: true,
    focusThrottleInterval: 60000,
  });

  const news = React.useMemo(() => {
    return (
      data?.reduce<News[]>((res, cur) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        if (cur === null) {
          return res;
        }
        return [...res, ...cur.news];
      }, []) || []
    );
  }, [data]);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size && size > 0 && data && typeof data[size - 1] === 'undefined');
  const empty = !data?.length;
  const totalPage = useMemo(() => {
    return data?.[data?.length - 1]?.totalPage || 0;
  }, [data]);
  const isReachingEnd = useMemo(() => {
    return empty || totalPage === 0 || size === totalPage;
  }, [empty, totalPage, size]);

  const onLoadMore = useCallback(() => {
    setSize?.((size) => (size ? size + 1 : 1));
  }, [setSize]);

  const loadMore = useMemo(
    () =>
      !isLoadingInitialData && !isLoadingMore && !isReachingEnd ? (
        <div className="mb-5 flex flex-row items-center justify-center">
          <Button onClick={onLoadMore} size="large">
            <T value="load_more" />
          </Button>
        </div>
      ) : null,
    [isLoadingInitialData, isLoadingMore, isReachingEnd, onLoadMore]
  );

  const handleClearMonth = useCallback(() => {
    setSelectedMonth(undefined);
  }, []);

  const handleClickMonthButton = useCallback(
    (month: Date | 'older') => {
      if (month === selectedMonth) {
        return handleClearMonth();
      }
      setSelectedMonth(month);
    },
    [handleClearMonth, selectedMonth]
  );

  return (
    <Drawer
      width={682}
      visible={visible}
      closable={false}
      bodyStyle={{ padding: 0 }}
      onClose={() => {
        handleChangePatchNoteDrawerVisible(false);
      }}
    >
      <div className="p-5">
        <DrawerHeader
          months={months}
          selectedMonth={selectedMonth}
          handleClickMonthButton={handleClickMonthButton}
          handleChangePatchNoteDrawerVisible={handleChangePatchNoteDrawerVisible}
          search={search}
          handleSearch={setSearch}
          handleClearMonth={handleClearMonth}
          isTaglishOn={isTaglishToggleOn}
          setIsTaglishOn={setIsTaglishToggleOn}
        />
      </div>
      <Divider />
      <DrawerBody
        search={search}
        data={news}
        loadmore={loadMore}
        decreaseNewsCount={decreaseNewsCount}
        isUseTaglish={isUseTaglish}
      />
    </Drawer>
  );
};

export default PatchNoteDrawer;
