export interface IWorkspace {
  id: string;
  name: string;
  latest_message?: IMessage;
  avatar_url?: string;
}

export interface ICustomer {
  id: string;
  name: string;
  latest_message?: IMessage;
  type?: CustomerType;
  avatar_url?: string;
  quickViewCustomerType?: string;
}

export interface IMessage {
  id: string;
  text: string;
  created_at: number;
  datasource: string;
  recipient_id: string;
  sender_id: string;
  sender_type: AuthorType;
  conversation_type: number;
}

export enum CustomerType {
  FACEBOOK,
  INSTAGRAM,
  Manual,
  CreateManualLegacyGuest,
  BuyerApp,
  None = -1,
}

export enum AuthorType {
  MERCHANT,
  CUSTOMER,
}

export interface WorkspaceListOption {
  value?: string;
  label?: string;
  avatar_url?: string;
}
