import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import T from 'shared-components/Translator';
import { setSavedCartIds, setSavedOrderIds, removeCurrentCartFilter, removeCurrentOrderFilter } from 'utils/functions';

type ErrorModalContentProps = {
  affectedIds: {
    cartIds?: string[];
    orderIds?: string[];
  };
};

const ErrorModalContent: FunctionComponent<ErrorModalContentProps> = ({ affectedIds }: ErrorModalContentProps) => {
  const history = useHistory();

  const handleNavigate = (page: 'carts' | 'orders') => {
    if (page === 'carts') {
      setSavedCartIds(affectedIds.cartIds);
      removeCurrentCartFilter();
      return history.push('/carts');
    }
    setSavedOrderIds(affectedIds.orderIds);
    removeCurrentOrderFilter();
    return history.push('/orders');
  };

  return (
    <div>
      {affectedIds && affectedIds?.cartIds && affectedIds.cartIds.length > 0 && (
        <div className="mb-5">
          <Typography.Text type="danger" className="block">
            <T value="there_are_pending_orders_that_affected_by_this_change" />
          </Typography.Text>
          {affectedIds?.cartIds?.map((id: string) => (
            <Typography.Text key={id} className="block py-1">
              {id}
            </Typography.Text>
          ))}
          <div>
            <Typography.Text>
              <T value="see_above_orders" />
            </Typography.Text>
            <Typography.Link className="ml-1" onClick={() => handleNavigate('carts')}>
              <T value="here" />
            </Typography.Link>
          </div>
        </div>
      )}
      {affectedIds && affectedIds?.orderIds && affectedIds?.orderIds?.length > 0 && (
        <div>
          <Typography.Text type="danger" className="block">
            <T value="there_are_paid_orders_that_affected_by_this_change" />
          </Typography.Text>
          {affectedIds?.orderIds?.map((id: string) => (
            <Typography.Text key={id} className="block py-1">
              {id}
            </Typography.Text>
          ))}
          <div>
            <Typography.Text>
              <T value="see_above_orders" />
            </Typography.Text>
            <Typography.Link className="ml-1" onClick={() => handleNavigate('orders')}>
              <T value="here" />
            </Typography.Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorModalContent;
