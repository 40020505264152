import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Modal, Popover, Space, Typography } from 'antd';
import { addSeconds, isAfter } from 'date-fns';

import { LanguageContext } from 'context/language';
import { getPinCode, removePinCode, setPinCode } from 'utils/storage';
import { currentUser } from 'utils/functions';
import { UserSettingsContext } from 'UserSettingsContext';

export const usePinCode = (props?: { title?: ReactNode; onSuccess?: () => void; onCancel?: () => void }) => {
  const { translate } = useContext(LanguageContext);
  const inputRef = useRef<InputRef>(null);

  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [showPinCodePopover, setShowPinCodePopover] = useState(false);
  const { showPinCode, setShowPinCode } = useContext(UserSettingsContext);
  const [value, setValue] = useState('');

  const handleCloseModal = useCallback(() => {
    setShowPinCodeModal(false);
    props?.onCancel?.();
  }, [props]);

  const handlePinCodeSubmit = useCallback(() => {
    if (value === '2047') {
      setShowPinCode(true);
      setPinCode({ [currentUser()]: addSeconds(new Date(), 900).toString() });
      props?.onSuccess?.();
      setShowPinCodeModal(false);
    } else {
      handleCloseModal();
    }
    setShowPinCodePopover(false);
  }, [handleCloseModal, props, setShowPinCode, value]);

  const handleHidePinCode = useCallback(() => {
    setShowPinCode(false);
    removePinCode(currentUser());
  }, [setShowPinCode]);

  useEffect(() => {
    if (showPinCodeModal) {
      setValue('');
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }
  }, [showPinCodeModal]);

  useEffect(() => {
    const timeExpiry = getPinCode(currentUser());
    if (timeExpiry) {
      if (isAfter(new Date(timeExpiry), new Date())) return setShowPinCode(true);
      return handleHidePinCode();
    }
  }, [handleHidePinCode, setShowPinCode]);

  useEffect(() => {
    if (inputRef.current && showPinCodePopover) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [showPinCodePopover]);

  const modal = showPinCodeModal ? (
    <Modal
      title={props?.title}
      open={showPinCodeModal}
      onOk={handlePinCodeSubmit}
      onCancel={handleCloseModal}
      okText={translate('submit')}
      cancelText={translate('cancel')}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlePinCodeSubmit();
        }}
      >
        <Input
          ref={inputRef}
          placeholder={translate('pin_code')}
          autoFocus
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </form>
    </Modal>
  ) : null;

  const popover = (node: ReactNode) => {
    return (
      <Popover
        trigger="click"
        placement="bottomRight"
        open={showPinCodePopover}
        onOpenChange={setShowPinCodePopover}
        content={
          <div>
            <Space direction="vertical" className="w-full">
              {props?.title && <Typography>{props.title}</Typography>}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePinCodeSubmit();
                }}
              >
                <Input
                  placeholder={translate('enter_pin_code')}
                  autoFocus
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  ref={inputRef}
                />
              </form>
            </Space>
            <Space className="justify-end pt-3 px-4 mt-4 ml-[-1rem] mr-[-1rem] w-[-webkit-fill-available] w-[-moz-available] border-t">
              <Button onClick={() => setShowPinCodePopover(false)}>{translate('cancel')}</Button>
              <Button onClick={handlePinCodeSubmit} type="primary">
                {translate('submit')}
              </Button>
            </Space>
          </div>
        }
      >
        {node}
      </Popover>
    );
  };

  return { modal, popover, show: showPinCode, onShowModal: setShowPinCodeModal, onHide: handleHidePinCode };
};
