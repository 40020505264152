import React from 'react';
import useSWR from 'swr';

import ShippingGroup, { ShippingGroupV2 } from 'models/ShippingGroup';
import DeliveryService from 'models/DeliveryService';
import { requestParamsFromObject, requestWithErrorLogging } from 'utils/request';
import { useSegement } from 'utils/hooks';
import { generateCommonTrackingData } from 'utils/functions';

export type AddProductToShippingGroupRequest = {
  groupName: string;
  note: string;
};

export const useFetchShippingGroups = () => {
  const { data: shippingGroups, isValidating } = useSWR<{
    total: number;
    data: ShippingGroup[];
  }>(`/delivery-service/api/shipping-groups`);

  return {
    shippingGroups,
    isValidating,
  };
};

export const useFetchFillupShippingGroupIds = () => {
  const {
    data: fillupShippingGroupIds,
    isValidating,
    revalidate,
  } = useSWR<string[]>(`/delivery-service/api/shipping-groups/fillup`);

  return {
    fillupShippingGroupIds,
    isValidating,
    revalidate,
  };
};

export const useAddProductsToShippingGroup = ({
  mutateQueryString,
  shippingGroup,
}: {
  mutateQueryString: string;
  shippingGroup?: ShippingGroup | null;
}) => {
  const { analyticsTrack } = useSegement();

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

  const addProductsToShippingGroup = async ({
    productIdsInGroup,
    deliveryOptionsToCreateAndUpdate,
    values,
  }: {
    deliveryOptionsToCreateAndUpdate: DeliveryService[];
    productIdsInGroup: string[];
    values: AddProductToShippingGroupRequest;
  }) => {
    const commonTrackingEventData = generateCommonTrackingData();
    setIsUpdating(true);
    try {
      const groupId = await requestWithErrorLogging({
        url: '/delivery-service/api/shipping-groups/v2',
        method: 'post',
        data: {
          ...values,
          productIds: productIdsInGroup,
          methods: deliveryOptionsToCreateAndUpdate,
        },
        mutateConfig: {
          mutatePath: ['/delivery-service/api/shipping-groups', mutateQueryString],
        },
      });

      analyticsTrack('Group Created', {
        shipping_group_id: groupId,
        group_name: values.groupName,
        product_in_group: productIdsInGroup,
        deleted_product_in_group: [],
        group_type: 'delivery group',
        ...commonTrackingEventData,
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const updateProductsToShippingGroup = async ({
    productIdsInGroup,
    deliveryOptionsToDelete,
    deliveryOptionsToCreateAndUpdate,
    deletedProduct,
    values,
    id,
  }: {
    deliveryOptionsToDelete: DeliveryService[];
    deliveryOptionsToCreateAndUpdate: DeliveryService[];
    deletedProduct: string[];
    productIdsInGroup: string[];
    values: AddProductToShippingGroupRequest;
    id: string;
  }) => {
    setIsUpdating(true);
    try {
      const commonTrackingEventData = generateCommonTrackingData();
      const groupId = await requestWithErrorLogging({
        url: `delivery-service/api/shipping-groups/v2/${id}`,
        method: 'put',
        data: {
          ...values,
          productIds: productIdsInGroup,
          methodsToBeDeleted: deliveryOptionsToDelete.map((d) => d.id),
          productsToBeDeleted: deletedProduct,
          methods: deliveryOptionsToCreateAndUpdate,
          shippingGroupId: shippingGroup?.id,
          shippingGroupName: values.groupName,
        },
        mutateConfig: {
          mutatePath: ['/delivery-service/api/shipping-groups', mutateQueryString],
        },
      });

      analyticsTrack('Group Edited', {
        delivery_group_id: groupId,
        name: values.groupName,
        product_in_group: productIdsInGroup,
        deleted_product_in_group: deletedProduct,
        delivery_option: deliveryOptionsToCreateAndUpdate,
        group_type: 'delivery group',
        ...commonTrackingEventData,
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    addProductsToShippingGroup,
    updateProductsToShippingGroup,
    isUpdating,
  };
};

export const useGetShippingGroupDetail = (
  options: {
    id?: string;
  } = {}
) => {
  const { id } = options;

  return useSWR<ShippingGroupV2>(id ? `/delivery-service/api/shipping-groups/v2/${id}` : null);
};

export const useFetchShippingGroupsV2 = (
  options: {
    search?: string;
    pageSize?: number;
    pageNum?: number;
    sort?: 'asc' | 'desc';
  } = {}
) => {
  const { pageNum, pageSize, search: searchTerm, sort } = options;

  const paramsObject: {
    pageNumber?: number;
    pageSize?: number;
    searchTerm?: string;
    orderBy?: string;
    orderDirection?: string;
  } = {
    pageNumber: pageNum,
    pageSize,
    searchTerm,
  };

  if (sort) {
    paramsObject.orderBy = 'groupName';
    paramsObject.orderDirection = sort;
  }

  const { data: shippingGroups, ...others } = useSWR<{
    total: number;
    data: ShippingGroupV2[];
  }>(`/delivery-service/api/shipping-groups/v2${requestParamsFromObject(paramsObject)}`);

  return {
    shippingGroups,
    ...others,
  };
};
