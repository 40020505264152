import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, AvatarProps, Badge, BadgeProps } from 'antd';

interface CustomerAvatarProps {
  badgeSrc?: string;
  avtSrc?: string;
  badgeProps?: BadgeProps;
  avatarProps?: AvatarProps;
  size?: number;
}

const CustomerAvatar = ({ badgeSrc, avtSrc, badgeProps, avatarProps, size = 24 }: CustomerAvatarProps) => {
  return (
    <Badge
      offset={[-(size / 4), size - size / 4]}
      count={
        badgeSrc ? <img className="bg-white rounded-full" style={{ width: `${size / 2}px` }} src={badgeSrc} /> : null
      }
      {...badgeProps}
    >
      <Avatar size={size} shape="circle" src={avtSrc} icon={<UserOutlined />} {...avatarProps} />
    </Badge>
  );
};

export default CustomerAvatar;
