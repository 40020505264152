import React from 'react';
import Select from 'react-select';
import { APP_REGION, BUILD_COUNTRY_LOCAL_KEY } from 'utils/constants';
import keyBy from 'lodash/keyBy';

const COUNTRIES = [
  { value: 'ph', label: 'PH' },
  { value: 'my', label: 'MY' },
  { value: 'sg', label: 'SG' },
  { value: 'th', label: 'TH' },
  { value: 'vn', label: 'VN' },
];

const countryByCode = keyBy(COUNTRIES, 'value');

const CountryBuildSwitch = () => {
  return (
    <div className="px-3">
      <Select
        value={countryByCode[APP_REGION as string]}
        styles={{
          container: (provided) => ({
            ...provided,
            maxWidth: 100,
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 100,
          }),
        }}
        classNamePrefix="react-select"
        onChange={(option: any) => {
          localStorage.setItem(BUILD_COUNTRY_LOCAL_KEY, option?.value as string);
          window.location.reload();
        }}
        options={COUNTRIES}
      />
    </div>
  );
};

export default CountryBuildSwitch;
