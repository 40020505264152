import { ReactText } from 'react';
import { isVN } from 'utils/constants';

export enum DeliveryStatus {
  Paid,
  Packing,
  ReadyForPickup,
  Shipping,
  Delivered,
  FailedDelivery,
  Archived,
  ArrangedShip,
}

export interface IDeliveryStatus {
  id: string;
  status: DeliveryStatus;
  isEnabled: boolean;
  isDisplay: boolean;
}

export interface UpdateDeliveryRequest {
  shipmentIds?: string[] | ReactText[];
  newDeliveryStatus?: DeliveryStatus;
  archivedStatusDisplayOption?: ArchivedStatusDisplayOption;
  isSendShippingNoti?: boolean;
}

export enum DeliveryStatusMethod {
  BuyerCheckout = 1,
  ForcePaid,
  Manual,
  Logistics,
}

export interface StatusUpdateLogMetadata {
  name: string;
  deliveryStatusMethod: DeliveryStatusMethod;
  userId: string | null;
}

export interface StatusUpdateLog {
  shipmentId: string;
  deliveryStatus: DeliveryStatus;
  created: string;
  metadata: StatusUpdateLogMetadata;
}

export enum ArchivedStatusDisplayOption {
  ShowAsDelivered,
  ShowAsFailedDelivery,
  ShowAsCurrentStatus,
}

export const getDeliveryStatusName = (status?: string | DeliveryStatus) => {
  switch (status) {
    case DeliveryStatus.Paid:
      return 'to_ship_paid';
    case DeliveryStatus.Packing:
      return 'packing';
    case DeliveryStatus.ReadyForPickup:
      return 'ready_for_pickup';
    case DeliveryStatus.Shipping:
      return 'shipping';
    case DeliveryStatus.Delivered:
      return 'delivered';
    case DeliveryStatus.FailedDelivery:
      return 'failed_delivery';
    case DeliveryStatus.Archived:
      return 'archived';
    case DeliveryStatus.ArrangedShip:
      return 'arranged_ship';
    default:
      return 'UNKNOWN STATUS NAME';
  }
};

export const getDeliveryStatusDescription = (status: DeliveryStatus) => {
  switch (status) {
    case DeliveryStatus.Paid:
      return 'default_status_after_order_is_checked_out_successfully';
    case DeliveryStatus.Packing:
      return 'mark_order_with_this_status_if_being_packed';
    case DeliveryStatus.ReadyForPickup:
      return 'mark_order_with_this_status_if_finished_packing';
    case DeliveryStatus.Shipping:
      return 'mark_order_with_this_status_if_its_out_to_delivery';
    case DeliveryStatus.Delivered:
      return 'mark_order_with_this_status_if_buyer_already_received';
    case DeliveryStatus.FailedDelivery:
      return 'mark_order_with_this_status_if_order_has_been_failed_delivery';
    case DeliveryStatus.Archived:
      return 'mark_order_with_this_status_if_you_want_to_hide_it';
    default:
      return 'UNKNOWN STATUS DESCRIPTION';
  }
};

export const getArchivedStatusDisplayOptionName = (option: ArchivedStatusDisplayOption) => {
  switch (option) {
    case ArchivedStatusDisplayOption.ShowAsDelivered:
      return 'show_as_delivered';
    case ArchivedStatusDisplayOption.ShowAsFailedDelivery:
      return 'show_as_failed_delivery';
    case ArchivedStatusDisplayOption.ShowAsCurrentStatus:
      return 'show_as_orders_current_status';
    default:
      return 'UNKNOWN OPTION NAME';
  }
};
