import { useContext } from 'react';

import UserSpaceContext from 'context/userSpace';
import rules from 'assets/configs/rules.json';
import { APP_REGION } from './constants';

export const useCheckFeatByTier = (featName: string) => {
  const { data } = useContext(UserSpaceContext);
  const tier = data?.businessProfile?.tier;

  if (!tier) {
    return true;
  }

  // GOD MODE => full permission
  if (data?.isAdmin) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return !rules?.bannedFeaturesByTier?.[APP_REGION]?.[tier]?.includes(featName);
};
