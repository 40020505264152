/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, isEmpty } from 'lodash';
import { ErrorMessage } from 'models/MassUpload';

export const getGenericErrors = (error: any): string[] => {
  const inputFieldErrors = get(error, 'response.data.errors');

  if (isEmpty(inputFieldErrors)) {
    return [];
  }

  const genericFieldErrors = Object.entries(inputFieldErrors);

  return genericFieldErrors.map(([_, value]) => `${value}`);
};

export const getErrorsArray = (error: any): string[] => {
  const inputFieldErrors = get(error, 'response.data.errors');

  if (isEmpty(inputFieldErrors)) {
    return [];
  }

  const genericFieldErrors = Object.values(inputFieldErrors);

  let res: any[] = [];

  genericFieldErrors.forEach((error) => {
    res = res.concat(error);
  });

  return res;
};

export const getErrorsObject = (error: any): ErrorMessage[] => {
  const errors = get(error, 'response.data.errors');

  if (isEmpty(errors)) {
    return [];
  }

  const res = Object.keys(errors).map((error) => ({
    errorCode: error,
    lines: errors[error],
  }));

  return res;
};
