/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import React, { ComponentPropsWithRef, ComponentType } from 'react';
import { ExoticComponent } from 'react';
import { Route } from 'react-router';
import RouteErrorBoundary from 'shared-components/RouteErrorBoundary';

export const SentryRoute = Sentry.withSentryRouting(Route);

export const withErrorBoundary = (
  fn: () => Promise<{ default: ComponentType<any> }>
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(({ default: Component }: any) => {
        resolve({
          default: (props: any) => (
            <RouteErrorBoundary>
              <Component {...props} />
            </RouteErrorBoundary>
          ),
        });
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export type PreloadableComponent<T extends ComponentType<any>> = ExoticComponent<ComponentPropsWithRef<T>> & {
  readonly _result: T;
  preload: () => Promise<void>;
};

const loadedComponentMap = new Map();

export const lazy = <T extends ComponentType<any>>(fn: () => Promise<{ default: T }>): PreloadableComponent<T> => {
  const Component = React.lazy(() => withErrorBoundary(fn));
  (Component as any).preload = () => {
    const loaded = loadedComponentMap.get(fn);
    if (loaded) return;
    loadedComponentMap.set(fn, true);
    return withErrorBoundary(fn);
  };
  return Component as any;
};
