import React, { useState, useEffect, useMemo, FunctionComponent, useContext } from 'react';
import Select, { OptionTypeBase, StylesConfig } from 'react-select';
import useSWR from 'swr';
import debounce from 'lodash/debounce';

import { CURRENT_SHOP_STORAGE_KEY, PROFILE_ID } from 'utils/constants';
import UserSpace from 'models/UserSpace';
import { Me } from 'models/Me';
import { LanguageContext } from 'context/language';
import UserSpaceContext from 'context/userSpace';

import UserSpaceModal from './UserSpaceModal';

export interface UserSpaceSelectProps {
  onChange?: (val?: UserSpace, noReload?: boolean) => void;
}

export const userSpaceUrl = '/user-service/api/workspace';
export const workSpaceDetailUrl = `/user-service/api/workspace/me`;

const UserSpaceSelect: FunctionComponent<UserSpaceSelectProps> = ({ onChange }: UserSpaceSelectProps) => {
  const { setData } = useContext(UserSpaceContext);
  const tmpShop = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);
  const defaultShopStorage: UserSpace = tmpShop && JSON.parse(tmpShop);
  const [showingForcedModal, setShowingForcedModal] = useState(false);
  const [options, setOptions] = useState<UserSpace[]>();
  const [value, setValue] = useState<OptionTypeBase>(
    defaultShopStorage && { value: defaultShopStorage.ownerKey, label: defaultShopStorage.name }
  );
  const [mySpace, setMySpace] = useState<UserSpace>();
  const [search, setSearch] = useState('');
  const { data: swrData, isValidating } = useSWR(`${userSpaceUrl}?businessName=${search}`);
  const { translate } = useContext(LanguageContext);

  const { data: workspaceData } = useSWR<Me>(workSpaceDetailUrl);
  const emailFb = workspaceData?.email;
  const { data: userData } = useSWR('/user-service/api/users/me');

  useEffect(() => {
    if (workspaceData && userData) {
      setData?.({
        ...workspaceData,
        isAdmin: userData.isAdmin,
      });
    }
  }, [setData, userData, workspaceData]);

  useEffect(() => {
    if (swrData?.userspace) {
      const data: UserSpace[] = swrData.userspace;
      const isBusinessActived = swrData.myspace?.isBusinessActived;
      const mySpace = swrData.myspace?.mySpaceId && {
        name: translate('my_space'),
        ownerKey: swrData.myspace.mySpaceId,
      };
      !!swrData?.myspace?.mySpaceId && localStorage.setItem(PROFILE_ID, swrData.myspace.mySpaceId);
      mySpace && isBusinessActived && setMySpace(mySpace);

      const listOptions: UserSpace[] = mySpace && isBusinessActived ? [mySpace, ...data] : data;
      setOptions(listOptions);

      if (!value) {
        const shouldShowModal = !localStorage.getItem(CURRENT_SHOP_STORAGE_KEY) && listOptions.length > 1;
        if (shouldShowModal) {
          return setShowingForcedModal(true);
        }
        const item = listOptions[0];
        setValue({
          value: item?.ownerKey,
          label: item?.name,
        });
        onChange?.(item, true);
      }
    }
  }, [onChange, swrData, value, translate]);

  const handleSearch = useMemo(
    () =>
      debounce((val: string) => {
        setSearch(val);
      }, 250),
    []
  );

  const handleChange = (val: OptionTypeBase) => {
    setValue(val);
    onChange?.(
      val && {
        ownerKey: val.value,
        name: val.label,
      }
    );
  };

  // empty case ==> not show
  if (!options) return null;
  if (search === '' && options?.length && options.length <= 1) {
    return null;
  }

  const isGodMode = mySpace && value?.value !== mySpace.ownerKey;

  const customStyles: StylesConfig = {
    option: (provided, state) => {
      const isMySpace = state.value === mySpace?.ownerKey;
      return isMySpace
        ? {
            ...provided,
            borderBottom: '1px dashed #ccc',
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
          }
        : provided;
    },
    control: (provided) => {
      const animation = isGodMode
        ? {
            animation: 'alert 0.8s ease-out infinite',
          }
        : {};
      return {
        ...provided,
        ...animation,
        minWidth: 180,
        minHeight: 27,
        height: 27,
      };
    },
    container: (provided) => {
      return { ...provided, zIndex: 10 };
    },
  };

  return (
    <>
      <UserSpaceModal open={showingForcedModal} onChange={handleChange} />
      <div className="relative">
        <Select
          isLoading={isValidating}
          filterOption={() => true}
          styles={customStyles}
          classNamePrefix="react-select"
          className="flex items-center text-sm"
          value={value}
          placeholder={translate('user_space')}
          isClearable
          isSearchable
          backspaceRemovesValue={false}
          onChange={handleChange}
          onInputChange={handleSearch}
          options={options?.map((o) => ({
            value: o.ownerKey,
            label: o.name,
          }))}
        />
        {!!(isGodMode && emailFb) && <div className="absolute left-0 text-xs top-[1.5rem]">{emailFb}</div>}
      </div>
    </>
  );
};

export default UserSpaceSelect;
