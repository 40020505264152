import { FreeGift } from './BundleDeal';
import CartStatus from './CartStatus';
import Customer from './Customer';
import PostDetailData from './PostDetailData';
import Product, { ProductStatusKeys } from './Product';

export enum SoldListStatus {
  OutOfStock = -1,
  None,
  Reserved,
  Paid,
}

export type SoldFilterForm = {
  pageNumber?: number;
  pageSize?: number;
  dateRangeFrom?: string;
  dateRangeTo?: string;
  status?: SoldListStatus[];
  inCartQuantity?: InCartFilterValue[];
  postIds?: string[];
  // platformIds & pageIds just for displaying on FE
  platformIds?: string[];
  pageIds?: string[];
};

export enum MessageStatus {
  UnknownError = -100,
  ExceedLengthLimit = -3,
  ReplyingTimeExpiredError,
  AlreadyRepliedError,
  None,
  Success,
  MarkSuccesBySeller = 100,
}

export enum SoldListTag {
  SellerEdit = 1,
  BuyerEdit,
  SellerAndBuyerEdit,
}

export type FilteredBiddingStats = {
  biddingOutOfStockQuantity?: number;
  biddingQuantity?: number;
  inCartQuantity?: number;
  paidQuantity?: number;
};

export type SoldListDataResponse = {
  data: SoldList[];
  total?: number;
} & FilteredBiddingStats;

export type SoldList = {
  addedTime: string;
  biddingQuantity: number;
  cartId?: string;
  customer: Customer;
  inCartQuantity: number;
  messageStatus: MessageStatus;
  oosQuantity: number;
  posts: PostDetailData[];
  productId: string;
  status: SoldListStatus;
  tags: SoldListTag;
  cartStatus: CartStatus;
};

export type SoldListProduct = {
  bidding?: number;
  outOfStock?: number;
  productRemainStock?: number;
  productStatus?: ProductStatusKeys;
  freeGift?: FreeGift;
  freeGiftTotalRedeemed?: number;
  freeGiftTotalRemainingStock?: number;
  freeGiftTotalStock?: number;
} & Product;

export enum InCartFilterValue {
  SellerEdited = 'seller_edited',
  MessageSentFailed = 'sent_failed',
  MessageSentBySystem = 'sent_by_system',
  MessageSentManually = 'sent_manually',
}
