import React, { FunctionComponent, useContext, useMemo } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tooltip } from 'antd';
import useSWR from 'swr';

import Revenue from 'models/Revenue';
import { currentUser, formatCurrency } from 'utils/functions';
import { useSegement } from 'utils/hooks';
import { usePinCode } from 'hooks/pincode';
import { LanguageContext } from 'context/language';

import T from './Translator';

const RevenueQuickSummary: FunctionComponent = () => {
  const { analyticsTrack } = useSegement();
  const { translate } = useContext(LanguageContext);

  const { popover, show, onHide } = usePinCode({
    title: <T value="enter_pin_code_to_show_revenue_summary" />,
    onSuccess: () => {
      analyticsTrack('Clicked To See LiveSelling Amount');
    },
  });

  const { data: revenue, isValidating } = useSWR<Revenue>(show ? `/cart-service/api/carts/revenue` : null, {
    refreshInterval: 60000,
    revalidateOnFocus: true,
  });

  const collected = useMemo(() => {
    const sold = revenue?.sold || 0;
    //hack trick for S'Gorgeous, will remove as soon as possible
    if (currentUser() === 'facebook|266949948299666') return sold - 3360893.24;
    return sold;
  }, [revenue?.sold]);

  return (
    <div className="flex items-center">
      {!show ? (
        <>
          {popover(
            <Tooltip title={<T value="show_revenue" />}>
              <Button type="text" size="large" icon={<EyeInvisibleOutlined />} className="text-sm">
                {translate('revenue')}
              </Button>
            </Tooltip>
          )}
        </>
      ) : !revenue && isValidating ? (
        <Spin />
      ) : (
        <Tooltip title={<T value="hide_revenue" />}>
          <Space className="text-xs border rounded-sm px-3 py-1 cursor-pointer hover:bg-gray-300" onClick={onHide}>
            <EyeOutlined className="text-base" />
            <Space direction="vertical" className="text-neutral-500">
              <T value="collected:" />
              <T value="pending:" />
            </Space>
            <Space direction="vertical">
              <span className="font-bold">{formatCurrency(collected)}</span>
              {formatCurrency(revenue?.reserved || 0)}
            </Space>
          </Space>
        </Tooltip>
      )}
    </div>
  );
};

export default RevenueQuickSummary;
