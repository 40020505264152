import React from 'react';
import { Modal } from 'antd';

import { LanguageContext } from 'context/language';
import T from './Translator';

type BlockModalProps = {
  show: boolean;
  onAgree: () => void;
};

const BlockModal: React.FunctionComponent<BlockModalProps> = ({ show, onAgree }: BlockModalProps) => {
  const { translate } = React.useContext(LanguageContext);

  const link = window.location.hostname === 'dashboard.upmesh.io' ? 'dashboard.upmesh.my' : 'subadmin.upmesh.my';

  return (
    <Modal
      visible={show}
      onOk={onAgree}
      onCancel={() => null}
      okText={<T value="agree" />}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.95)' }}
      centered
      closable={false}
      cancelButtonProps={{ className: 'hidden' }}
      width={900}
    >
      <span
        className="text-lg text-center"
        dangerouslySetInnerHTML={{
          __html: translate('please_access_other_dashboard', {
            '[LINK]': `<a class="text-blue-500" href="https://${link}">${link}</a>`,
          }),
        }}
      />
    </Modal>
  );
};

export default BlockModal;
