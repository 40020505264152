import { Button, notification, Progress, Space, Typography } from 'antd';
import { cancelDownloadInvoice, getStatusDownloadInvoices } from 'apis/invoices';
import { Dictionary, isEmpty, omit, pickBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import { useSegement, useShowError } from 'utils/hooks';
import { LanguageContext } from './language';
import { getGenericErrors } from 'utils/error';
import { generateCommonTrackingData } from 'utils/functions';
import { DELAY_TIME_EXPORT } from 'utils/constants';

export type DownloadObject = {
  processingId: string;
  type: string;
  cartIdsLength?: number;
  onSuccess?: () => void;
  isPackingList?: boolean;
};

export interface DownloadInvoicesProps {
  value: Dictionary<DownloadObject>;
  setValue: React.Dispatch<React.SetStateAction<Dictionary<DownloadObject>>>;
}

export const DownloadInvoicesContext = React.createContext<DownloadInvoicesProps>({
  value: {},
  setValue: () => null,
});

export const DownloadInvoicesProvider: React.FC = ({ children }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();

  const [value, setValue] = useState<Dictionary<DownloadObject>>({});
  const [progress, setProgress] = useState<
    Dictionary<{
      progress: number;
      file?: string;
    }>
  >({});

  const handleDownloadInvoices = useCallback(
    async (
      processingId: string,
      type: string,
      cartIdsLength: number,
      fileUrl: string,
      onSuccess?: () => void,
      isPackingList?: boolean
    ) => {
      const commonTrackingEventData = generateCommonTrackingData();
      const res = await fetch(fileUrl).then((r) => r.blob());
      const fileNames = fileUrl.split('?X-Amz-Expires')[0].split('/'); // get file name
      FileSaver.saveAs(new Blob([res]), fileNames[fileNames.length - 1]);
      analyticsTrack('Exported File', {
        type: isPackingList ? 'packing list' : 'invoices',
        num_selected: cartIdsLength,
        file_type: 'pdf',
        ...commonTrackingEventData,
      });
      onSuccess?.();
      notification.close(processingId);
    },
    [analyticsTrack]
  );

  const handleCancel = useCallback(
    async (id: string, type: string) => {
      setValue(omit(value, id));
      notification.close(id);
      try {
        await cancelDownloadInvoice(type, id);
      } catch (error) {
        const genericErrors = getGenericErrors(error);
        showError(genericErrors, translate('download_invoices_error'), translate('error_occurred_try_again'));
      }
    },
    [showError, translate, value]
  );

  const updateProgress = (id: string, status: 'doing' | 'done', file?: string) => {
    setProgress((prevState) => {
      let number = 10;
      if (prevState[id]) {
        if (status === 'done') {
          number = 99;
        } else {
          if (prevState[id].progress < 90) {
            number = prevState[id].progress + 10;
          }
        }
      }
      return {
        ...prevState,
        [id]: {
          progress: number,
          file,
        },
      };
    });
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (!isEmpty(value)) {
      const fetchDownloadInvoicesStatus = async () => {
        try {
          if (isEmpty(value)) return clearInterval(intervalId);
          const results = await Promise.allSettled(
            Object.keys(value).map(async (i) => {
              const item = value[i];
              return await getStatusDownloadInvoices(item.type, item.processingId);
            })
          );
          results.map(async (result) => {
            if (result.status === 'rejected') {
              const url = result.reason.config.url;
              const id = url.slice('/cart-service/api/order/invoices/export/'.length, url.length);
              handleCancel(id, value[id].type);
            } else if (result.status === 'fulfilled') {
              const noti = result.value;
              if (noti.fileUrl) {
                updateProgress(noti.id, 'done', noti.fileUrl);
              } else if (noti.exportStatus === -2) {
                setValue(omit(value, noti.id));
                notification.close(noti.id);
                showError([], translate('download_invoices_error'), translate('error_occurred_try_again'));
              } else {
                updateProgress(noti.id, 'doing');
              }
            }
          });
        } catch (error) {
          clearInterval(intervalId);
        }
      };
      fetchDownloadInvoicesStatus();
      intervalId = setInterval(fetchDownloadInvoicesStatus, DELAY_TIME_EXPORT);
    }
    return () => clearInterval(intervalId);
  }, [handleCancel, showError, translate, value]);

  useEffect(() => {
    const successValue = pickBy(progress, (item) => !!item.file);

    Object.keys(successValue).map(async (item) => {
      const process = value[item];
      setProgress(omit(progress, item));
      setValue(omit(value, item));
      if (process && successValue[item].file) {
        await handleDownloadInvoices(
          item,
          process.type,
          process.cartIdsLength || 0,
          successValue[item].file || '',
          process?.onSuccess,
          process.isPackingList
        );
      }
    });
  }, [handleDownloadInvoices, progress, value]);

  const renderProcessingDownload = useCallback(() => {
    if (!isEmpty(value)) {
      return Object.keys(value).map((i) => {
        const item = value[i];
        return notification.open({
          key: item.processingId,
          message: (
            <Space direction="vertical">
              <Typography>{translate('processing_to_download_your_invoices')}</Typography>
              <Typography>{translate('please_wait_and_do_not_close_the_browser')}</Typography>
              <div className="w-full flex justify-between items-center gap-4">
                <Progress percent={progress?.[item.processingId]?.progress || 0} />
                <Button onClick={() => handleCancel(item.processingId, item.type)}>Cancel</Button>
              </div>
            </Space>
          ),
          closeIcon: <></>,
          duration: 0,
        });
      });
    }
    return <></>;
  }, [handleCancel, progress, translate, value]);

  return (
    <DownloadInvoicesContext.Provider
      value={{
        value,
        setValue,
      }}
    >
      {children}
      {renderProcessingDownload()}
    </DownloadInvoicesContext.Provider>
  );
};
