export enum RewardType {
  CreditReward = 1,
  DiscountPercentage = 2,
  FixedDiscount = 3,
}

export enum EarnType {
  Checkout = 1,
  Referral = 2,
  Comment = 3,
  Voting = 4,
  MySpace = 5,
}

export type EarnFromKeyType = 'earnType' | 'isEnabled' | 'earnPoints' | 'earnRate';

export type EarnFrom = {
  [key in EarnFromKeyType]?: EarnType | JSX.Element | boolean | number | null;
};

export enum RewardValueKey {
  Value_1 = 1,
  Value_2 = 2,
}

export type RewardValue = { [key in RewardValueKey]?: number };

export type Reward = {
  [type in RewardType]?: RewardValue;
};

export type Tier = {
  id?: string;
  iconName?: string;
  colorCode?: string;
  name?: string;
  pointReach?: number;
  rewardOptions?: Reward;
  isDefault?: boolean;
};

export type Icon = {
  iconName: string;
  colorCode: string;
};
