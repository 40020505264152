import { Button, Divider, message, Tooltip } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { useAppRegion } from 'hooks/app';
import { LOCALE_ID_MAPPING, STAGING_LOCALE_ID_MAPPING } from 'enums/locales';
import { COUNTRY_ID_MAPPING, STAGING_COUNTRY_ID_MAPPING } from 'enums/country';
import { LanguageContext } from 'context/language';
import { fetcher as getPatchNotes } from 'apis/patchnote';
import { getDateInNYearAfter, getStartAndEndDateOfMonth } from 'utils/date';
import { getTaglishToggleStatus } from 'utils/storage';
import { useSegement } from 'utils/hooks';
import { APP_REGION, HELP_CENTER_LINK, isDev } from 'utils/constants';

import CountryBuildSwitch from 'shared-components/CountryBuildSwitch';
import UserAccount from 'shared-components/UserAccount/UserAccount';
import PatchNoteNotification from 'shared-components/PatchNoteNotification';
import RevenueQuickSummary from 'shared-components/RevenueQuickSummary';
import PatchNoteDrawer from 'shared-components/PatchNoteDrawer/PatchNoteDrawer';
import T from 'shared-components/Translator';

const calculateUnread = (newPostIds: { [id: string]: boolean | undefined }) => {
  let unreadNewPostCount = 0;
  for (const key in newPostIds) {
    if (newPostIds[key] === false) {
      unreadNewPostCount++;
    }
  }

  return unreadNewPostCount;
};

const TopActions = () => {
  const { region } = useAppRegion();
  const { translate } = useContext(LanguageContext);
  const isPH = region === 'ph';
  const [IsTaglishToggleOn, setIsTaglishToggleOn] = useState<boolean>(getTaglishToggleStatus());
  const isUseTaglish = useMemo(() => isPH && IsTaglishToggleOn, [isPH, IsTaglishToggleOn]);
  const { currentLang } = useContext(LanguageContext);
  const langKey = useMemo(() => (isUseTaglish ? 'tgl' : currentLang), [currentLang, isUseTaglish]);
  const newPostIdKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-newPostIds`;
  }, [langKey]);
  const { analyticsTrack } = useSegement();

  const readPostIdKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-readPostIds`;
  }, [langKey]);

  const lastSeenDateKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-lastSeenDate`;
  }, [langKey]);

  const lastSeenDateStr = useMemo(() => {
    return localStorage.getItem(lastSeenDateKey) || '';
  }, [lastSeenDateKey]);
  const [isPatchNoteDrawerOpen, setIsPatchNoteDrawerOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const patchNoteURL = process.env.REACT_APP_PATCH_NOTE_API;
  const localeId = useMemo(() => {
    return patchNoteURL?.includes('staging')
      ? get(STAGING_LOCALE_ID_MAPPING, langKey) || '0'
      : get(LOCALE_ID_MAPPING, langKey) || '0';
  }, [patchNoteURL, langKey]);
  const countryId = useMemo(() => {
    return patchNoteURL?.includes('staging')
      ? get(STAGING_COUNTRY_ID_MAPPING, APP_REGION as string) || '0'
      : get(COUNTRY_ID_MAPPING, APP_REGION as string) || '0';
  }, [patchNoteURL]);
  const [newPostIds, setNewPostIds] = useState<{ [id: string]: boolean | undefined }>(
    JSON.parse(localStorage.getItem(newPostIdKey) as string) || {}
  );

  useEffect(() => {
    const getNewPatchNotePost = async () => {
      const url = new URL(`${process.env.REACT_APP_PATCH_NOTE_API}/wp-json/wp/v2/patchnotes`);
      url.searchParams.append('countries', countryId);
      url.searchParams.append('locales', localeId);
      url.searchParams.append('orderBy', 'releaseDate');

      // query new post by last seen date
      if (!isEmpty(lastSeenDateStr)) {
        url.searchParams.append('start', lastSeenDateStr);
        url.searchParams.append('end', getDateInNYearAfter(lastSeenDateStr, 1));
      }
      // If don't have last seen date, query post that have publish date from current month to future
      else {
        const { firstDate } = getStartAndEndDateOfMonth(new Date());
        url.searchParams.append('start', firstDate);
        url.searchParams.append('end', getDateInNYearAfter(firstDate, 1));
      }
      const response = await getPatchNotes(url.href);
      const postIds: string[] = response?.news?.map?.((item) => item.id) || [];
      const newPosts = postIds.reduce((a, v) => ({ ...a, [v]: false }), {});
      const currentLocalReadPost = JSON.parse(localStorage.getItem(readPostIdKey) as string) || {};
      for (const [key, _] of Object.entries(newPosts)) {
        if (currentLocalReadPost[key] === true) {
          Object.assign(newPosts, { [key]: true });
        }
      }
      localStorage.setItem(newPostIdKey, JSON.stringify(newPosts));
      setNewPostIds(newPosts);
      analyticsTrack('Saw Number of Up-to-date News', {
        num_news: calculateUnread(newPosts),
      });
    };
    if (countryId !== '0' && localeId !== '0') {
      try {
        getNewPatchNotePost();
      } catch (e) {
        message.error(translate('error_occurred'));
      }
    }
  }, [countryId, localeId, newPostIdKey, lastSeenDateStr, readPostIdKey, analyticsTrack, translate]);

  useEffect(() => {
    setCount(calculateUnread(newPostIds));
  }, [analyticsTrack, newPostIds]);

  const decreaseNewsCount = useCallback(() => {
    setCount((count) => {
      if (count > 0) {
        return count - 1;
      }
      return count;
    });
  }, []);

  return (
    <div className="flex flex-row place-items-center gap-4">
      <PatchNoteNotification handleChangePatchNoteDrawerVisible={setIsPatchNoteDrawerOpen} count={count} />
      <Tooltip title={<T value="help_centre" />}>
        <Button
          type="text"
          shape="circle"
          size="large"
          icon={<QuestionCircleOutlined />}
          onClick={() => window.open(HELP_CENTER_LINK, '_blank')}
        ></Button>
      </Tooltip>
      <Divider type="vertical" className="mx-0" />
      <RevenueQuickSummary />
      <Divider type="vertical" className="mx-0" />
      <UserAccount />
      {isDev && <CountryBuildSwitch />}
      <PatchNoteDrawer
        isTaglishToggleOn={IsTaglishToggleOn}
        setIsTaglishToggleOn={setIsTaglishToggleOn}
        visible={isPatchNoteDrawerOpen}
        handleChangePatchNoteDrawerVisible={setIsPatchNoteDrawerOpen}
        decreaseNewsCount={decreaseNewsCount}
      />
    </div>
  );
};

export default React.memo(TopActions);
