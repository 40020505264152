import React from 'react';

export const HeaderContext = React.createContext<{
  title: React.ReactNode;
  setTitle: (value: React.ReactNode) => void;
  extra: React.ReactNode;
  setExtra: (value: React.ReactNode) => void;
}>({
  title: null,
  setTitle: () => null,
  extra: null,
  setExtra: () => null,
});
