/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Space, Tag } from 'antd';
import useSWR from 'swr';
import type { MenuProps } from 'antd';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { isPH, isTH, isMY, isSG, SOCIAL_CHAT, LOGISTICv1, isVN, CURRENT_SHOP_STORAGE_KEY } from 'utils/constants';
import { PermissionContext } from 'context';
import { KycVerificationStatus, PaymentKycStatus, PaymentProvider } from 'models/PaymentKYC';
import { useAbTestingFeatures, useGodMode } from 'utils/hooks';
import { RoutesGroup } from 'routes/RoutesGroup';
import { PreloadableComponent } from 'utils/route';
import { usePaidOrderCount } from 'hooks/fulfillOrder';
import { useCheckFeatByTier } from 'utils/tiers';

import T from 'shared-components/Translator';
import SubscriptionTooltip from 'shared-components/SubscriptionTooltip';
import BlockSpinner from 'shared-components/BlockSpinner';
import FeatureGuide from 'shared-components/FeatureGuide';
import TooltipParagraphProps from 'shared-components/TooltipParagraph';

import { ReactComponent as LiveSellingIcon } from 'assets/icons/notification-status.svg';
import { ReactComponent as CRMIcon } from 'assets/icons/personalcard.svg';
import { ReactComponent as MessageCenterIcon } from 'assets/icons/messages-1.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/note.svg';
import { ReactComponent as PromotionIcon } from 'assets/icons/discount-shape.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/diagram.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/empty-wallet.svg';
import { ReactComponent as CatalogueIcon } from 'assets/icons/shop.svg';
import { ReactComponent as DeliveryIcon } from 'assets/icons/truck-setting.svg';
import { ReactComponent as FulfilmentIcon } from 'assets/icons/truck-fast.svg';
import { ReactComponent as ShipmentIcon } from 'assets/icons/box.svg';
import { ReactComponent as ItemsSoldIcon } from 'assets/icons/bag-tick.svg';
import { ReactComponent as AccountActivationIcon } from 'assets/icons/profile-2user.svg';

import './styles.css';

const eventMenuGroupKeys = ['/lucky-draw', '/bundle-deals', '/promotion'];
const buyerAppKeys = ['/events', '/collections', '/memberships', '/credit'];
const paymentGroupKey = ['/payment', '/financial-account'];
const logisticGroupKey = ['/logistic', '/fulfill-orders', '/fulfill'];
const messageCenterKeys = ['/messages', '/chat', '/settings', '/reply-comment', '/invoice-display'];
const analyticGroupKeys = ['/analytics', '/reports'];

const checkOpenSubMenu = (key: string) => {
  switch (true) {
    case eventMenuGroupKeys.includes(key):
      return 'event-menu-group';
    case buyerAppKeys.includes(key):
      return 'buyer-app';
    case paymentGroupKey.includes(key):
      return 'payment-menu-group';
    case logisticGroupKey.includes(key):
      return 'logistics';
    case messageCenterKeys.includes(key):
      return 'message-menu-group';
    case analyticGroupKeys.includes(key):
      return 'analytics-menu-group';
    default:
      return undefined;
  }
};

const routesByPath = Object.values(RoutesGroup).reduce(
  (acc: { [path: string]: { path: string; component: PreloadableComponent<any> } }, value) => {
    value.forEach((route: any) => {
      acc[route.path] = route;
    });
    return acc;
  },
  {}
);

type NavbarProps = {
  parentRef: React.RefObject<HTMLDivElement>;
  collapsed: boolean;
};

const Navbar = ({ parentRef, collapsed }: NavbarProps) => {
  const history = useHistory();
  const location = useLocation();
  const permissions = useContext(PermissionContext);

  const { hasFeature } = useAbTestingFeatures();
  const hasLogisticFeature = hasFeature(LOGISTICv1) || isTH || isMY || isPH || isVN;
  const hasChatFeature = hasFeature(SOCIAL_CHAT);
  const hasLuckyDraw = useCheckFeatByTier('luckyDraw');
  const hasPromo = useCheckFeatByTier('promoCode');
  const hasBundle = useCheckFeatByTier('bundleDeal');

  const { data: isGodMode } = useGodMode();

  const [openSubMenus, setOpenSubMenus] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const popoverRef = useRef(null);

  const { total: toShipOrdersTotal } = usePaidOrderCount();

  const { data: kycStatus, isValidating } = useSWR<PaymentKycStatus[]>(
    permissions.includes('PaymentService') ? '/payment-service/api/kyc/verification/status' : null
  );

  const stripeStatus = useMemo(
    () => kycStatus?.find((item) => item.paymentProvider === PaymentProvider.Stripe),
    [kycStatus]
  );
  const isVerifiedPaymentKyc = useMemo(
    () => !!stripeStatus && stripeStatus.verificationStatus === KycVerificationStatus.Verified,
    [stripeStatus]
  );
  const paymongoStatus = useMemo(
    () => kycStatus?.find((item) => item.paymentProvider === PaymentProvider.Paymongo),
    [kycStatus]
  );
  const isPaymongoConnected = useMemo(
    () => paymongoStatus?.verificationStatus === KycVerificationStatus.Verified,
    [paymongoStatus]
  );
  const twoCtwoPStatus = useMemo(
    () => kycStatus?.find((item) => item.paymentProvider === PaymentProvider['2c2p']),
    [kycStatus]
  );
  const is2c2pConnected = useMemo(
    () => twoCtwoPStatus?.verificationStatus === KycVerificationStatus.Verified,
    [twoCtwoPStatus]
  );

  // @todo: update when we have new payment provider
  const showTransactionMenu = useMemo(() => {
    if (permissions.includes('PaymentService')) {
      if (isPH) {
        return isPaymongoConnected;
      }

      if (isTH) {
        return is2c2pConnected;
      }

      return isVerifiedPaymentKyc;
    }

    return false;
  }, [permissions, isPaymongoConnected, isVerifiedPaymentKyc, is2c2pConnected]);

  const handleOpenChange = React.useCallback((openKeys: string[]) => {
    setOpenSubMenus(openKeys);
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      const groupMenuFromUrl = checkOpenSubMenu(location.pathname);

      if (groupMenuFromUrl) {
        setOpenSubMenus((prevOpenedSubMenus) =>
          !prevOpenedSubMenus.includes(groupMenuFromUrl)
            ? [...prevOpenedSubMenus, groupMenuFromUrl]
            : prevOpenedSubMenus
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    let keys = [];
    if (location.pathname.includes('/reports')) {
      keys = ['/reports'];
    } else if (location.pathname.includes('/fulfill')) {
      keys = ['/fulfill-orders'];
    } else if (location.pathname.includes('/shippingSettings')) {
      keys = ['/delivery'];
    } else if (location.pathname.includes('/dashboard')) {
      keys = ['/live-sessions'];
    } else if (
      location.pathname.includes('/product-groups') ||
      location.pathname.includes('/product-search') ||
      location.pathname.includes('/product-log')
    ) {
      if (permissions.includes('CartService') && !permissions.includes('ProductService')) {
        keys = ['/product-log'];
      } else {
        keys = ['/products'];
      }
    } else {
      keys = [location.pathname];
    }
    setSelectedKeys(keys);
  }, [location.pathname, permissions]);

  useEffect(() => {
    const parent = parentRef.current;
    const popover = popoverRef.current as any;

    const handleScroll = () => {
      if (parent && popover) {
        const popoverNode = popover?.popupRef?.current?.getElement();
        const popoverRect = popoverNode.getBoundingClientRect();
        const rootNode = popover.getRootDomNode();
        const rootRect = rootNode.getBoundingClientRect();

        const newPosition = rootRect.top - (popoverRect.height - rootRect.height) / 2;

        popoverNode.style.top = `${newPosition}px`;
      }
    };
    if (parent) {
      parent.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (parent) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
    // need to add popoverRef in dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef, popoverRef.current]);

  const handleClickMenu: MenuProps['onClick'] = useCallback(
    (e) => {
      if (e.key.includes('/reports')) {
        return history.push('/reports');
      }
      if (!e.key.includes('/logout')) {
        history.push(e.key);
      }
    },
    [history]
  );

  const renderTitle = useCallback(
    (title: string) => {
      if (collapsed) return <T value={title} />;
      return <TooltipParagraphProps value={title} />;
    },
    [collapsed]
  );

  const styleIcon = useMemo(() => {
    if (collapsed) return { width: '1.5rem', height: '1.5rem' };
    return { width: '1rem', height: '1rem' };
  }, [collapsed]);

  const items: MenuProps['items'] = useMemo(() => {
    const data: MenuProps['items'] = [];
    if (permissions.includes('FacebookService')) {
      data.push({
        label: renderTitle('live_selling'),
        key: '/live-sessions',
        icon: <LiveSellingIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/live-sessions']?.component?.preload,
      });
    }
    if (permissions.includes('ProductService') || permissions.includes('CartService')) {
      const actualKey =
        permissions.includes('CartService') && !permissions.includes('ProductService') ? '/product-log' : '/products';
      data.push({
        label: renderTitle('products'),
        key: actualKey,
        icon: <ProductIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.[actualKey]?.component?.preload,
      });
    }
    if (permissions.includes('CartService')) {
      data.push({
        label: (
          <Space size={4}>
            {renderTitle('items_sold')}
            <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
              New
            </Tag>
          </Space>
        ),
        key: '/items-sold',
        icon: <ItemsSoldIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/items-sold']?.component?.preload,
      });
      data.push({
        label: renderTitle('orders'),
        key: '/carts',
        icon: <OrderIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/carts']?.component?.preload,
      });
      data.push({
        label: renderTitle('shipment'),
        key: '/orders',
        icon: <ShipmentIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/orders']?.component?.preload,
      });
    }
    if (permissions.includes('PromotionService')) {
      const submenu = [];
      if (!isPH) {
        submenu.push({
          label: (
            <SubscriptionTooltip withIcon enabled={!hasLuckyDraw} placement="right">
              {renderTitle('lucky_draw')}
            </SubscriptionTooltip>
          ),
          key: '/lucky-draw',
          disabled: isVN && !hasLuckyDraw,
          onMouseEnter: routesByPath?.['/lucky-draw']?.component?.preload,
        });
      }
      if (isSG || isMY || isPH || isVN) {
        submenu.push(
          {
            label: (
              <SubscriptionTooltip withIcon enabled={!hasBundle} placement="right">
                <Space size={4}>
                  {renderTitle('bundle_deals')}
                  <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
                    New
                  </Tag>
                </Space>
              </SubscriptionTooltip>
            ),
            key: '/bundle-deals',
            disabled: isVN && !hasBundle,
            onMouseEnter: routesByPath?.['/bundle-deals']?.component?.preload,
          },
          {
            label: (
              <SubscriptionTooltip withIcon enabled={!hasPromo} placement="right">
                {renderTitle('store_promo')}
              </SubscriptionTooltip>
            ),
            key: '/promotion',
            disabled: isVN && !hasPromo,
            onMouseEnter: routesByPath?.['/promotion']?.component?.preload,
          }
        );
      }
      data.push({
        label: renderTitle('promotions'),
        key: 'event-menu-group',
        icon: <PromotionIcon style={styleIcon} />,
        children: submenu,
      });
    }
    if (
      permissions.includes('FacebookService') &&
      permissions.includes('AnalyticsFeature') &&
      permissions.includes('StatisticService')
    ) {
      data.push({
        label: renderTitle('analytics'),
        key: 'analytics-menu-group',
        icon: <AnalyticsIcon style={styleIcon} />,
        children: [
          {
            label: renderTitle('business_insights'),
            key: '/analytics',
            onMouseEnter: routesByPath?.['/analytics']?.component?.preload,
          },
          {
            label: renderTitle('reports'),
            key: '/reports',
            onMouseEnter: routesByPath?.['/reports']?.component?.preload,
          },
        ],
      });
    } else if (
      permissions.includes('FacebookService') &&
      permissions.includes('AnalyticsFeature') &&
      !permissions.includes('StatisticService')
    ) {
      data.push({
        label: renderTitle('analytics'),
        key: '/analytics',
        icon: <AnalyticsIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/analytics']?.component?.preload,
      });
    } else if (
      (!permissions.includes('FacebookService') || !permissions.includes('AnalyticsFeature')) &&
      permissions.includes('StatisticService')
    ) {
      data.push({
        label: renderTitle('reports'),
        key: '/reports',
        icon: <AnalyticsIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/reports']?.component?.preload,
      });
    }
    if (permissions.includes('ProfileService') && permissions.includes('PaymentService')) {
      if (isVN || !showTransactionMenu) {
        data.push({
          label: renderTitle('payment'),
          key: '/payment',
          icon: <PaymentIcon style={styleIcon} />,
          onMouseEnter: routesByPath?.['/payment']?.component?.preload,
        });
      } else {
        data.push({
          label: renderTitle('payment'),
          key: 'payment-menu-group',
          icon: <PaymentIcon style={styleIcon} />,
          children: [
            {
              label: renderTitle('accounts'),
              key: '/financial-account',
              onMouseEnter: routesByPath?.['/financial-account']?.component?.preload,
            },
            {
              label: renderTitle('payment_settings'),
              key: '/payment',
              onMouseEnter: routesByPath?.['/payment']?.component?.preload,
            },
          ],
        });
      }
    }
    if (permissions.includes('DeliveryService')) {
      data.push({
        label: renderTitle('delivery_settings'),
        key: '/delivery',
        icon: <DeliveryIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/delivery']?.component?.preload,
      });
    }
    if (
      permissions.includes('ProfileService') ||
      permissions.includes('FacebookService') ||
      permissions.includes('UserService')
    ) {
      const submenu = [];
      if (permissions.includes('ProfileService')) {
        submenu.push({
          label: renderTitle('checkout_display'),
          key: '/settings',
          onMouseEnter: routesByPath?.['/settings']?.component?.preload,
        });
      }
      if (permissions.includes('ProfileService')) {
        submenu.push({
          label: (
            <Space size={2}>
              {renderTitle('invoice_display')}
              <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
                New
              </Tag>
            </Space>
          ),
          key: '/invoice-display',
          onMouseEnter: routesByPath?.['/invoice-display']?.component?.preload,
        });
      }
      if (permissions.includes('ProfileService') && permissions.includes('FacebookService')) {
        submenu.push({
          label: renderTitle('send_in_messenger'),
          key: '/messages',
          onMouseEnter: routesByPath?.['/messages']?.component?.preload,
        });
        submenu.push({
          label: renderTitle('comment_under_post'),
          key: '/reply-comment',
          onMouseEnter: routesByPath?.['/reply-comment']?.component?.preload,
        });
      }
      if (hasChatFeature && permissions.includes('UserService')) {
        submenu.push({
          label: renderTitle('chat_management'),
          key: '/chat',
          onMouseEnter: routesByPath?.['/chat']?.component?.preload,
        });
      }
      if (!isEmpty(submenu)) {
        data.push({
          label: (
            <Space size={2}>
              {renderTitle('message_center')}
              <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
                New
              </Tag>
            </Space>
          ),
          key: 'message-menu-group',
          icon: <MessageCenterIcon style={styleIcon} />,
          children: submenu,
        });
      }
    }
    if (!isVN) {
      const submenu = permissions.includes('PromotionService')
        ? [
            {
              label: renderTitle('memberships'),
              key: '/memberships',
              onMouseEnter: routesByPath?.['/memberships']?.component?.preload,
            },
            {
              label: renderTitle('credit_management'),
              key: '/credit',
              onMouseEnter: routesByPath?.['/credit']?.component?.preload,
            },
          ]
        : [];
      if (permissions.includes('FacebookService')) {
        submenu.push({
          label: (
            <Space size={4}>
              {renderTitle('blacklist')}
              <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
                New
              </Tag>
            </Space>
          ),
          key: '/blacklist',
          onMouseEnter: routesByPath?.['/blacklist']?.component?.preload,
        });
      }
      if (submenu.length) {
        data.push({
          label: (
            <Space size={2}>
              {renderTitle('crm')}
              <Tag className="text-[0.5rem] mr-0 p-[2px] leading-[0.5rem]" color="#389E0D">
                New
              </Tag>
            </Space>
          ),
          key: 'buyer-app',
          icon: <CRMIcon style={styleIcon} />,
          children: submenu,
        });
      }
    }
    if (!isVN && permissions.includes('ProductService')) {
      data.push({
        label: renderTitle('catalogue'),
        key: '/catalogue',
        icon: <CatalogueIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/catalogue']?.component?.preload,
      });
    }
    if (hasLogisticFeature && !isPH && permissions.includes('DeliveryService')) {
      data.push({
        label: (
          <Space size={4}>
            {renderTitle('order_fulfillment')}
            {toShipOrdersTotal > 0 && (
              <span className="font-medium text-white bg-red-600 px-2 py-[0.125rem] text-xxs rounded-xl">
                {toShipOrdersTotal < 10 ? toShipOrdersTotal : '+9'}
              </span>
            )}
          </Space>
        ),
        key: '/fulfill-orders',
        icon: <FulfilmentIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/fulfill-orders']?.component?.preload,
      });
    }
    if (isGodMode) {
      data.push({
        label: renderTitle('account_activation'),
        key: '/account-activation',
        icon: <AccountActivationIcon style={styleIcon} />,
        onMouseEnter: routesByPath?.['/account-activation']?.component?.preload,
      });
    }
    return data;
  }, [
    hasBundle,
    hasChatFeature,
    hasLogisticFeature,
    hasLuckyDraw,
    hasPromo,
    isGodMode,
    permissions,
    renderTitle,
    showTransactionMenu,
    styleIcon,
    toShipOrdersTotal,
  ]);
  if (isValidating) return <BlockSpinner />;
  return (
    <>
      <div className={classnames('navbar', { navbar__collapsed: collapsed })}>
        <div className="navbar__inner">
          <Menu
            mode="inline"
            items={items}
            onClick={handleClickMenu}
            selectedKeys={selectedKeys}
            openKeys={openSubMenus}
            onOpenChange={handleOpenChange}
            className="text-xs bg-gray-300 pt-4"
          />
        </div>
      </div>
      <FeatureGuide
        start={
          !!localStorage.getItem(CURRENT_SHOP_STORAGE_KEY) &&
          ((permissions.includes('PaymentService') && !isValidating && !!kycStatus) ||
            !permissions.includes('PaymentService'))
        }
      />
    </>
  );
};

export default Navbar;
