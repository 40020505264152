export const LOCALE_ID_MAPPING = {
  en: '11',
  ph: '11',
  zh: '13',
  th: '17',
  ms: '14',
  tgl: '27',
  vi: '12',
};

export const STAGING_LOCALE_ID_MAPPING = {
  en: '11',
  ph: '11',
  zh: '13',
  th: '15',
  ms: '14',
  tgl: '17',
  vi: '12',
};
