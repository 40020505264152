import { CopyOutlined, LogoutOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, notification, Space, Typography } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { LanguageContext } from 'context/language';
import { useLogout } from 'hooks/logout';
import { PAGE_TOKEN_EXPIRED_HELP_CENTER } from 'utils/constants';
import { copyTextToClipboard, replaceTextWithUrls } from 'utils/functions';
import { getSeePageTokenExpiredModal, setSeePageTokenExpiredModal } from 'utils/storage';

import T from './Translator';

type PageTokenExpiredModalProps = {
  isExpired?: boolean;
  openManualy?: boolean;
  setOpenManualy?: (open: boolean) => void;
};

const LINK = 'https://www.facebook.com/settings?tab=business_tools&section=active';

const PageTokenExpiredModal = ({ isExpired, openManualy, setOpenManualy }: PageTokenExpiredModalProps) => {
  const { translate } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const { logout } = useLogout();

  const handleLogout = useCallback(() => {
    setOpen(false);
    logout();
  }, [logout]);

  const handleLater = useCallback(() => {
    setSeePageTokenExpiredModal();
    setOpen(false);
    setOpenManualy?.(false);
  }, [setOpenManualy]);

  useEffect(() => {
    const seen = getSeePageTokenExpiredModal();
    const isOpen = !!isExpired && !seen;
    setOpen(isOpen);
  }, [isExpired]);

  return (
    <Modal
      open={openManualy || open}
      width={700}
      centered
      closable={false}
      maskClosable={false}
      okText={
        <Space size={4}>
          <LogoutOutlined /> <T value="logout" />
        </Space>
      }
      onOk={handleLogout}
      cancelText={<T value="ill_do_it_later" />}
      onCancel={handleLater}
      bodyStyle={{ padding: '1rem' }}
    >
      <Space size={32} direction="vertical">
        <Alert
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: replaceTextWithUrls(translate('page_token_has_expired_error'), [
                  PAGE_TOKEN_EXPIRED_HELP_CENTER,
                ]),
              }}
            ></span>
          }
          type="error"
          showIcon
        />
        <ol className="pl-4 mb-0">
          <li className="mb-4">
            <T value="page_token_has_expired_guide_1" />
          </li>
          <li className="mb-4">
            <Space className="w-full" direction="vertical" size={4}>
              <Space className="items-start justify-between w-full">
                <Space direction="vertical" size={4}>
                  <T value="page_token_has_expired_guide_2_1" />
                  <Typography.Link href={LINK} target="_blank">
                    {LINK}
                  </Typography.Link>
                </Space>
                <Button
                  onClick={() =>
                    copyTextToClipboard(LINK).then(() =>
                      notification.success({
                        message: translate('copied'),
                        duration: 1,
                      })
                    )
                  }
                  icon={<CopyOutlined />}
                >
                  {translate('copy_link')}
                </Button>
              </Space>
              <Typography className="text-xs text-neutral-500">
                <T value="page_token_has_expired_guide_2_2" />
              </Typography>
            </Space>
          </li>
          <li className="mb-4">
            <T value="page_token_has_expired_guide_3" />
          </li>
          <li className="mb-4">
            <span
              dangerouslySetInnerHTML={{
                __html: translate('page_token_has_expired_guide_4_1'),
              }}
            />
            <Typography className="text-xs text-neutral-500 whitespace-pre">
              <T value="page_token_has_expired_guide_4_2" />
            </Typography>
          </li>
        </ol>
      </Space>
    </Modal>
  );
};

export default PageTokenExpiredModal;
