import { Tooltip, notification } from 'antd';
import React, { useContext } from 'react';
import { TooltipPlacement } from 'antd/es/tooltip';

import LockImg from 'assets/lock_sub_img.gif';
import CrowIc from 'assets/crown_icon.png';
import T from './Translator';
import { copyTextToClipboard } from 'utils/functions';
import { LanguageContext } from 'context/language';
import { isVN } from 'utils/constants';

const HOTLINE = '0399082322';
interface SubscriptionTooltipProps {
  children: JSX.Element;
  enabled?: boolean;
  placement?: TooltipPlacement;
  withIcon?: boolean;
}

const SubscriptionTooltip = ({ children, enabled, placement = 'top', withIcon = false }: SubscriptionTooltipProps) => {
  const { translate } = useContext(LanguageContext);

  if (!enabled || !isVN) {
    return children;
  }

  return (
    <Tooltip
      color="white"
      placement={placement}
      title={
        <div className="flex">
          <div className="mr-4">
            <div
              className="rounded-full"
              style={{
                width: 80,
                height: 80,
                background: `url(${LockImg}) no-repeat center center`,
                backgroundSize: 'cover',
              }}
            />
          </div>
          <div>
            <T value="subscription_description" />
            <div
              className="mt-2 cursor-pointer text-brand-500"
              onClick={async () => {
                await copyTextToClipboard(HOTLINE);
                notification.success({
                  message: translate('copied'),
                });
              }}
            >
              <T value="hotline_zalo" /> {HOTLINE}
            </div>
          </div>
        </div>
      }
      overlayInnerStyle={{ color: 'black', width: 320, padding: '1rem' }}
    >
      <div className="relative">
        {children}
        {!!withIcon && <img src={CrowIc} className="absolute" style={{ width: 16, right: 0, top: 10 }} />}
      </div>
    </Tooltip>
  );
};

export default SubscriptionTooltip;
