import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { mutate } from 'swr';
import { isBlank } from './functions';

export interface RequestWithErrorLoggingConfig extends AxiosRequestConfig {
  mutateConfig?: {
    mutatePath: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutatedData?: any;
    mutateType?: 'multiple' | 'single';
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requestWithErrorLogging = async <T = any>(
  config: RequestWithErrorLoggingConfig
): Promise<AxiosResponse<T>> => {
  const { mutateConfig } = config;
  try {
    const result = await Axios.request(config);

    if (result.status === 200 && mutateConfig?.mutatedData) {
      mutateConfig.mutatePath.forEach((path, index) => {
        if (mutateConfig.mutateType === 'multiple') {
          mutate(path, mutateConfig.mutatedData[index], false);
        } else {
          mutate(path, mutateConfig.mutatedData, false);
        }
      });
    }

    if (result.status === 200 && mutateConfig) {
      mutateConfig.mutatePath.forEach((path) => {
        mutate(path);
      });
    }

    if (result.status === 204 && mutateConfig) {
      mutateConfig.mutatePath.forEach((path) => {
        mutate(path);
      });
    }
    return result;
  } catch (error) {
    const serverErrors = _.get(error, 'response.data');
    if (serverErrors) {
      // eslint-disable-next-line no-console
      console.error(serverErrors);
    }
    throw error;
  }
};

export const requestParamsFromObject = (
  params: Record<string, string[] | number[] | undefined | null | string | number | boolean>
): string => {
  const joined = Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return (params[key] as string[]).map((o) => `${key}=${encodeURIComponent(o)}`).join('&');
      }

      return `${key}=${encodeURIComponent(params[key] as string)}`;
    })
    .join('&');

  return isBlank(joined) ? '' : `?${joined}`;
};
