import { Avatar, Divider, Popover, Space, Typography } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSegement } from 'utils/hooks';
import { PermissionContext } from 'context';
import UserSpaceContext from 'context/userSpace';
import { useLogout } from 'hooks/logout';

import T from '../Translator';
import LanguagesSelect from 'shared-components/LanguagesSelect';

import { ReactComponent as ProfileIcon } from 'assets/profile-circle.svg';
import { ReactComponent as LogOutIcon } from 'assets/log-out.svg';

import './styles.css';

const UserAccount = () => {
  const history = useHistory();

  const permissions = useContext(PermissionContext);
  const { data: profileData } = useContext(UserSpaceContext);

  const [isOpen, setIsOpen] = useState(false);

  const { analyticsTrack } = useSegement();
  const { logout } = useLogout();

  const doLogout = useCallback(() => {
    analyticsTrack('Logged Out');
    logout();
  }, [analyticsTrack, logout]);

  return (
    <Popover
      title={<Typography className="text-base">{profileData?.businessProfile?.businessName}</Typography>}
      content={
        <Space direction="vertical" size={0} className="w-full">
          {permissions.includes('ProfileService') && (
            <Space
              className="cursor-pointer w-full px-4 py-2 hover:bg-gray-200"
              onClick={() => {
                setIsOpen(false);
                history.push('/profile');
              }}
            >
              <ProfileIcon />
              <T value="my_account" />
            </Space>
          )}
          <LanguagesSelect />
          <Divider className="m-0" />
          <Space
            className="cursor-pointer w-full px-4 py-2 hover:bg-gray-200"
            onClick={() => {
              setIsOpen(false);
              doLogout();
            }}
          >
            <LogOutIcon />
            <T value="logout" />
          </Space>
        </Space>
      }
      trigger="click"
      placement="bottomRight"
      arrowPointAtCenter
      destroyTooltipOnHide
      overlayClassName="user-account"
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Avatar
        src={profileData?.businessProfile?.logo}
        className="border border-neutral-100 border-solid cursor-pointer"
        size={48}
      />
    </Popover>
  );
};

export default UserAccount;
