import { ProductImage, ProductStatusKeys } from './Product';

export type VariantAttributeType = {
  id?: string;
  attributeId?: string;
  attributeName: string;
  optionId?: string;
  optionName: string;
};

export type OptionDataType = {
  optionName?: string;
  optionId?: string;
};

export type AttributesDataType = {
  id?: string;
  attributeName?: string;
  options?: OptionDataType[];
  productCategoryAttributeId?: string;
  optionId?: string;
  optionName?: string;
};

export enum PricingModel {
  None = 0,
  DirectPurchase = 1,
  Commission = 2,
}

export interface ProductVariant {
  id?: string;
  productCode?: string;
  productVariantValues?: VariantAttributeType[];
  image?: ProductImage;
  price?: number;
  stock?: number;
  costPrice?: number;
  maximumQuantityPerPost?: number | null;
  // data in list
  productName?: string;
  description?: string;
  productStock?: number;
  productRemainStock?: number;
  productSold?: number;
  productSoldTracking?: number;
  productReserved?: number;
  maximumQuantityPerBuyer?: number;
  created?: string;
  postId?: string;
  productImage?: ProductImage;
  status?: ProductStatusKeys;
  isMaster?: boolean;
  masterProductId?: string;
  masterProductName?: string;
  masterProductCode?: string;
  masterProductImages?: ProductImage[];
  productImages?: ProductImage[];
  sku?: string;
  shippingGroup?: {
    id?: string;
    shippingGroupId?: string;
    shippingGroupName?: string;
  };
  categoryId?: number;
  height?: number;
  length?: number;
  weight?: number;
  width?: number;
  isDelete?: boolean;
  dropShip?: boolean;
  recommendedRetailPrice?: number;
  isAllowEditProductCode?: boolean;
  pricingModel?: PricingModel;
  newProductRemainStockToApply?: number;
  currentProductRemainStock?: number;
  productReservedAndSold?: number;
  isPinned?: boolean;
  isDeleted?: boolean;
  rank?: number;
  subTotalPaid?: number;
  subTotalPending?: number;
  productTotalStock?: number;
  freeGiftBundleTierId?: string;
}

export type ESProductVariant = {
  product_id: string;
  master_product_id?: string;
};
