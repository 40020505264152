import Axios from 'axios';
import { groupBy } from 'lodash';

import { Category } from 'models/Category';
import { OTHER_CATEGORY_OPTION } from 'utils/constants';
import { connectCategoryLevels } from 'utils/functions';

export const getCategories = async () => {
  const res = await Axios.get<Category[]>('product-service/api/categories');

  const rawCategories = res.data;
  const othersItem = rawCategories?.find((o) => o.id === OTHER_CATEGORY_OPTION);
  const sortedRawCategories = othersItem
    ? [...(rawCategories?.filter((o) => o.id !== OTHER_CATEGORY_OPTION) || []), othersItem]
    : rawCategories;
  const categories = sortedRawCategories?.map((o) => ({ ...o, name: o.nameKey || '' }));
  const filteredCategoriesLevel3 = categories?.filter((o) => o.level === 3) || [];
  const categoriesLevel3GroupByParentId = groupBy(filteredCategoriesLevel3, (category) => category.parentId);
  const formattedCategoriesLevel2 = connectCategoryLevels({
    categories,
    currentLevel: 2,
    childCategoriesList: categoriesLevel3GroupByParentId,
  });
  const categoriesLevel2GroupByParentId = groupBy(formattedCategoriesLevel2, (category) => category.parentId);
  const formattedCategoriesLevel1 = connectCategoryLevels({
    categories,
    currentLevel: 1,
    childCategoriesList: categoriesLevel2GroupByParentId,
  });
  const listCategoryLevel1 = categories?.filter((o) => o.level === 1) || [];
  const categoriesLevel1IsLeaf = listCategoryLevel1
    .filter((o) => o.isLeaf)
    .map((o) => ({ ...o, parentId: o.id.toString() }));

  const formattedCategoriesLevel1List = groupBy(categoriesLevel1IsLeaf, (category) => category.parentId);
  const formattedCategoriesList = {
    ...categoriesLevel2GroupByParentId,
    ...formattedCategoriesLevel1List,
  };
  return { categories: formattedCategoriesLevel1, formattedCategoriesList };
};
