/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from 'axios';
import { flatten } from 'lodash';
import Product, { ProductImage } from 'models/Product';

import { ProductVariant } from 'models/ProductVariant';
import { DEFAULT_NORMAL_PRODUCT_PAGE_SIZE } from 'hooks/product';

export const checkProductsUsedInCarts = async (productIds: string[]) => {
  const theRestProductIds = [...productIds];
  const requestAll = [];

  while (theRestProductIds.length > 0) {
    const chunkProductIds = theRestProductIds.splice(0, 30);
    const queryString = chunkProductIds.map((o) => `productIds=${o}`).join('&');

    requestAll.push(Axios.get<{ productIds: string[] }>(`/cart-service/api/carts/product-exist?${queryString}`));
  }

  const promiseAllRes = await Promise.all(requestAll);

  return flatten(promiseAllRes?.map((o) => o?.data?.productIds || []));
};

export const checkShippingGroupUsedInCarts = async (shippingGroupId: string) => {
  const res = await Axios.get(`/cart-service/api/carts/shipping-group-exist/${shippingGroupId}`);
  return res?.data?.shippingGroupId;
};

export const fetchProductByIds = (productIds: string[]) => async () => {
  const res = await Axios.post(`/product-service/api/products/search/ids`, {
    ids: productIds,
  });
  return res?.data;
};

export const fetchVariantByIds = (variantIds: string[]) => async () => {
  const res = await Axios.post<{
    data: ProductVariant[];
  }>(`/product-service/api/products/search/variants/ids`, {
    ids: variantIds,
  });
  return res?.data;
};

export const fetchProductDetailByIds = async (productIds: string[]) => {
  const data: Product[] = [];
  for (let i = 0; i < productIds.length; i++) {
    const res = await Axios.get(`/product-service/api/products/${productIds[i]}`);
    data.push(res.data);
  }
  return data;
};

export const fetchNormalProductsByTotal = async (totalProducts: number) => {
  const data: Product[] = [];
  const maxPageNumber = Math.ceil(totalProducts / DEFAULT_NORMAL_PRODUCT_PAGE_SIZE);
  for (let i = 0; i < maxPageNumber; i++) {
    const res = await Axios.get(
      `/product-service/api/products/normal-products?pageNumber=${i + 1}&pageSize=${DEFAULT_NORMAL_PRODUCT_PAGE_SIZE}`
    );
    data.push(...res.data.data);
  }
  return data;
};

export const reindexProducts = async () => {
  return await Axios.post('/product-service/api/products/reload-products');
};

export const getProductCode = async () => {
  const res = await Axios.get<string>('/product-service/api/products/new-product-code');
  return res.data;
};

export const massCreateQuickProduct = async (products: Product[]) => {
  const res = await Axios.post('/product-service/api/products/simple-products', {
    products,
  });
  return res.data;
};

export const updateProductImage = async (productId: string, productImage: ProductImage | null) => {
  const res = await Axios.post('/product-service/api/products/update-image', {
    productId,
    productImage,
  });
  return res.data;
};

export const fetchProductDuringLiveStreamById = (ids: string[], capturingSessionId: string) => async () => {
  const res = await Axios.post<{
    data: ProductVariant[];
  }>('/facebook-service/api/products/search/ids/capturing-sessions/during', {
    ids,
    capturingSessionId,
  });
  return res?.data;
};

export const fetchProductAfterLiveStreamById = (ids: string[], capturingSessionId: string) => async () => {
  const res = await Axios.post<{
    data: ProductVariant[];
  }>('/facebook-service/api/products/search/ids/capturing-sessions/after', {
    ids,
    capturingSessionId,
  });
  return res?.data;
};

export const getProductDetails = async (id: string) => {
  const res = await Axios.get<Product>(`/product-service/api/products/${id}`);
  return res.data;
};
