export enum ReportTemplate {
  PackingList = 1,
  Fulfillment = 2,
  QuantitySold = 3,
  TransactionSale = 4,
  TopSpender = 5,
  FillupPurchaseOrder,
}

export interface RequestGenerateReportParams {
  postIds?: string[];
  timezone: number;
  orderCreatedStart?: string;
  orderCreatedEnd?: string;
}

export enum ExportReportStatus {
  Failed = -2,
  Deleted = -1,
  New = 0,
  Processing = 1,
  Completed = 2,
  Cancel = 3,
}

export interface ReportItem {
  id: string;
  name: string;
  size: string;
  date: string;
  url: string;
}

export interface ReportDetail {
  id: string;
  completedRow: number;
  completedTime: string | null;
  estimateRow: number;
  fileKey: string | null;
  fileName: string | null;
  fileUrl: string | null;
  fileSize: string;
  reportType: ReportTemplate;
  status: ExportReportStatus;
}
