import Axios from 'axios';
import { collection, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';

import { ICustomer, IMessage, IWorkspace } from 'models/Chat';
import { db } from '../Firebase';
import { onRequestSuccess } from './search';

export enum Collection {
  Workspaces = 'workspaces',
  Customers = 'customers',
}

export interface FirebaseAuthRes {
  access_token: string;
  claims: {
    email: string;
    id: string;
    workspaces: IWorkspace[];
  };
}

const BASE_SEARCH_URL = process.env.REACT_APP_FIREBASE_API_BASE_URL;

const firebaseInstance = Axios.create({
  baseURL: BASE_SEARCH_URL,
});
firebaseInstance.interceptors.request.use(onRequestSuccess);

export const getFirebaseToken = async () => {
  const res = await firebaseInstance.get<FirebaseAuthRes>('/api/auth/me');

  return res.data;
};

export const subscribeListWorkspace = (setListWorkspaceData: React.Dispatch<React.SetStateAction<IWorkspace[]>>) => {
  const q = query(collection(db, Collection.Workspaces));

  return onSnapshot(q, (querySnapshot) => {
    const workspaces: IWorkspace[] = [];
    querySnapshot.forEach((doc) => {
      workspaces.push(doc.data() as IWorkspace);
    });
    setListWorkspaceData(workspaces);
  });
};

export const subscribeUser = (
  customerId: string,
  setUserData: React.Dispatch<React.SetStateAction<ICustomer | undefined>>
) => {
  const q = doc(db, Collection.Customers, customerId);

  return onSnapshot(q, (doc) => {
    const user = doc.data() as ICustomer;
    setUserData(user);
  });
};

export const subscribeListUser = (
  workspaceId: string,
  setListUserData: React.Dispatch<React.SetStateAction<ICustomer[]>>
) => {
  const q = query(
    collection(db, Collection.Workspaces, workspaceId, 'customers'),
    orderBy('latest_message.created_at', 'desc')
  );

  return onSnapshot(q, (querySnapshot) => {
    const users: ICustomer[] = [];
    querySnapshot.forEach((doc) => {
      users.push(doc.data() as ICustomer);
    });
    setListUserData(users);
  });
};

export const subscribeChatData = (
  customerId: string,
  setChatData: React.Dispatch<React.SetStateAction<IMessage[]>>
) => {
  const q = query(collection(db, Collection.Customers, customerId, 'messages'), orderBy('created_at'));

  return onSnapshot(q, (querySnapshot) => {
    const messages: IMessage[] = [];
    querySnapshot.forEach((doc) => {
      messages.push(doc.data() as IMessage);
    });
    setChatData(messages);
  });
};

export const sendMessageData = (customerId: string, message: IMessage) => {
  const customerChatRef = doc(db, Collection.Customers, customerId, 'messages', message.id);

  return setDoc(customerChatRef, message);
};
