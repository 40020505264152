import React, { useState, useEffect, useCallback, useMemo, FunctionComponent, ReactNode } from 'react';
import { Dictionary, omit, values } from 'lodash';
import { Dropdown, Button, Menu, Checkbox, Input, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import IdNamePair from 'models/IdNamePair';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchAndSelectDropdownProps {
  onChange: (options: IdNamePair[], type: string) => void;
  IconComponent?: React.ReactNode;
  type: string;
  options: any[];
  selected: IdNamePair[];
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
  searchBarPlaceholder?: string;
  multipleSelectText: string;
  oneSelectText: string;
  noneSelectText: string;
  dropdownProps?: any;
  customDisplayTextSelected?: ReactNode;
  selectClassname?: string;
  prefix?: ReactNode;
}

const SearchAndSelectDropdown: FunctionComponent<SearchAndSelectDropdownProps> = ({
  onChange,
  IconComponent,
  type,
  options,
  selected,
  onSearch,
  searchBarPlaceholder,
  multipleSelectText,
  oneSelectText,
  noneSelectText,
  dropdownProps,
  customDisplayTextSelected,
  selectClassname,
  prefix,
}: SearchAndSelectDropdownProps) => {
  const [filterOptions, setFilterOptions] = useState<IdNamePair[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Dictionary<IdNamePair>>({});
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  useEffect(() => {
    const updatedOptions: Dictionary<IdNamePair> = {};
    if (selected) {
      selected.forEach((item) => {
        updatedOptions[item.id] = item;
      });
      setSelectedOptions(updatedOptions);
    }
  }, [options, selected]);

  useEffect(() => {
    const updatedFilterOptions = options?.map((option: IdNamePair) => ({
      ...option,
      isChecked: selectedOptions[option.id] ? true : false,
    }));
    setFilterOptions(updatedFilterOptions);
  }, [options, selectedOptions]);

  const handleCheck = useCallback(
    (option: IdNamePair) => {
      let updatedOptions = {};
      if (selectedOptions[option.id]) updatedOptions = omit(selectedOptions, option.id);
      else updatedOptions = { ...selectedOptions, [option.id]: option };
      setSelectedOptions(updatedOptions);
      onChange(values(updatedOptions), type);
    },
    [selectedOptions, type, onChange]
  );

  const menu = useMemo(() => {
    return (
      <Menu className={`${filterOptions?.length > 6 && 'overflow-y-scroll'} max-w-xs`} style={{ maxHeight: '15rem' }}>
        {onSearch && (
          <Menu.Item key="searchField">
            <Input onChange={(e) => onSearch(e, type)} placeholder={searchBarPlaceholder} />
          </Menu.Item>
        )}
        {filterOptions?.map((option) => (
          <Menu.Item key={option.id} onClick={() => handleCheck(option)} className="truncate">
            <Checkbox checked={option.isChecked} />
            <Typography.Text className="ml-2">{option.name}</Typography.Text>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [filterOptions, handleCheck, onSearch, type, searchBarPlaceholder]);

  const handleVisibleChange = (flag: boolean) => setDropdownVisible(flag);

  const displayTextByAmountSelected = useMemo(() => {
    if (customDisplayTextSelected) return customDisplayTextSelected;
    const amount = Object.keys(selectedOptions).length;
    if (amount >= 1) {
      return `${Object.keys(selectedOptions).length} ${amount > 1 ? multipleSelectText : oneSelectText}`;
    }
    return `${noneSelectText}`;
  }, [customDisplayTextSelected, selectedOptions, noneSelectText, multipleSelectText, oneSelectText]);

  return (
    <div className="relative">
      <Dropdown
        overlay={menu}
        visible={dropdownVisible}
        onVisibleChange={handleVisibleChange}
        className="mt-2 mr-1"
        getPopupContainer={(triggerNode) => triggerNode}
        {...dropdownProps}
      >
        <Button className={`${Object.keys(selectedOptions).length && 'text-blue-500'} ${selectClassname ?? ''}`}>
          {IconComponent}
          {prefix}
          {displayTextByAmountSelected}
          <DownOutlined className="ml-5" />
        </Button>
      </Dropdown>
    </div>
  );
};

export default SearchAndSelectDropdown;
