import { generate, presetPalettes } from '@ant-design/colors';

export const brandColor = generate('#477ff0');
export const primaryColor = '#26d4ab';
export const gray = [
  '#ffffff',
  '#fafafa',
  '#f5f5f5',
  '#e8e8e8',
  '#d9d9d9',
  '#bfbfbf',
  '#8c8c8c',
  '#595959',
  '#262626',
  '#000000',
];
export const infoColor = presetPalettes.blue[5];
export const successColor = presetPalettes.green[5];
export const errorColor = presetPalettes.red[5];
export const warningColor = presetPalettes.yellow[5];

export const red = presetPalettes.red;
export const volcano = presetPalettes.volcano;
export const gold = presetPalettes.gold;
export const yellow = presetPalettes.yellow;
export const lime = presetPalettes.lime;
export const green = presetPalettes.green;
export const cyan = presetPalettes.cyan;
export const blue = presetPalettes.blue;
export const geekblue = presetPalettes.geekblue;
export const puple = presetPalettes.purple;
export const magenta = presetPalettes.magenta;
