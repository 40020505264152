export const COUNTRY_ID_MAPPING = {
  sg: '5',
  th: '6',
  ph: '4',
  my: '3',
  id: '2',
  vn: '7',
};

export const STAGING_COUNTRY_ID_MAPPING = {
  sg: '5',
  th: '6',
  ph: '4',
  my: '3',
  id: '16',
  vn: '7',
};
