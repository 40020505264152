import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

import { CURRENT_SHOP_STORAGE_KEY, PIN_CODE, TOKEN_STORAGE_KEY } from 'utils/constants';
import { removeCurrentAnalyticsFilter, removeCurrentCartFilter, removeCurrentOrderFilter } from 'utils/functions';
import { requestWithErrorLogging } from 'utils/request';
import { removeSeePageTokenExpiredModal } from 'utils/storage';

import { setDownloadAppBannerShowedConfig } from 'shared-components/DownloadApp/utils';

export const useLogout = () => {
  const { logout } = useAuth0();

  const handleLogout = useCallback(
    (isAuthen = true) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      localStorage.removeItem(CURRENT_SHOP_STORAGE_KEY);
      localStorage.removeItem(PIN_CODE);
      removeSeePageTokenExpiredModal();
      removeCurrentCartFilter();
      removeCurrentOrderFilter();
      removeCurrentAnalyticsFilter();
      Cookies.remove('auth');
      Cookies.remove('clicks');
      setDownloadAppBannerShowedConfig();
      if (isAuthen) {
        requestWithErrorLogging({
          url: `/user-service/api/users/logout`,
          method: 'get',
        });
      }
      logout({ returnTo: window.location.origin });
    },
    [logout]
  );
  return { logout: handleLogout };
};
