export enum LuckyDrawType {
  Liker,
  Purchaser,
  Share,
}

export enum LuckyDrawRule {
  Liker,
  CommentValidProductCode,
  LeftAnyComment,
  CommentSpecificKeyword,
  CustomizedParticipantList,
}

export type LuckyDraw = {
  type: LuckyDrawType;
  availableEntries: number;
  time?: number;
  disable?: boolean;
};

export type DrawWinner = {
  cartId?: string;
  name?: string;
  pictureUrl?: string;
};

export type LuckyDrawHistory = {
  type: LuckyDrawRule;
  allowDuplicated?: boolean;
  created?: string;
  luckyDrawWinnerMetadata?: DrawWinner[];
  totalWinner?: number;
  totalParticipant?: number;
  commentSpecificKeyword?: string;
};

export type LuckyDrawReqV2 = {
  postId?: string;
  participantFileUrl?: string;
  type?: LuckyDrawRule;
  includePreviousWinners: boolean;
  winnerCount: number;
  excludeParticipantIds?: string[];
};
