import React, { FunctionComponent, useMemo, useState, useCallback } from 'react';
import { debounce } from 'lodash';

import { LanguageContext } from 'context/language';
import { useFetchFillupShippingGroupIds, useFetchShippingGroups } from 'hooks/shippingGroup';
import { useCheckIsFreelancer } from 'hooks/profile';

import SearchAndSelectDropdown from './SearchAndSelectDropdown';
import IdNamePair from 'models/IdNamePair';

export const FILTER_GENERAL_PRODUCTS_OPTION_ID = 'generalProductsOption';

interface MultiShippingGroupSelectProps {
  value?: string[];
  onChange?: (options: string[]) => void;
  prefix?: React.ReactNode;
}

const MultiShippingGroupSelect: FunctionComponent<MultiShippingGroupSelectProps> = ({
  value,
  onChange,
  prefix,
}: MultiShippingGroupSelectProps) => {
  const { translate } = React.useContext(LanguageContext);

  const [search, setSearch] = useState<string>('');

  const { shippingGroups } = useFetchShippingGroups();
  const { fillupShippingGroupIds } = useFetchFillupShippingGroupIds();
  const { isFreelancer } = useCheckIsFreelancer();

  const formattedShippingGroup = useMemo(
    () =>
      isFreelancer
        ? shippingGroups?.data.filter((o) => !!fillupShippingGroupIds?.includes(o?.id || '')) || []
        : shippingGroups?.data.filter((o) => !fillupShippingGroupIds?.includes(o?.id || '')) || [],
    [fillupShippingGroupIds, isFreelancer, shippingGroups?.data]
  );

  const shippingGroupOptions = useMemo(() => {
    const options = formattedShippingGroup?.map((o) => ({ id: o.id, name: o.groupName })) || [];
    const generalProductsOption = {
      id: FILTER_GENERAL_PRODUCTS_OPTION_ID,
      name: translate('general_product'),
    };
    return isFreelancer ? options : [generalProductsOption, ...options];
  }, [formattedShippingGroup, isFreelancer, translate]);

  const filteredOptions = useMemo(
    () => shippingGroupOptions.filter((o) => o.name?.toLocaleLowerCase().includes(search)),
    [shippingGroupOptions, search]
  );

  const handleSearchDropdown = useMemo(
    () =>
      debounce((val: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = val.target.value?.toLocaleLowerCase();
        setSearch(searchKey);
      }, 250),
    []
  );

  const formattedSelected = useMemo(() => value?.map((value) => ({ id: value, name: value })) || [], [value]);

  const handleChange = useCallback(
    (options: IdNamePair[]) => {
      onChange?.(options?.map((option) => option.id) || []);
    },
    [onChange]
  );

  return (
    <SearchAndSelectDropdown
      onChange={handleChange}
      type="shipping_group_ids"
      options={filteredOptions}
      selected={formattedSelected}
      onSearch={handleSearchDropdown}
      searchBarPlaceholder={translate('search_3_dots')}
      multipleSelectText={translate('selected')}
      oneSelectText={translate('selected')}
      noneSelectText={translate('shipping_group')}
      dropdownProps={{ className: 'mt-0' }}
      prefix={prefix}
    />
  );
};

export default MultiShippingGroupSelect;
