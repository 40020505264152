import { APP_REGION } from './constants';

export const formatNumberWithCommas = (number: number | string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getCurrencyUnitByString = (currency: string): string => {
  const cur = currency.toLowerCase();
  switch (cur) {
    case 'php':
      return '₱';
    case 'myr':
      return 'RM';
    case 'thb':
      return '฿';
    case 'vnd':
      return '₫';
    default:
      return '$';
  }
};

export const getCurrencyUnit = (): string => {
  const env = APP_REGION;
  switch (env) {
    case 'ph':
      return '₱';
    case 'my':
      return 'RM';
    case 'th':
      return '฿';
    case 'vn':
      return '₫';
    case 'sg':
      return 'S$';
    default:
      return '$';
  }
};

export const formatCurrency = (
  rawNum: number | string | undefined,
  currency?: string,
  numberFormatOptions?: Intl.NumberFormatOptions
): string => {
  if (typeof rawNum !== 'number' && !rawNum) return '-';
  if (typeof rawNum === 'string') {
    if (currency) formatNumberWithCommas(`${getCurrencyUnitByString(currency)}${rawNum}`);
    return formatNumberWithCommas(`${getCurrencyUnit()}${rawNum}`);
  }
  const num = Math.abs(rawNum);
  if (rawNum < 0) return `-${formatCurrency(num)}`;

  if (currency) {
    switch (currency) {
      case 'PHP':
        return num.toLocaleString('fil', {
          style: 'currency',
          currency: 'PHP',
          minimumFractionDigits: 2,
          ...numberFormatOptions,
        });
      case 'MYR':
        return num.toLocaleString('ta-MY', {
          style: 'currency',
          currency: 'MYR',
          minimumFractionDigits: 2,
          ...numberFormatOptions,
        });
      case 'THB':
        return num.toLocaleString('th-TH', {
          style: 'currency',
          currency: 'THB',
          minimumFractionDigits: 2,
          ...numberFormatOptions,
        });
      case 'VND':
        return num.toLocaleString('vi-VN', {
          style: 'currency',
          currency: 'VND',
          minimumFractionDigits: 0,
          ...numberFormatOptions,
        });
      case 'SGD':
        return `S$${num.toLocaleString('en-SG', {
          minimumFractionDigits: 2,
          ...numberFormatOptions,
        })}`;
      default:
        return num.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
    }
  }

  const env = APP_REGION;
  switch (env) {
    case 'ph':
      return num.toLocaleString('fil', {
        style: 'currency',
        currency: 'PHP',
        minimumFractionDigits: 2,
        ...numberFormatOptions,
      });
    case 'my':
      return num.toLocaleString('ta-MY', {
        style: 'currency',
        currency: 'MYR',
        minimumFractionDigits: 2,
        ...numberFormatOptions,
      });
    case 'th':
      return num.toLocaleString('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 2,
        ...numberFormatOptions,
      });
    case 'vn':
      return num.toLocaleString('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        ...numberFormatOptions,
      });
    case 'sg':
      return `S$${num.toLocaleString('en-SG', {
        minimumFractionDigits: 2,
        ...numberFormatOptions,
      })}`;
    default:
      return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        ...numberFormatOptions,
      });
  }
};
