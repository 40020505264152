import { AssociateAccount } from './AssociateAccount';
import CartStatus from './CartStatus';
import { DeliveryStatus } from './DeliveryStatus';
import { FulfillStatus } from './Fulfillment';
import { OrderShipment } from './Order';
import TagName from './TagName';
import PaymentStatus from './PaymentStatus';

export type ESData = {
  cart_status?: CartStatus;
  cart_id?: string;
  order_reference_code?: string;
  customer_name?: string;
  customer_id?: string;
  page_ids?: string[];
  page_names?: string[];
  cart_created_at?: string;
  cart_updated_at?: string;
  cart_total?: number;
  cart_expired_at?: string;
  product_ids?: string[];
  order_id?: string;
  recipient_name?: string;
  order_created_at?: string;
  shipment_ids?: string[];
  customers?: AssociateAccount[];
  buyer_names?: string[];
  buyer_ids?: string[];
  customer_type?: string;
  recipient_id?: string;
  buyers?: { id: string; name: string }[];
  shipping_group_ids?: string[];
  number_of_shipments?: number;
  cart_tags?: string[];
  cart_last_downloaded_at?: string;
  cart_tag_enums?: TagName[];
  cart_enable_cart_expiry?: boolean;
  cart_has_payment_proof?: boolean;
  cart_note?: string;
  children?: ShipmentES[];
  cart_update_reason?: string;
  order_address_id_ref?: string;
  paid_amount?: number;
  payment_method_id?: string;
  offline_payment_method_name?: string;
  // VN
  order_total_courier_delivery_fee?: number;
  order_provider_tracking_id?: string;
  order_cod_amount?: number;
  order_payment_status?: PaymentStatus;
  order_prepaid?: number;
  payment_method?: string;
  order_provider_courier_id: string;
  is_master?: boolean;
  children_order?: ESData[];
  full_shipment_ids?: string[];
  internal_note?: string;
  total_product_quantity?: number;
};

export type ShipmentES = {
  delivery_status: DeliveryStatus;
  last_downloaded_at?: string;
  logistic_status: FulfillStatus | null;
  shipment_id: string;
  shipment_name: string;
  shipment_reference_code: string;
  shipment_tag_names?: string[];
  order_id: string;
  shipment_note?: string;
  shipment_reason?: string;
  last_updated_delivery_status?: string;
  shipping_group_id?: string;
  is_being_fulfilled_by_logistic?: boolean;
  shipment_surcharge?: number;
};

export type MoreInfoES = {
  index: string;
  query: string;
};

export const generateShipmentFromEsData = (raw: ShipmentES): OrderShipment => {
  return {
    id: raw.shipment_id,
    deliveryStatus: raw.delivery_status,
    displayDeliveryStatus: raw.delivery_status,
    lastDownloadedAt: raw.last_downloaded_at,
    shipmentName: raw.shipment_name,
    shipmentReferenceCode: raw.shipment_reference_code,
    orderId: raw.order_id,
    shipmentNote: raw.shipment_note,
    shipmentReason: raw.shipment_reason,
    lastUpdateDeliveryStatus: raw.last_updated_delivery_status,
    shippingGroupId: raw.shipping_group_id,
    isBeingFulfilledByLogistic: raw.is_being_fulfilled_by_logistic,
    shipmentSurcharge: raw.shipment_surcharge,
    shipmentTags:
      raw.shipment_tag_names?.map((o) => {
        return {
          id: o,
          name: o,
        };
      }) || [],
  } as OrderShipment;
};
