import React from 'react';
import { Modal } from 'antd';

import ProfileSection from './ProfileSection';
import { useGodMode } from 'utils/hooks';

interface ProfileSectionModalProps {
  onClose: () => void;
  setShowAfterProfileModal: (open: boolean) => void;
}

const ProfileSectionModal = ({ onClose, setShowAfterProfileModal }: ProfileSectionModalProps) => {
  const { data: isGodMode } = useGodMode();

  const handleSubmit = () => {
    onClose();
    setShowAfterProfileModal(true);
  };

  return (
    <Modal
      className="profile-modal"
      open={true}
      title=""
      onCancel={onClose}
      centered
      width={1000}
      footer={null}
      closable={false}
      maskClosable={isGodMode}
    >
      <ProfileSection onSubmit={handleSubmit} />
    </Modal>
  );
};

export default ProfileSectionModal;
