import {
  AttributesDataType,
  ESProductVariant,
  PricingModel,
  ProductVariant,
  VariantAttributeType,
} from './ProductVariant';
import ShippingGroup from './ShippingGroup';

export default interface Product {
  id?: string;
  description?: string;
  price?: number;
  costPrice?: number;
  productCode?: string;
  productImages?: ProductImage[];
  productName?: string;
  productSold?: number;
  productSoldTracking?: number;
  productReserved?: number;
  productStock?: number;
  maximumQuantityPerPost?: number | null;
  userId?: string;
  created?: string;
  postId?: string;
  shippingGroup?: ShippingGroup;
  isAssociatedEvent?: boolean;
  status?: ProductStatusKeys;
  isMaster?: boolean;
  attributes?: AttributesDataType[];
  variants?: ProductVariant[];
  productVariantValues?: VariantAttributeType[];
  productImage?: ProductImage;
  masterProductId?: string;
  masterProductName?: string;
  masterProductCode?: string;
  categoryId?: number;
  sku?: string;
  height?: number;
  length?: number;
  weight?: number;
  width?: number;
  dropShip?: boolean;
  recommendedRetailPrice?: number;
  isAllowEditProductCode?: boolean;
  pricingModel?: PricingModel;
  variantProductCodeNextSequence?: number;
  variantProductCodePrefixSuggest?: string;
  newProductRemainStockToApply?: number;
  currentProductRemainStock?: number;
  productReservedAndSold?: number;
  isPinned?: boolean;
  isDeleted?: boolean;
  rank?: number;
  subTotalPaid?: number;
  subTotalPending?: number;
  priceRange?: number[];
  costPriceRange?: number[];
  variantIds?: string[];
  productTotalStock?: number;
  freeGiftBundleTierId?: string;
  productId?: string;
}

export type ProductImage = {
  id: string;
  index: number;
  name: string;
  size: number;
  type: string;
  uid: string;
  url: string;
};

export type PrepareUploadProductImage = {
  imageRefForCrop: HTMLImageElement;
  uid: string;
  name: string;
  index?: number;
  size?: number;
  type?: string;
  url?: string;
};

export enum ProductStatusKeys {
  Active = 1,
  Inactive,
}

export type ESProduct = {
  product_id: string;
  variants?: ESProductVariant[];
  is_master?: boolean;
};

export enum WeightUnits {
  Gram = 1,
  Kilogram,
}

export enum ProductMigrationStatus {
  Queued,
  Running,
  Resuming,
  Success,
  Failed,
}

export interface ProductMigrationStatusRes {
  total: number;
  numberOfValidProduct: number;
  numberOfInvalidProduct: number;
  progress: number;
  hasError: boolean;
  errorReportUrl: string;
  status: ProductMigrationStatus;
}

export enum ProductMigrationStatusKeys {
  Processing = 'migrate-processing',
  Success = 'migrate-successfully',
  Error = 'migrate-failed',
}
