import React, { ReactNode, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from 'context/language';
import { usePermissions } from 'utils/hooks';

type StepType = {
  selector: string[];
  position?: 'right' | 'bottom' | 'bottomRight';
  content?: ReactNode;
  media?: string;
  width?: number;
  type?: 'contain' | 'cover';
  learnMore?: string;
};

export const useFeatureGuide = () => {
  const { translate } = useContext(LanguageContext);
  const location = useLocation();
  const checkPermission = usePermissions();

  const steps: StepType[] = useMemo(() => {
    const defaultStep: StepType[] = [];
    if (location.pathname.includes('/carts'))
      defaultStep.push({
        selector: ['#prepareSourcingBtn'],
        position: 'bottomRight',
        content: <span>{translate('you_can_now_download_the_sourcing_list_in_excel_format')}</span>,
        learnMore: 'https://www.upmesh.io/help/prepare-sourcing',
      });
    return defaultStep;
  }, [location.pathname, translate]);

  const numberOfStep = useMemo(() => {
    let count = 0;
    if (checkPermission('CartService')) {
      count = +1;
    }
    return count;
  }, [checkPermission]);

  return { steps, numOfStep: numberOfStep };
};
