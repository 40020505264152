import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import history from './utils/history';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css';
import 'react-image-crop/dist/ReactCrop.css';
import './styles/styles.css';
import { isSubadmin } from 'utils/functions';

let clientId: string;
if (isSubadmin()) {
  clientId = process.env.REACT_APP_AUTH0_SUBADMIN_CLIENT_ID ?? '6F8ygMAcactymgaDf3qv0LsHtgPm2Cc6';
} else {
  clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'sYJOVwKzhWx6qJxwbeEtJQVh7LKBxKfb';
}
const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? 'https://upmesh-dev.us.auth0.com/api/v2/';
const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? 'upmesh-dev.us.auth0.com';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: any) => {
  if (appState?.redirectUrl) {
    // litte hack to get token because auth0 do not allow put search params on redirectUri
    const intervalId = setInterval(() => {
      const token = localStorage.getItem('auth');
      if (token) {
        clearInterval(intervalId);
        const url = new URL(decodeURIComponent(appState?.redirectUrl));
        url.searchParams.append('token', token);
        window.location.replace(url.toString());
      }
    }, 1000);
  } else history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

!!process.env.REACT_APP_SENTRY_DSN &&
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.3,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ChunkLoadError'],
  });

ReactDOM.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience={audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
