import React, { useCallback } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import { useAuth0 } from '@auth0/auth0-react';

import { ICustomer, IWorkspace } from 'models/Chat';
import { getFirebaseToken } from 'apis/chat';
import { firebaseAuth } from '../Firebase';
import { TOKEN_STORAGE_KEY } from 'utils/constants';

export interface ChatContextInterace {
  currentWorkspace: IWorkspace | null;
  currentCustomer: ICustomer | null;
  changeWorkspace: (input: IWorkspace) => void;
  changeCustomer: (input: ICustomer) => void;
}

const defaultProps: ChatContextInterace = {
  currentWorkspace: null,
  currentCustomer: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeWorkspace: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeCustomer: () => {},
};

export const ChatContext = React.createContext<ChatContextInterace>(defaultProps);

const ChatContextProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const isLoggedIn = isAuthenticated || !!localStorage.getItem(TOKEN_STORAGE_KEY);
  const [currentWorkspace, setCurrentWorkspace] = React.useState<IWorkspace | null>(null);
  const [currentCustomer, setCurrentCustomer] = React.useState<ICustomer | null>(null);

  const changeWorkspace = useCallback((newWorkspace: IWorkspace) => {
    setCurrentWorkspace(newWorkspace);
  }, []);

  const changeCustomer = useCallback((newCustomer: ICustomer) => {
    setCurrentCustomer(newCustomer);
  }, []);

  React.useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          const data = await getFirebaseToken();
          const token = data.access_token;

          await signInWithCustomToken(firebaseAuth, token);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })();
    }
  }, [isLoggedIn]);

  return (
    <ChatContext.Provider
      value={{
        currentWorkspace,
        currentCustomer,
        changeWorkspace,
        changeCustomer,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
