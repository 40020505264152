/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { get, omit } from 'lodash';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';

import UserSpace from 'models/UserSpace';

import { CURRENT_SHOP_STORAGE_KEY, PROFILE_ID } from 'utils/constants';
import { currentUser, getAcceptLanguage } from 'utils/functions';
import { getGenericErrors } from 'utils/error';

const TIMEOUT = 30 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const setupAxiosInterceptors = (token: string | null, onUnauthenticated: () => void): void => {
  const onRequestSuccess = async (config: AxiosRequestConfig) => {
    try {
      const currentUserSpace = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);

      if (config.headers) {
        config.headers['Accept-Language'] = getAcceptLanguage();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        if (currentUserSpace) {
          const parsedCurrentUserSpace: UserSpace = JSON.parse(currentUserSpace);
          config.headers.UserWorkspace = parsedCurrentUserSpace.ownerKey;
        }
      }
    } catch (error) {
      // Unauthenticated user, return default config
    }

    return config;
  };
  const onResponseSuccess = (response: any) => {
    return response;
  };
  const onResponseError = (err: any) => {
    if (err?.response?.status >= 500 && !!process.env.REACT_APP_SENTRY_DSN) {
      Sentry.captureException(err);
    }
    const profileId = localStorage.getItem(PROFILE_ID);

    amplitude.getInstance()?.logEvent?.(`Seller-Error`, {
      user_id: profileId,
      platform: 'desktop',
      error_message: getGenericErrors(err),
    });
    let body = 'empty';
    const status = err.status || get(err, 'response.status');
    const config = get(err, 'response.config');

    if (config) {
      const genericErrors = getGenericErrors(err).join(',');
      if (config.data) {
        if (config.data instanceof FormData) {
          const data = config.data.get('FileUpload');
          if (data) {
            body = `{"uid":"${data.uid},name:${data.name}","lastModified":"${data.lastModified}","lastModifiedDate":"${data.lastModifiedDate}","size":"${data.size}","type":"${data.type}"}`;
          }
        } else {
          const jsonData = JSON.parse(config.data);
          body = JSON.stringify(omit(jsonData, ['productImages', 'logo', 'paymentInstructionImage']));
        }
      }
      ReactGA.event({
        category: config.url,
        action: `${config.method}_${body}`,
        label: `failed_${currentUser()}_${new Date()}_${genericErrors}`,
      });
    }
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };

  const onCustomUrusBaseUrl = (config: AxiosRequestConfig) => {
    if (config.url?.includes('urus-')) {
      config.baseURL = process.env.REACT_APP_API_URUS_BASE_URL;
    }
    return config;
  };

  axios.interceptors.request.use(onCustomUrusBaseUrl);
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
