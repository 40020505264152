import React, { FunctionComponent, useState } from 'react';
import Routes from 'routes/Routes';
import { useAuth0 } from '@auth0/auth0-react';
import { get, isEmpty } from 'lodash';
import { Button, notification } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { SWRConfig } from 'swr';
import axios from 'axios';
import Axios from 'axios';
import Cookies from 'js-cookie';

import setupAxiosInterceptors from './configs/axiosConfig';
import BlockSpinner from 'shared-components/BlockSpinner';
import { PROFILE_ID, TOKEN_STORAGE_KEY, APP_REGION, LOGIN_CONFIRMATION_REDIRECT } from 'utils/constants';
import UserSettingsContext from 'UserSettingsContext';
import { errorColor } from 'utils/colors';
import LanguageContextProvider from 'context/language';
import ResendVerificationModal from 'routes/Login/ResendVerificationModal';
import ErrorBoundary from 'shared-components/ErrorBoundary';
import ChatContextProvider from 'context/chat';

import 'react-phone-input-2/lib/style.css';
import { DownloadInvoicesProvider } from 'context/invoices';
import { useLogout } from 'hooks/logout';
import PageTokenExpiredModal from 'shared-components/PageTokenExpiredModal';

let installedScripts = false;

const App: FunctionComponent = () => {
  const isPH = APP_REGION === 'ph';
  const [openModal, setOpenModal] = React.useState(false);

  const [initialized, setInitialized] = React.useState(false);
  const { getAccessTokenSilently, isAuthenticated, getAccessTokenWithPopup, error } = useAuth0();
  const [completedAuth, setCompletedAuth] = useState(false);
  const [token, setToken] = useState<string>('');
  const [isPageTokenExpired, setpageTokenExpired] = useState(false);

  const { logout } = useLogout();

  const onUnauthenticated = React.useCallback(() => {
    logout(false);
  }, [logout]);

  React.useEffect(() => {
    if (error) {
      const message = get(error, 'message');
      message?.includes('email') && // email not verfied ==> show error
        notification.open({
          message: `Warning:`,
          description: (
            <div>
              <div>{message}</div>
              <br />
              <div>Cannot find your verification email?</div>
              <Button type="link" onClick={() => setOpenModal(true)}>
                Resend Verification Email
              </Button>
            </div>
          ),
          icon: <WarningOutlined style={{ color: errorColor }} />,
        });
    }
  }, [error]);

  React.useEffect(() => {
    if (window.location.pathname.startsWith('/login')) {
      setInitialized(true);
      return;
    }

    if (!getAccessTokenSilently || !getAccessTokenWithPopup) {
      return;
    }

    const asyncGetToken = async () => {
      const localStorageToken = localStorage.getItem(TOKEN_STORAGE_KEY);
      if (localStorageToken) {
        return {
          token: localStorageToken,
        };
      }

      try {
        const auth0Token = await getAccessTokenSilently();
        return {
          token: auth0Token,
        };
      } catch (e: any) {
        if (e.error === 'consent_required') {
          try {
            const popUpToken = await getAccessTokenWithPopup();

            return {
              token: popUpToken,
            };
          } catch {
            return {
              token: null,
            };
          }
        }
        return {
          token: null,
        };
      }
    };

    (async () => {
      const { token } = await asyncGetToken();

      setToken(token || '');
      if (token) {
        localStorage.setItem('auth', token);
        Cookies.set('auth', token, { domain: '.' + window.location.host.split('.').slice(1).join('.'), expires: 2 });
        setCompletedAuth(true);
      }

      setInitialized(true);
    })();
  }, [getAccessTokenSilently, getAccessTokenWithPopup, initialized, onUnauthenticated]);

  // useEffect just depend on token to initialize Axios Interceptors once
  React.useEffect(() => {
    if (token) {
      setupAxiosInterceptors(token, onUnauthenticated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  React.useEffect(() => {
    (async () => {
      const redirectToLoginConfirmation = !!localStorage.getItem(LOGIN_CONFIRMATION_REDIRECT);
      if (initialized && isAuthenticated && completedAuth && !redirectToLoginConfirmation) {
        try {
          const res = await Promise.all([
            Axios.get('/facebook-service/api/facebook/sync-pages'),
            Axios.get('/facebook-service/api/instagram/sync-pages'),
          ]);
          const errors = res.reduce(
            (cur: any[], prev) => (isEmpty(prev.data.errorPages) ? cur : [...cur, prev.data.errorPages]),
            []
          );
          const myUserWorkSpace = await Axios({
            url: '/user-service/api/workspace',
            method: 'get',
          });
          localStorage.setItem(PROFILE_ID, myUserWorkSpace?.data?.myspace?.mySpaceId || '');
          setpageTokenExpired(!isEmpty(errors));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    })();
  }, [initialized, isAuthenticated, completedAuth]);

  React.useEffect(() => {
    let script: HTMLScriptElement;
    if (isPH && !installedScripts) {
      script = document.createElement('script');
      script.src = 'https://js.hs-scripts.com/8291159.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      document.body.appendChild(script);

      script = document.createElement('script');
      script.src = '//run.louassist.com/v2.5.1-m?id=513493207877';
      document.body.appendChild(script);
      installedScripts = true;
    }
  }, [isPH]);

  return (
    <>
      <ErrorBoundary>
        <UserSettingsContext>
          <LanguageContextProvider>
            <ChatContextProvider>
              <DownloadInvoicesProvider>
                <SWRConfig
                  value={{
                    revalidateOnFocus: false,
                    fetcher: async (url) => (await axios.get(url)).data,
                  }}
                >
                  <ResendVerificationModal open={openModal} onClose={() => setOpenModal(false)} />
                  {initialized ? <Routes completedAuth={completedAuth} /> : <BlockSpinner height="100vh" />}
                  <PageTokenExpiredModal isExpired={isPageTokenExpired} />
                </SWRConfig>
              </DownloadInvoicesProvider>
            </ChatContextProvider>
          </LanguageContextProvider>
        </UserSettingsContext>
      </ErrorBoundary>
    </>
  );
};

export default App;
