import { Badge, Button, Tooltip } from 'antd';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import useSWR from 'swr';
import { BellOutlined } from '@ant-design/icons';

import { Profile } from 'models/Profile';
import { useSegement } from 'utils/hooks';
import { PROFILE_ID } from 'utils/constants';

import T from './Translator';

type PatchNoteNotificationProps = {
  handleChangePatchNoteDrawerVisible: Dispatch<SetStateAction<boolean>>;
  count?: number;
};

const PatchNoteNotification: React.FunctionComponent<PatchNoteNotificationProps> = ({
  handleChangePatchNoteDrawerVisible,
  count = 0,
}: PatchNoteNotificationProps) => {
  const { analyticsIdentify, analyticsTrack, analyticsPage } = useSegement();
  const { data: profile } = useSWR<Profile>('/profile-service/api/profile');

  const handleClickWhatNewButton = useCallback(() => {
    if (profile) {
      analyticsIdentify(localStorage.getItem(PROFILE_ID) || profile.id, {
        name: profile.name,
        business_name: profile.businessName,
        email: profile.email,
        link_to_contact: profile.linkToContact,
        estimated_streaming_hours: profile.estimatedStreamingTime,
        cart_expiry: profile.cartExpiry,
        online_payment_enabled: profile.onlinePaymentEnabled,
        offline_payment_enabled: profile.offlinePaymentEnabled,
        payment_proof_required: profile.offlinePaymentProofRequired,
      });
      analyticsTrack(`Clicked What's New Button`, {
        num_news: count,
      });
      analyticsPage('Viewed Patch Note');
    }
    handleChangePatchNoteDrawerVisible(true);
  }, [analyticsIdentify, analyticsPage, analyticsTrack, count, handleChangePatchNoteDrawerVisible, profile]);

  return (
    <div className="relative">
      <Tooltip title={<T value="what_new" />}>
        <Button
          type="text"
          shape="circle"
          size="large"
          onClick={handleClickWhatNewButton}
          icon={<BellOutlined className="text-xl" />}
        ></Button>
      </Tooltip>
      <Badge size="small" count={count} overflowCount={99} className="absolute top-0 left-7" />
    </div>
  );
};

export default PatchNoteNotification;
