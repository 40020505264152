import { SubLevelLocation } from './Address';
import DeliveryService, { DeliveryServiceV2 } from './DeliveryService';
import Product from './Product';
import { ProductVariant } from './ProductVariant';

export default interface ShippingGroup {
  id?: string;
  count?: number;
  name?: string;
  groupName?: string;
  deliveryOptions?: DeliveryService[];
  products?: (Product | ProductVariant)[];
  note?: string;
  deliveryOption?: DeliveryService;
  shippingGroupId?: string;
  shippingGroupName?: string;
}

export interface OrderShippingGroupDetail {
  deliveryOption?: string;
  deliveryOptionId?: string;
  deliverySurcharge?: number;
  id?: string;
  reason?: string;
  shippingGroupId?: string;
  shippingGroupName?: string;
  orderGroupReferenceCode?: string;
}

export interface ShipmentCouriers {
  country_id?: number;
  deactivate?: boolean;
  isEnabled?: boolean; // for VN only
  id?: number;
  name?: string;
  code: string;
}

export interface Shipping {
  id: string;
  courierTrackingID: string;
  status: string;
  courier: Courier;
  deliveryPlan: DeliveryPlan;
  weightDimension: WeightDimension;
  fee: number;
  pickupPoint: PickupPoint;
  pickupTime: Date;
  pickupInstruction: string;
  cartId: string;
  buyerInfo: BuyerInfo;
  items: Item[];
  createdAt: string;
  stateDate: null | string;
  cancelReasonText?: string;
  cancelReasonCode?: CancelReasonCode;
  // If true use new mass print pdf API.
  printable?: boolean;
}

export enum CancelReasonCode {
  OTHER = '6',
  CANCELLED_BY_CUSTOMER = '80',
  PICKUP_IS_DONE = '82',
  CUSTOMER_UNREACHABLE = '83',
  INVALID_SENDER_PHONE = '85',
  PARCEL_IS_OVERSIZED = '86',
  PARCEL_CONTAIN_PROHIBITED_ITEMS = '87',
  SENDER_ADDRESS_IS_ISLAND = '88',
  SELLER_CANCEL = 'seller_cancel',
}

export interface BuyerInfo {
  name: string;
  email: string;
  contactNumber: string;
  countryCode: string;
  postalCode: string;
  city: string;
  state: string;
  addressLine1: string;
  addressLine2: string;
  subLevelLocations?: SubLevelLocation[];
}

export interface Courier {
  id: number;
  name: string;
  country_id: number;
  deactivate: boolean;
  type: number;
  code: string;
}

export interface DeliveryPlan {
  id: number;
  name: string;
  code: string;
  countryId: number;
  courierId: number;
  minDeliveryDay: number;
  maxDeliveryDay: number;
  deactivate: boolean;
  effectiveDate: Date;
}

export interface Item {
  code: string;
  description: string;
  quantity: number;
}

export interface PickupPoint {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  pickupTime: null;
  pickupInstruction: string;
  address: Address;
  default: boolean;
  deactivate: boolean;
}

export interface Address {
  id: number;
  address1: string;
  address2: string;
  postalCode: string;
  unitNumber: string;
  countryId: number;
  subLevelLocations: null;
  deactivate: boolean;
}

export interface WeightDimension {
  weight: number;
  width: number;
  length: number;
  height: number;
}

export interface ShippingDetailSellerCouriers {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: {
    shopId?: string;
    shopName?: string;
  };
  id?: string;
  verified?: boolean;
}
export interface ShippingDetailSellerCouriersSG {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: {
    merchantCode: string;
  };
  id: string;
  verified: boolean;
}
export interface ShippingDetailSellerCouriersVN {
  authenticationInfo: {
    token?: string;
  };
  courierId: string;
  isEnabled: boolean;
}
export interface ShippingDetailSellerCouriersMY {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: CourierAuthenticationInfoMy;
  id: string;
  verified: boolean;
}
export interface CourierAuthenticationInfoMy {
  customerCode: string;
  password: string;
}
export interface ShippingDetailSellerCouriersFlashExpress {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: {
    subMchId: string;
  };
  id: string;
  verified: boolean;
  deactivate: boolean;
  lastUpdated: Date;
}

export interface CourierAuthenticationInfoPH {
  merchantCode: string;
  key: string;
}
export interface ShippingDetailSellerCouriersPH {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: CourierAuthenticationInfoPH;
  id: string;
  verified: boolean;
}

export interface ShippingDetailSellerCouriersNinjaVanSG {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: CourierAuthenticationInfoNinjaVanSG;
  id: string;
  verified: boolean;
}
export interface CourierAuthenticationInfoNinjaVanSG {
  sellerId: string;
  sellerName: string;
}

export interface CourierAuthenticationInfoChannelJnTTH {
  shopId: string;
  shopName: string;
}

export interface CourierAuthenticationInfoChannelJnTSG {
  merchantCode: string;
}

export enum ShipmentSettingsStatus {
  ADDRESS = 'logistic',
  SHIPPING_CHANNEL = 'shippingChannel',
}

export interface Archive {
  id: string;
  courierTrackingID: string;
  status: string;
  courierStatus: string;
  courierWebhookStatus: string;
  courier: Courier;
  deliveryPlan: DeliveryPlan;
  weightDimension: WeightDimension;
  fee: number;
  cancelReasonText: string;
  cancelReasonCode: string;
  pickupPoint: PickupPoint;
  pickupTime: string;
  pickupStartTime: string;
  pickupEndTime: string;
  pickupInstruction: string;
  cartId: string;
  buyerInfo: BuyerInfo;
  items: Item[];
  printable: boolean;
  createdAt: string;
  stateDate: string;
  archivedAt: string;
}

export const NONE_SHIPPING_GROUP = '-';

export enum CartFulfillmentStatus {
  NOT_SHIP = 'NOT_SHIP',
  DRAFT = 'DRAFT',
  DONE = 'DONE',
}

export interface CartShippingGroup {
  cartId: string;
  id?: string;
  shippingGroupId?: string;
  fulfillmentId?: string;
  fulfillStatus?: CartFulfillmentStatus;
  shipmentId?: string;
}

export interface ArrangeCartShipmentData {
  fulfillStatus?: CartFulfillmentStatus;
  arrangedAll?: boolean;
  shippingGroupIds?: string[];
  shippingGroupFulfillStatus?: { [shippingGroupId: string]: CartFulfillmentStatus }[];
}

export interface CourierAuthenticationInfoKerryExpressTH {
  merchantId: string;
}
export interface ShippingDetailSellerCouriersKerryExpressTH {
  courier: ShipmentCouriers;
  courierAuthenticationInfo: CourierAuthenticationInfoKerryExpressTH;
  id: string;
  verified: boolean;
}

export interface ShippingGroupV2 {
  id?: string;
  groupName?: string;
  note?: string;
  created?: Date;
  products?: (Product | ProductVariant)[];
  deliveryOptions?: DeliveryServiceV2[];
  deliveryOption?: DeliveryServiceV2;
}
