import { TablePaginationConfig, message as antdMessage } from 'antd';
import {
  FulfillOrder,
  FulfillStatus,
  OrderWithToShipStatus,
  RequestArrangeShipResponse,
  ToShipOrder,
} from 'models/Fulfillment';
import { ShipmentName } from 'models/Shipment';
import { Archive, CartFulfillmentStatus, CartShippingGroup } from 'models/ShippingGroup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { getGenericErrors } from 'utils/error';
import { currentUser } from 'utils/functions';
import { useShowError } from 'utils/hooks';
import { requestWithErrorLogging } from 'utils/request';
import { LanguageContext } from '../context/language';
import { requestParamsFromObject } from './../utils/request';
import { useGetOrdersDetail } from './order';

export const useGetToShipOrders = (props?: {
  pagination?: TablePaginationConfig;
  hasLogisticFeature?: boolean | null;
}) => {
  const { pagination, hasLogisticFeature = true } = props || {};
  const { pageSize, current } = pagination || {};
  const currentWorkSpace = currentUser();

  const params = {
    limit: pageSize || 10,
    page: current || 1,
    sort: 'updated_at desc',
  };

  const result = useSWR<{ data: ToShipOrder[]; total: number }>(
    hasLogisticFeature && currentWorkSpace ? `/urus-order-service/paid-orders${requestParamsFromObject(params)}` : null,
    {
      revalidateOnFocus: true,
    }
  );

  return {
    ...result,
    data: result.data?.data || [],
    total: result.data?.total || 0,
    toShipCartIds: result.data?.data?.map((item) => item.cartId) || [],
    isLoading: !hasLogisticFeature ? false : (!result.data && !result.error) || result.isValidating,
  };
};

export const usePaidOrderCount = (props?: { hasLogisticFeature?: boolean | null }) => {
  const { hasLogisticFeature = true } = props || {};
  const currentWorkSpace = currentUser();

  const result = useSWR<{ count: number }>(
    hasLogisticFeature && currentWorkSpace ? `/urus-order-service/paid-orders/count` : null,
    {
      revalidateOnFocus: true,
    }
  );

  return {
    ...result,
    total: result.data?.count || 0,
    isLoading: !hasLogisticFeature ? false : (!result.data && !result.error) || result.isValidating,
  };
};

export const useGetToShipOrderDetails = ({ toShipOrders }: { toShipOrders: ToShipOrder[] }) => {
  const toShipCartIds = toShipOrders?.map((item) => item.cartId);

  const { data: orders, isLoading: isLoadingOrderDetail } = useGetOrdersDetail({ ids: toShipCartIds });

  const ordersWithStatus = useMemo<OrderWithToShipStatus[]>(() => {
    return (toShipOrders || []).map((ord) => {
      const matchDetailOrder = orders?.find((o) => o.cartId === ord.cartId);

      const matchToShipOrder = toShipOrders.find((toShipOrd) => toShipOrd.cartId === ord.cartId);
      if (!matchToShipOrder) {
        return {
          ...ord,
          id: undefined,
        };
      }
      return {
        ...matchDetailOrder,
        id: matchDetailOrder?.id || '',
        shippingGroupId: ord.shippingGroupId,
        toShipStatus: ord.status,
        incorrectInformation: ord.incorrectInformation,
        fulfillmentId: ord.fulfillmentId,
        paidOrderId: ord.id,
      };
    });
  }, [orders, toShipOrders]);

  return {
    isLoading: !toShipOrders || isLoadingOrderDetail,
    data: ordersWithStatus || [],
  };
};

interface ArrangeShipResponse {
  isError: boolean;
  fulfillmentId?: string;
}

export const useArrangeShipPaidOrder = () => {
  const { showError } = useShowError();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onArrangeShip = useCallback(
    async (data: CartShippingGroup[]): Promise<ArrangeShipResponse> => {
      try {
        setIsLoading(true);
        const result = await requestWithErrorLogging<RequestArrangeShipResponse>({
          url: '/urus-order-service/paid-orders/arrange-ship',
          method: 'post',
          data: {
            paidOrders: data,
          },
          mutateConfig: {
            mutatePath: ['/urus-order-service/paid-orders'],
          },
        });

        return {
          isError: false,
          fulfillmentId: result.data.fulfillmentId,
        };
      } catch (err) {
        const errs = getGenericErrors(err);
        showError(errs, 'Error', 'Error');
        return {
          isError: true,
        };
      } finally {
        setIsLoading(false);
      }
    },
    [showError]
  );

  return {
    isLoading,
    onArrangeShip,
  };
};

export const useCheckArrangedShips = (props: { paidOrders?: CartShippingGroup[] } = {}) => {
  const { paidOrders } = props;

  const result = useSWR<CartShippingGroup[]>(
    ['arranged-ship', paidOrders],
    async () => {
      if (paidOrders && paidOrders.length > 0) {
        const data = await requestWithErrorLogging<{ data: CartShippingGroup[] }>({
          url: '/urus-order-service/paid-orders/arranged-ship',
          method: 'post',
          data: {
            paidOrders,
          },
        });
        return data.data.data.map((item) => ({
          ...item,
          fulfillStatus: !item.fulfillmentId
            ? CartFulfillmentStatus.NOT_SHIP
            : !item.shipmentId
            ? CartFulfillmentStatus.DRAFT
            : CartFulfillmentStatus.DONE,
        }));
      }
      return [];
    },
    { revalidateOnFocus: true }
  );

  return {
    ...result,
    arrangedShip: result.data || [],
    isLoading: (!result.data && !result.error) || result.isValidating,
  };
};

export const useCheckAllowCartIdsForFulfill = (props?: { cartIds?: string[] | null; hasLogisticFeature?: boolean }) => {
  const { cartIds, hasLogisticFeature } = props || {};

  const { showError } = useShowError();
  const { toShipCartIds, isLoading: isLoadingToShipOrders, mutate } = useGetToShipOrders({ hasLogisticFeature });

  const [isLoading, setIsLoading] = useState<boolean>(!!hasLogisticFeature);
  const [allowCartIds, setAllowCartIds] = useState<string[] | undefined>();

  const checkNotFulfilledCartIds = async (ids?: string[]) => {
    if (!ids || ids.length === 0) {
      return [];
    }

    try {
      const result = await requestWithErrorLogging({
        url: '/urus-order-service/fulfillments/not-fulfilled',
        method: 'post',
        data: {
          cartIds: ids,
        },
      });
      return (result.data.cartIds as string[]) || [];
    } catch (err) {
      const errs = getGenericErrors(err);
      showError(errs, 'Error', 'Error');
      return [];
    }
  };

  useEffect(() => {
    if (typeof hasLogisticFeature === undefined || typeof cartIds === 'undefined' || isLoadingToShipOrders) {
      return;
    }

    if (!hasLogisticFeature) {
      setIsLoading(false);
      return;
    }

    checkNotFulfilledCartIds(cartIds || [])
      .then((notFulfilledCartIds) => {
        setAllowCartIds(notFulfilledCartIds);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [JSON.stringify(cartIds), hasLogisticFeature, isLoadingToShipOrders]);

  return {
    isLoading: !hasLogisticFeature ? false : isLoading,
    allowCartIds: allowCartIds?.filter((id) => !toShipCartIds.includes(id)),
    mutate,
  };
};
const NUMBER_0 = 0;
const NUMBER_1 = 1;
const NUMBER_30 = 30;
const NUMBER_50 = 50;
const NUMBER_100 = 100;
const NUMBER_150 = 150;
const NUMBER_180 = 180;
const NUMBER_200 = 200;
const NUMBER_280 = 280;
const NUMBER_300 = 300;

export const getDimensionByCountryAndShippingUnit = (country: string, shippingUnit: string) => {
  switch (country) {
    case 'th':
      switch (shippingUnit) {
        case ShipmentName.J_AND_T:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_100,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_100,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_100,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_50,
            maximumDimension: NUMBER_180,
          };
        case ShipmentName.FLASH_EXPRESS:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_150,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_150,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_150,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_50,
            maximumDimension: NUMBER_280,
          };
        case ShipmentName.KERRY_EXPRESS_TH: {
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_200,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_200,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_200,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_30,
            maximumDimension: NUMBER_280,
          };
        }
      }
      break;
    case 'sg':
      switch (shippingUnit) {
        case ShipmentName.J_AND_T_SG:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_150,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_150,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_150,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_30,
            maximumDimension: NUMBER_300,
          };
        case ShipmentName.NINJA_VAN:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_150,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_150,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_150,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_30,
            maximumDimension: NUMBER_280,
          };
      }
      break;
    case 'my':
      switch (shippingUnit) {
        case ShipmentName.J_AND_T_MY:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_150,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_150,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_150,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_100,
            maximumDimension: NUMBER_300,
          };
      }
      break;

    case 'ph':
      switch (shippingUnit) {
        case ShipmentName.FLASH_EXPRESS_PH:
          return {
            minLength: NUMBER_1,
            maxLength: NUMBER_150,
            minWidth: NUMBER_1,
            maxWidth: NUMBER_150,
            minHeight: NUMBER_1,
            maxHeight: NUMBER_150,
            minWeight: NUMBER_0,
            maxWeight: NUMBER_50,
            maximumDimension: NUMBER_280,
          };
      }
      break;
  }
};

export const useArchive = () => {
  const { showError } = useShowError();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleArchive = useCallback(
    async (id: string, fullFillStatus: string, dataParamsObject: any = {}) => {
      try {
        setIsLoading(true);
        const url =
          fullFillStatus === FulfillStatus.TO_SHIP
            ? `/urus-order-service/paid-orders/${id}/archive`
            : `/urus-shipment-service/shipments/${id}/archive`;

        const mutatePath =
          fullFillStatus === FulfillStatus.TO_SHIP
            ? `/urus-order-service/paid-orders?limit=999&sort=updated_at desc&isArrangedShip=true&isFulfilled=false`
            : `/urus-shipment-service/shipments${requestParamsFromObject(dataParamsObject)}`;

        await requestWithErrorLogging({
          url: url,
          method: 'post',
          mutateConfig: {
            mutatePath: [
              '/urus-shipment-service/shipments/archived', //Reload tab number archive
              mutatePath, // Reload table shipments
              URL_API_FULFILL_STATUS_MAPPING[fullFillStatus], //Reload tab number shipment
            ],
          },
        });
        return {
          isError: false,
        };
      } catch (err) {
        const errs = getGenericErrors(err);
        showError(errs, 'Error', 'Error');
        return {
          isError: true,
        };
      } finally {
        setIsLoading(false);
      }
    },
    [showError]
  );

  return {
    isLoading,
    handleArchive,
  };
};

export const useListArchives = (paramsObject?: string) => {
  const result = useSWR<{ data: Archive[]; total: number }>(
    paramsObject
      ? `/urus-shipment-service/shipments/archived${paramsObject}`
      : '/urus-shipment-service/shipments/archived'
  );

  return {
    ...result,
    data: result.data,
    isLoading: !result.data && !result.error,
    total: result.data?.total,
  };
};

const URL_API_FULFILL_STATUS_MAPPING: { [key: string]: string } = {
  [FulfillStatus.CANCELLED]: 'urus-shipment-service/shipments?status=cancelled',
  [FulfillStatus.FAILED_DELIVERY]: 'urus-shipment-service/shipments?status=failed_delivery',
  [FulfillStatus.DELIVERED]: 'urus-shipment-service/shipments?status=delivered',
};

export const useFulfillments = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { translate } = useContext(LanguageContext);
  const handleFulfillments = useCallback(
    async (
      paidOrderIds: number[],
      {
        skipNotification,
      }: {
        skipNotification?: boolean;
      } = {}
    ) => {
      try {
        setIsLoading(true);
        const result = await requestWithErrorLogging<FulfillOrder>({
          url: '/urus-order-service/fulfillments',
          method: 'post',
          data: {
            paidOrderIds,
          },
        });
        setIsLoading(false);
        return result.data.fulfillmentId;
      } catch (err) {
        if (!skipNotification) {
          const genericErrors = getGenericErrors(err);
          showError(genericErrors, translate('fulfill_order_ship_failed'), translate('error_try_again_later'));
        }
        setIsLoading(false);
      }
    },
    [showError, translate]
  );

  return {
    handleFulfillments,
    isLoading,
  };
};

export const useRemoveFulfillmentOrder = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { translate } = useContext(LanguageContext);
  const handleRemove = useCallback(
    async (
      data: {
        cartId: string;
        shippingGroupId?: string;
        fulfillId: string;
      },
      {
        skipNotification,
      }: {
        skipNotification?: boolean;
      } = {}
    ) => {
      const { fulfillId, shippingGroupId, cartId } = data;
      try {
        setIsLoading(true);
        await requestWithErrorLogging<FulfillOrder>({
          url: `/urus-order-service/fulfillments/${fulfillId}/orders`,
          method: 'delete',
          data: {
            shippingGroupId,
            cartId,
          },
        });
        setIsLoading(false);
      } catch (err) {
        if (!skipNotification) {
          const genericErrors = getGenericErrors(err);
          showError(genericErrors, translate('fulfill_remove_failed'), translate('error_try_again_later'));
        }
        setIsLoading(false);
      }
    },
    [showError, translate]
  );

  return {
    handleRemove,
    isLoading,
  };
};

export const useLimitSelectedItem = (props?: { limit: number; message?: string }) => {
  const { translate } = useContext(LanguageContext);
  const { limit, message } = props || {};
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onUpdate = useCallback(
    (updateIds: string[]) => {
      if (!limit || updateIds.length <= limit) {
        setSelectedIds(updateIds);
        return;
      }

      antdMessage.warning(message || translate('alert_limit_selected_items', { '[LIMIT]': limit }));
    },
    [limit, message, translate]
  );

  return {
    selectedIds,
    setSelectedIds: onUpdate,
  };
};
export const useContinueFulfillment = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { translate } = useContext(LanguageContext);
  const handleContinue = useCallback(
    async (data: { paidOrderIds: string[]; fulfillmentId: string }) => {
      const { paidOrderIds, fulfillmentId } = data;
      try {
        setIsLoading(true);
        const result = await requestWithErrorLogging<{ fulfillmentId: string }>({
          url: `/urus-order-service/fulfillments/${fulfillmentId}`,
          method: 'post',
          data: {
            paidOrderIds,
          },
        });
        setIsLoading(false);
        return result.data.fulfillmentId;
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('fulfill_order_ship_failed'), translate('error_try_again_later'));
        setIsLoading(false);
      }
    },
    [showError, translate]
  );

  return {
    handleContinue,
    isLoading,
  };
};
