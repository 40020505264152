import React, { FunctionComponent, useContext } from 'react';
import { Button, Modal } from 'antd';

import { LanguageContext } from 'context/language';

export interface SwitchUserModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SwitchUserModal: FunctionComponent<SwitchUserModalProps> = ({
  open,
  onClose,
  onConfirm,
}: SwitchUserModalProps) => {
  const { translate } = useContext(LanguageContext);

  return (
    <Modal
      title={translate('switch_user')}
      open={open}
      onCancel={onClose}
      cancelText={translate('cancel')}
      footer={
        <Button key="ok" type="primary" onClick={onConfirm}>
          OK
        </Button>
      }
    >
      <div>{translate('switch_user_description')}</div>
    </Modal>
  );
};

export default SwitchUserModal;
