import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { News } from 'models/News';
import PatchNotePost from 'shared-components/PatchNotePost/PatchNotePost';
import { LanguageContext } from 'context/language';
import { isEmpty } from 'lodash';
import compareAsc from 'date-fns/compareAsc';
import { convertDateToString, convertStringtoDate } from 'utils/date';
import { APP_REGION } from 'utils/constants';

type DrawerBodyProps = {
  data?: News[];
  search?: string;
  loadmore: JSX.Element | null;
  decreaseNewsCount: () => void;
  isUseTaglish: boolean;
};

const DrawerBody: React.FunctionComponent<DrawerBodyProps> = ({
  data,
  search = '',
  loadmore,
  decreaseNewsCount,
  isUseTaglish,
}: DrawerBodyProps) => {
  const { currentLang } = useContext(LanguageContext);
  const langKey = useMemo(() => (isUseTaglish ? 'tgl' : currentLang), [currentLang, isUseTaglish]);
  const newPostIdKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-newPostIds`;
  }, [langKey]);

  const readPostIdKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-readPostIds`;
  }, [langKey]);

  const lastSeenDateKey = useMemo(() => {
    return `${APP_REGION}-${langKey}-lastSeenDate`;
  }, [langKey]);

  const [newPostIds, setNewPostIds] = useState<{ [id: string]: boolean | undefined }>(
    () => JSON.parse(localStorage.getItem(newPostIdKey) as string) || {}
  );

  const handleClickNewsPost = useCallback(
    async (postId: string, publishDate: Date) => {
      if (!newPostIds[postId]) {
        setNewPostIds((prevNewPostIds) => {
          return { ...prevNewPostIds, [postId]: true };
        });
        decreaseNewsCount();
        const lastSeenDateStr = localStorage.getItem(lastSeenDateKey) || '';
        if (!isEmpty(localStorage.getItem(lastSeenDateKey))) {
          const lastSeenDate = convertStringtoDate(lastSeenDateStr, 'yyyy-MM-dd');
          if (compareAsc(publishDate, lastSeenDate) === 1) {
            localStorage.setItem(lastSeenDateKey, convertDateToString(publishDate, 'yyyy-MM-dd'));
          }
        } else {
          localStorage.setItem(lastSeenDateKey, convertDateToString(publishDate, 'yyyy-MM-dd'));
        }
      }
    },
    [decreaseNewsCount, newPostIds, lastSeenDateKey]
  );

  useEffect(() => {
    localStorage.setItem(newPostIdKey, JSON.stringify(newPostIds));
    localStorage.setItem(readPostIdKey, JSON.stringify(newPostIds));
  }, [newPostIds, newPostIdKey, readPostIdKey]);
  const renderPostList = () =>
    data?.map((o) => (
      <PatchNotePost
        key={o.id}
        body={o.content.rendered}
        title={o.title.rendered}
        publishDate={o.acf.releaseDate}
        hyperlink={o.acf.hyperlink}
        handleClickNewsPost={handleClickNewsPost}
        id={o.id}
        isNewPost={!newPostIds[o.id]}
        featured_media={o.featured_media}
        search={search}
      />
    ));

  return (
    <div className="flex flex-col" data-testid="patchnote-drawer-body">
      {renderPostList()}
      {loadmore}
    </div>
  );
};

export default DrawerBody;
