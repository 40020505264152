export enum ProductImageSize {
  MEDIUM = 'medium',
  SMALL = 'small',
  ORIGINAL = 'original',
}

export const getOptimizeProductImgUrl = (rawUrl: string, size: ProductImageSize) => {
  const isSupportedMediumSize = rawUrl.includes('medium');
  const isSupportedSmallSize = rawUrl.includes('small');
  const mediumSizeImgUrl = rawUrl.replace('/upmesh-product-images', '/upmesh-product-images/medium');
  const smallSizeImgUrl = rawUrl.replace('/upmesh-product-images', '/upmesh-product-images/small');

  switch (size) {
    case ProductImageSize.MEDIUM:
      return isSupportedMediumSize ? mediumSizeImgUrl : rawUrl;

    case ProductImageSize.SMALL:
      if (isSupportedSmallSize) return smallSizeImgUrl;
      else if (isSupportedMediumSize) return mediumSizeImgUrl;
      return rawUrl;

    default:
      return rawUrl;
  }
};

export const proxyGetImage = ({ type, url }: { type: string; url: string }) =>
  `${process.env.REACT_APP_API_BASE_URL}/product-service/api/products/file?type=${type}&url=${url}`;
