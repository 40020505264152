import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';
import T from 'shared-components/Translator';

type ErrorModalTitleProps = {
  title?: string;
  titleTextTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case';
};

const ErrorModalContent: FunctionComponent<ErrorModalTitleProps> = ({
  title,
  titleTextTransform = 'uppercase',
}: ErrorModalTitleProps) => {
  return (
    <Typography.Text className={`${titleTextTransform}`} style={{ color: '#FF4D4F' }}>
      {title || <T value="warning" />}
    </Typography.Text>
  );
};

export default ErrorModalContent;
