import React, { FunctionComponent, useContext, useState } from 'react';
import { Button, Input, Modal, notification, Space } from 'antd';

import { LanguageContext } from 'context/language';
import BlockSpinner from 'shared-components/BlockSpinner';
import { requestWithErrorLogging } from 'utils/request';
import { useShowError } from 'utils/hooks';
import { showErrorAtFormFields } from 'utils/form';

export interface ResendVerificationModalProps {
  open: boolean;
  onClose: () => void;
}

const ResendVerificationModal: FunctionComponent<ResendVerificationModalProps> = ({
  open,
  onClose,
}: ResendVerificationModalProps) => {
  const { translate } = useContext(LanguageContext);
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const { showError } = useShowError();

  const handleSubmit = async () => {
    setErr('');
    function validateEmail(raw: string) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(raw);
    }

    if (!email || !validateEmail(email)) {
      return setErr(translate('email_error'));
    }

    try {
      setLoading(true);
      await requestWithErrorLogging({
        url: '/user-service/api/users/registration/verification-email/resend',
        method: 'post',
        data: { email },
      });
      onClose();
      notification.success({
        message: translate('resend_email_success'),
      });
    } catch (err) {
      const genericErrors = showErrorAtFormFields(undefined, err);
      showError(genericErrors, translate('resend_email_fail'), translate('error_occurred_try_again'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={translate('resend_verification_email')}
      visible={open}
      onCancel={onClose}
      cancelText={translate('cancel')}
      footer={[
        isLoading ? (
          <BlockSpinner height={40} />
        ) : (
          <Button key="resend" type="primary" onClick={handleSubmit}>
            Resend
          </Button>
        ),
      ]}
    >
      <Space className="flex justify-center flex-col">
        <Input
          disabled={isLoading}
          value={email}
          placeholder={translate('email')}
          onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
        />
        {!!err && <div style={{ color: 'red' }}>{err}</div>}
      </Space>
    </Modal>
  );
};

export default ResendVerificationModal;
