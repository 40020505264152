import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { List, message, Modal, Space } from 'antd';
import Search from 'antd/lib/input/Search';
import { OptionTypeBase } from 'react-select';
import useSWR from 'swr';

import UserSpace from 'models/UserSpace';
import { userSpaceUrl } from './UserSpaceSelect';
import { LanguageContext } from 'context/language';

export interface UserSpaceModalProps {
  open: boolean;
  onChange: (opt: OptionTypeBase) => void;
}

const UserSpaceModal: FunctionComponent<UserSpaceModalProps> = ({ open, onChange }: UserSpaceModalProps) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<UserSpace[]>();
  const [mySpace, setMySpace] = useState<UserSpace>();
  const { data: swrData } = useSWR(`${userSpaceUrl}?businessName=${search}`);
  const { translate } = useContext(LanguageContext);

  const handleChange = (value: UserSpace) => () => {
    onChange({
      value: value?.ownerKey,
      label: value?.name,
    });
  };

  useEffect(() => {
    if (swrData?.userspace) {
      const data: UserSpace[] = swrData.userspace;
      const isBusinessActived = swrData.myspace?.isBusinessActived;
      const mySpace = swrData.myspace?.mySpaceId && {
        name: translate('my_space'),
        ownerKey: swrData.myspace.mySpaceId,
      };
      mySpace && isBusinessActived && setMySpace(mySpace);

      const listOptions = mySpace && isBusinessActived ? [mySpace, ...data] : data;
      setOptions(listOptions);
    }
  }, [swrData, translate]);

  return (
    <Modal
      title={translate('select_space')}
      visible={open}
      onCancel={() => null}
      footer={null}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.95)' }}
    >
      <Space className="mr-2 mb-4">
        <Search
          placeholder={translate('search_3_dots')}
          onSearch={(value: string) => {
            if (value.length > 0 && value.length < 2) {
              message.error(translate('enter_at_least_2_chars'));
              return;
            }

            setSearch(value);
          }}
          allowClear
        />
      </Space>
      <List
        dataSource={options}
        renderItem={(item: UserSpace) => {
          const isMySpace = item.ownerKey === mySpace?.ownerKey;

          return (
            <List.Item
              style={
                isMySpace
                  ? {
                      borderBottom: '1px dashed #ccc',
                    }
                  : {}
              }
            >
              <a onClick={handleChange(item)}>{item.name}</a>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default UserSpaceModal;
