import axios from 'axios';

export const getStatusDownloadInvoices = async (type: string, processingId: string) => {
  const res = await axios.get<{
    id: string;
    exportStatus: number;
    fileKey?: string;
    fileUrl?: string;
    isDeleted?: boolean;
    serviceName?: string;
  }>(`/cart-service/api/${type}/invoices/export/${processingId}`);

  return res.data;
};

export const getStatusExportingInvoices = async (bucket: string, key: string) => {
  const res = await axios.get<{
    url?: string;
  }>(`/pdf-printer-service/api/orders/invoice/download?bucket=${bucket}&key=${key}`);

  return res.data;
};

export const cancelDownloadInvoice = async (type: string, processingId: string) => {
  return await axios.put(`/cart-service/api/${type}/invoices/cancellation/${processingId}`);
};

export const generatePrintInvoice = async ({
  dataForPrintInvoice,
}: {
  dataForPrintInvoice: { orderId: string; shipmentId?: string; childrenIds?: string[] }[];
}) => {
  const res = await axios.post(
    `/cart-service/api/orders/generate-print-invoice`,
    {
      invoiceOrders: dataForPrintInvoice,
      timezone: new Date().getTimezoneOffset() / -60,
    },
    {
      responseType: 'blob',
    }
  );
  return res;
};

export const requestPrintInvoices = async ({
  dataForPrintInvoice,
}: {
  dataForPrintInvoice: { orderId: string; shipmentId?: string; childrenIds?: string[] }[];
}) => {
  const res = await axios.post(`/cart-service/api/orders/invoices/print`, {
    invoiceOrders: dataForPrintInvoice,
    timezone: new Date().getTimezoneOffset() / -60,
  });
  return res.data;
};
