export interface ProductItem {
  productName?: string; // FE only
  productCode?: string;
  productQuantity?: number;
  description?: string;
}
export enum ParcelDeliveryStatus {
  Cancelled = -100,
  Draft = 0,
  RequestSentToCourier = 100,

  Picking = 200,
  PickedUp = 201,

  OnDelivery = 300,
  Delivered = 301,
  FailedDelivery = 400,
}

export interface ShipmentDetailModel {
  providerTrackingId: string;
  deliveryStatus: ParcelDeliveryStatus;
  courierId: string;
  codAmount: number;
  pickupPersonName: string;
  pickupPhoneNumber: string;
  pickupAddress: string;
  pickupProvince: string;
  pickupDistrict: string;
  pickupWard: string;
  deliveryRecipient: string;
  deliveryPhoneNumber: string;
  deliveryAddress: string;
  deliveryProvince: string;
  deliveryDistrict: string;
  deliveryWard: string;
  totalWeight: number;
  deliveryFee: number;
  deliveryFeePaidBy: number;
  insuranceFee: number;
  insuranceFeePaidBy: number;
  isDeleted: boolean;
  orderId: string;
  cartId: string;
  items: ProductItem[];
  parcelStatusHistories: {
    parcelId: string;
    created: string;
    deliveryStatus: ParcelDeliveryStatus;
  }[]; // Define the type for parcelStatusHistories if needed
}

export interface UpdateAddressShipmentRequest {
  shipmentId?: string;
  address1?: string;
  address2?: string;
  shippingCountry?: string;
  postalCode?: string;
  recipientName?: string;
  recipientEmail?: string;
  recipientPhoneNumber?: string;
  deliveryInstruction?: string;
  unitNumber?: string;
  subLevelLocationIds?: string[];
}
