/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import * as Sentry from '@sentry/react';
import { Button, Result } from 'antd';
import { ArrowLeftOutlined, HomeOutlined, ReloadOutlined } from '@ant-design/icons';

interface Props {}

const ErrorBoundary: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <Result
          status="error"
          title="An Error Occurred"
          subTitle={
            process.env.NODE_ENV === 'development' ? (
              <code>{errorData?.error?.message}</code>
            ) : (
              'Something went wrong. Please try again.'
            )
          }
          extra={[
            <Button type="default" key="back" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
              Back
            </Button>,
            <Button type="default" key="home" icon={<HomeOutlined />} onClick={() => (window.location.href = '/')}>
              Home
            </Button>,
            <Button type="default" key="retry" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
              Retry
            </Button>,
          ]}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
