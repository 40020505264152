/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Result } from 'antd';
import { ArrowLeftOutlined, HomeOutlined, ReloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from 'context/language';

interface Props {}

const RouteErrorBoundary: React.FC<Props> = (props) => {
  const { children } = props;
  const { push, back } = useHistory();
  const { translate } = useContext(LanguageContext);
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <Result
          status="error"
          title={translate('error_occurred')}
          subTitle={
            process.env.NODE_ENV === 'development' ? (
              <code>{errorData?.error?.message}</code>
            ) : (
              translate('common_error_msg')
            )
          }
          extra={[
            <Button type="default" key="back" icon={<ArrowLeftOutlined />} onClick={() => back()}>
              {translate('back')}
            </Button>,
            <Button type="default" key="home" icon={<HomeOutlined />} onClick={() => push('/')}>
              {translate('home')}
            </Button>,
            <Button type="default" key="retry" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
              {translate('retry')}
            </Button>,
          ]}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default RouteErrorBoundary;
