import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import { Button, Dropdown, Menu, Popover, Space } from 'antd';
import { LanguageContext } from 'context/language';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { generateLanguageByCode } from 'utils/functions';

import { ReactComponent as LanguageIcon } from 'assets/language.svg';

type LanguagesSelectProps = {
  type?: 'dropdown' | 'popover';
};

const LanguagesSelect = ({ type = 'popover' }: LanguagesSelectProps): ReactElement => {
  const { listLanguages, currentLang, changeLanguage } = useContext(LanguageContext);

  const onClick = useCallback(
    (e) => {
      changeLanguage(e.key);
    },
    [changeLanguage]
  );

  const menu = useMemo(() => {
    if (type === 'popover')
      return (
        <div>
          {listLanguages.map((o) => {
            return (
              <div
                key={o.value}
                className="cursor-pointer w-full px-4 py-2 hover:bg-gray-200"
                onClick={() => onClick({ key: o.value })}
              >
                {generateLanguageByCode(o.label.toLocaleLowerCase())}
              </div>
            );
          })}
        </div>
      );
    return (
      <Menu onClick={onClick}>
        {listLanguages.map((o) => {
          return <Menu.Item key={o.value}>{generateLanguageByCode(o.label.toLocaleLowerCase())}</Menu.Item>;
        })}
      </Menu>
    );
  }, [listLanguages, onClick, type]);

  if (type === 'popover')
    return (
      <Popover
        content={menu}
        trigger="click"
        placement="leftTop"
        showArrow={false}
        overlayStyle={{ padding: 0 }}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        <Space className="cursor-pointer w-full px-4 py-2 hover:bg-gray-200 justify-between">
          <div className="flex gap-2 items-center">
            <LanguageIcon />
            <span>{generateLanguageByCode(currentLang?.toLowerCase())}</span>
          </div>
          <RightOutlined style={{ fontSize: '10px' }} />
        </Space>
      </Popover>
    );

  return (
    <Dropdown overlay={menu}>
      <Button className="flex items-center gap-2">
        <LanguageIcon />
        <span>{generateLanguageByCode(currentLang?.toLowerCase())}</span>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguagesSelect;
