/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DeliveryMethod,
  DeliveryServiceV2,
  ItemBaseType,
  ShippingCalculation,
  ShippingFeeItem,
} from 'models/DeliveryService';
import IdNamePair from 'models/IdNamePair';
import { formatCurrency, getCurrencyUnit } from './functions';
import { AppRegion } from 'hooks/app';
import React from 'react';
import T from 'shared-components/Translator';
import { isVN } from './constants';
import { ShippingGroupV2 } from 'models/ShippingGroup';

export function deliverySettingSurchargeDisplay(
  delivery: DeliveryServiceV2 | undefined,
  options: {
    formatEach: (data: { currency: string; base: string; each: string }) => string;
  }
) {
  const { formatEach } = options;
  const currency = getCurrencyUnit();
  switch (delivery?.shippingCalculationType) {
    case ShippingCalculation.PER_ORDER_FLAT_RATE: {
      return `${currency} ${(delivery.flatRateSurcharge?.surcharge || 0).toFixed(2)}`;
    }
    case ShippingCalculation.WEIGHT_BASED_SHIPPING:
    case ShippingCalculation.ITEM_BASED_SHIPPING: {
      if (delivery.adjustableValueBasedSurcharge) {
        return formatEach({
          base: delivery.adjustableValueBasedSurcharge.surcharge.toFixed(2),
          currency,
          each: delivery.adjustableValueBasedSurcharge.additionalSurcharge.toFixed(2),
        });
      }

      if (delivery.adjustableTierBasedSurcharge) {
        let max = 0;
        let min = delivery.adjustableTierBasedSurcharge.surchargeItems[0].surcharge || 0;

        delivery.adjustableTierBasedSurcharge.surchargeItems.forEach((s) => {
          max = Math.max(max, s.surcharge || 0);
          min = Math.min(min, s.surcharge || 0);
        });

        return `${currency} ${min.toFixed(2)} - ${currency} ${max.toFixed(2)}`;
      }

      break;
    }
  }
  return `${currency} 0`;
}

function getTierItems(options: {
  values: any;
  keys: {
    tierName: string;
  };
}) {
  const { values, keys } = options;
  const items: ShippingFeeItem[] = [
    {
      surcharge: values[`${keys.tierName}.surcharge`] || 0,
      lowerBound: values[`${keys.tierName}.firstFrom`] || 0,
      upperBound: values[`${keys.tierName}.firstTo`] || 0,
    },
  ];

  const tiers: { from: number; to: number; surcharge?: number }[] = values[keys.tierName];

  tiers.forEach((w, index) => {
    items.push({
      surcharge: w.surcharge || 0,
      lowerBound: w.from || 0,
      upperBound: index === tiers.length - 1 ? null : w.to || 0,
    });
  });

  return items;
}

export function formatDeliveryFormData(values: { [key: string]: any }) {
  let data: DeliveryMethod;

  data = {
    option: values.option,
    shippingCalculationType: values.shippingCalculationType,
    additionalInformation: values.additionalInformation,
    active: !values?.id ? true : values.active,
    ...(!values?.id ? {} : { id: values.id }),
  };

  switch (values.shippingCalculationType) {
    case ShippingCalculation.PER_ORDER_FLAT_RATE: {
      data = {
        ...data,
        adjustableTierBasedSurcharge: {
          surchargeItems:
            values?.flatRateTiers?.map((o: any) => ({
              surcharge: o.fee || 0,
              lowerBound: o.from || 0,
              upperBound: o.to || null,
            })) || [],
        },
      };
      break;
    }
    case ShippingCalculation.WEIGHT_BASED_SHIPPING: {
      data = {
        ...data,
        adjustableValueBasedSurcharge: {
          value: values?.weightBasedSurcharge?.value || 0,
          surcharge: values?.weightBasedSurcharge?.surcharge || 0,
          additionalSurcharge: values?.weightBasedSurcharge?.additionalSurcharge || 0,
          additionalValue: values?.weightBasedSurcharge?.additionalValue || 0,
        },
      };
      break;
    }
    case ShippingCalculation.ITEM_BASED_SHIPPING: {
      if (values.itemBasedType === ItemBaseType.BASE) {
        data = {
          ...data,
          adjustableValueBasedSurcharge: {
            value: values?.itemBasedSurcharge?.item || 1,
            surcharge: values?.itemBasedSurcharge?.surcharge || 0,
            additionalSurcharge: values?.itemBasedSurcharge?.surchargeAdditonal || 0,
          },
        };
      } else {
        data = {
          ...data,
          adjustableTierBasedSurcharge: {
            surchargeItems: getTierItems({
              values,
              keys: {
                tierName: 'itemTier',
              },
            }),
          },
        };
      }
      break;
    }
  }

  // Global shipment or not
  data.globalShipment = values.location?.[1]?.isChecked;
  data.allRegion = false;

  if (values.location?.[0].isChecked === true) {
    data.allRegion = true;
  } else {
    data.shippingRegions = (values.location as IdNamePair[])
      ?.filter((l) => l.id !== 'all_regions' && l.id !== 'global' && l.isChecked)
      .map((l) => Number.parseInt(l.id));
  }

  return data;
}

export function calcNewRegionOptions(currentOptions: IdNamePair[], newOption: IdNamePair) {
  const newList: IdNamePair[] = [];

  currentOptions.forEach((cur) => {
    const c = { ...cur };

    if (newOption.id === 'all_regions') {
      if (c.id === 'all' && !newOption.isChecked) {
        c.isChecked = false;
      } else if (c.id !== 'global' && c.id !== 'all') {
        c.isChecked = newOption.isChecked;
      }
    } else if (newOption.id === 'all') {
      c.isChecked = newOption.isChecked;
    } else if (c.id === newOption.id) {
      c.isChecked = newOption.isChecked;
    } else if (c.id === 'all') {
      c.isChecked = false;
    }
    newList.push(c);
  });

  const listWithOutDefaultOptions = newList.filter((l) => {
    return !['all', 'all_regions', 'global'].includes(l.id);
  });

  // SG does not have provinces
  if (listWithOutDefaultOptions.length > 0) {
    newList[0].isChecked = listWithOutDefaultOptions.filter((l) => !l.isChecked).length <= 0;
  }

  return newList;
}

export const generateRegionName = (regionCode: AppRegion) => {
  switch (regionCode) {
    case AppRegion.SG:
      return 'Singapore';
    case AppRegion.MY:
      return 'Malaysia';
    case AppRegion.TH:
      return 'Thailand';
    case AppRegion.PH:
      return 'Philippines';
    case AppRegion.VN:
      return 'Vietnam';

    default:
      return '';
  }
};

export const generateFieldName = (
  groupKeyName: number | undefined | (string | number)[],
  fieldName: (string | number)[]
) => {
  if (groupKeyName !== undefined) {
    if (typeof groupKeyName === 'number') {
      return [groupKeyName, ...fieldName];
    }

    return [...groupKeyName, ...fieldName];
  }

  return fieldName;
};

export const generateFeeRateDetail = (delivery: DeliveryServiceV2, translate: any) => {
  switch (delivery.shippingCalculationType) {
    case ShippingCalculation.PER_ORDER_FLAT_RATE:
      if (
        delivery.adjustableTierBasedSurcharge?.surchargeItems?.length === 1 &&
        delivery.adjustableTierBasedSurcharge.surchargeItems[0].lowerBound === 0
      ) {
        return [
          translate('fee_for_all_carts', {
            '[FEE]': formatCurrency(delivery.adjustableTierBasedSurcharge.surchargeItems[0].surcharge || 0),
          }),
        ];
      }
      return (
        delivery.adjustableTierBasedSurcharge?.surchargeItems?.map((o, idx) => {
          if (idx === (delivery.adjustableTierBasedSurcharge?.surchargeItems.length || 0) - 1) {
            return translate('last_flat_rate_fee_detail', {
              '[FEE]': formatCurrency(o.surcharge || 0),
              '[FROM]': formatCurrency(o.lowerBound || 0),
            });
          }
          return translate('flat_rate_fee_detail', {
            '[FEE]': formatCurrency(o.surcharge || 0),
            '[FROM]': formatCurrency(o.lowerBound || 0),
            '[TO]': formatCurrency(o.upperBound || 0),
          });
        }) || []
      );
    case ShippingCalculation.WEIGHT_BASED_SHIPPING:
      return [
        translate('first_based_weight_fee_detail', {
          '[FIRST_FEE]': formatCurrency(delivery.adjustableValueBasedSurcharge?.surcharge || 0),
          '[FIRST_WEIGHT]': delivery.adjustableValueBasedSurcharge?.value || 0,
        }),
        translate('additional_based_weight_fee_detail', {
          '[ADDITIONAL_FEE]': formatCurrency(delivery.adjustableValueBasedSurcharge?.additionalSurcharge || 0),
          '[ADDITIONAL_WEIGHT]': delivery.adjustableValueBasedSurcharge?.additionalValue || 0,
        }),
      ];
    case ShippingCalculation.ITEM_BASED_SHIPPING:
      return [];

    default:
      return [];
  }
};

export const generateDeliveryType = (type: ShippingCalculation) => {
  switch (type) {
    case ShippingCalculation.PER_ORDER_FLAT_RATE:
      return 'flat_rate';
    case ShippingCalculation.WEIGHT_BASED_SHIPPING:
      return 'first_and_additional_weight';

    default:
      return '';
  }
};

export const generateDeliveryTagColor = (tagType: 'type' | 'region') => {
  switch (tagType) {
    case 'type':
      return {
        backgroundColor: '#F9F0FF',
        borderColor: '#D3ADF7',
        color: '#722ED1',
      };
    case 'region':
      return {
        backgroundColor: '#FFF7E6',
        borderColor: '#FFD591',
        color: '#FA8C16',
      };

    default:
      return {};
  }
};

export const generateSelectedRegion = (
  selectRegions: IdNamePair[],
  region: AppRegion.SG | AppRegion.MY | AppRegion.PH | AppRegion.TH | AppRegion.VN
) => {
  const selected = selectRegions.filter((s) => s.isChecked === true);
  const allRegion = selectRegions.find((s) => s.id === 'all_regions');
  const selectedTotal = selected.length;
  const isAll = selectedTotal === selectRegions.length;

  let content: React.ReactNode = null;
  if (isAll) {
    if (isVN) {
      content = <T value={generateRegionName(region)} />;
    } else {
      content = (
        <>
          <T value={generateRegionName(region)} />, <T value="global" />
        </>
      );
    }
  } else if (!isAll && allRegion?.isChecked) {
    content = <T value={generateRegionName(region)} />;
  } else {
    content = selected.map((s, i) => {
      if (i > 0) {
        return `, ${s.name}`;
      }
      return s.name;
    });
  }

  return { content, selectedTotal };
};

export const generateFeeRateDetailInForm = (delivery: any) => {
  switch (delivery.shippingCalculationType) {
    case ShippingCalculation.PER_ORDER_FLAT_RATE:
      if (delivery?.flatRateTiers.some((o: any) => o.fee === undefined)) return [];
      if (delivery?.flatRateTiers?.length === 1 && delivery.flatRateTiers[0].from === 0) {
        return [
          <T
            key="fee_for_all_carts"
            value="fee_for_all_carts"
            params={{
              '[FEE]': formatCurrency(delivery.flatRateTiers[0].fee || 0),
            }}
          />,
        ];
      }

      return (
        delivery?.flatRateTiers?.map((o: any, idx: number) => {
          if (idx === (delivery.flatRateTiers.length || 0) - 1) {
            return (
              <T
                key={idx}
                value="last_flat_rate_fee_detail"
                params={{
                  '[FEE]': formatCurrency(o.fee || 0),
                  '[FROM]': formatCurrency(o.from || 0),
                }}
              />
            );
          }
          return (
            <T
              key={idx}
              value="flat_rate_fee_detail"
              params={{
                '[FEE]': formatCurrency(o.fee || 0),
                '[FROM]': formatCurrency(o.from || 0),
                '[TO]': formatCurrency(o.to || 0),
              }}
            />
          );
        }) || []
      );
    case ShippingCalculation.WEIGHT_BASED_SHIPPING:
      if (
        delivery?.weightBasedSurcharge?.surcharge === undefined ||
        delivery?.weightBasedSurcharge?.value === undefined
      )
        return [];

      return [
        <T
          key="first_based_weight_fee_detail"
          value="first_based_weight_fee_detail"
          params={{
            '[FIRST_FEE]': formatCurrency(delivery?.weightBasedSurcharge?.surcharge || 0),
            '[FIRST_WEIGHT]': delivery?.weightBasedSurcharge?.value || 0,
          }}
        />,
        <T
          key="additional_based_weight_fee_detail"
          value="additional_based_weight_fee_detail"
          params={{
            '[ADDITIONAL_FEE]': formatCurrency(delivery?.weightBasedSurcharge?.additionalSurcharge || 0),
            '[ADDITIONAL_WEIGHT]': delivery?.weightBasedSurcharge?.additionalValue || 0,
          }}
        />,
      ];
    case ShippingCalculation.ITEM_BASED_SHIPPING:
      return [];

    default:
      return [];
  }
};

export const generateSubmitDeliveryRegion = (data: DeliveryMethod, region: AppRegion) => {
  let deliveryRegion = [];

  if (data.globalShipment) {
    deliveryRegion.push('global');
  }

  if (data.allRegion) {
    deliveryRegion.push(generateRegionName(region));
  } else {
    deliveryRegion = [...deliveryRegion, ...(data.shippingRegions || [])];
  }

  return deliveryRegion;
};

export const generateShippingGroupDeliveryOptions = (group?: ShippingGroupV2) => {
  if (
    !group ||
    !group?.deliveryOption?.id ||
    !!group?.deliveryOptions?.find((o) => {
      return o.id === group?.deliveryOption?.id;
    })
  ) {
    return group?.deliveryOptions || [];
  }

  return [
    {
      ...group.deliveryOption,
    },
    ...(group?.deliveryOptions || []),
  ];
};
