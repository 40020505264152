import { UploadOutlined, StopOutlined } from '@ant-design/icons';
import { Row, Col, Input, Space, Form, message, Typography, Spin, Divider, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { isEmpty } from 'lodash';
import { Store } from 'antd/lib/form/interface';

import { LanguageContext } from 'context/language';
import { showErrorAtFormFields } from 'utils/form';
import { getGenericErrors } from 'utils/error';
import { dataUrlToFile, generateCommonTrackingData, isSubadmin } from 'utils/functions';
import { useSegement, useShowError } from 'utils/hooks';
import { requestWithErrorLogging } from 'utils/request';
import { useProfile } from '../../hooks/profile';
import { APP_REGION, UEN_NUMBER_MAX_LENGTH } from 'utils/constants';

import CustomerAvatar from 'shared-components/CustomerAvatar';
import T from 'shared-components/Translator';
import CancelSaveFooter from 'shared-components/CancelSaveFooter';

import fbIcon from 'assets/Facebook.svg';
import useSWR from 'swr';

const PROFILE_IMAGE_SIZE = 200;

const initialCropValue: ReactCrop.Crop = {
  unit: '%',
  height: 100,
  aspect: 1,
};

export interface ProfileSectionProps {
  onSubmit?: () => void;
  hideSubmit?: boolean;
}

const ProfileSection: FunctionComponent<ProfileSectionProps> = ({
  onSubmit: onAfterSubmit,
  hideSubmit = false,
}: ProfileSectionProps) => {
  const { translate } = useContext(LanguageContext);

  const { analyticsTrack } = useSegement();
  const { showError } = useShowError();

  const mine = useRef<string>('');

  const { data: user, isValidating: isLoadingUser } = useSWR('/user-service/api/users/me');

  const { data: profile, isValidating } = useProfile();

  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<ReactCrop.Crop>(initialCropValue);
  const [imageRefForCrop, setImageRefForCrop] = useState<HTMLImageElement>();
  const [updating, setUpdating] = useState(false);

  const [form] = Form.useForm();

  const isPH = APP_REGION === 'ph';

  const onFileSelect = async (files: File[]) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setPreviewSrc(reader.result as string));
      reader.readAsDataURL(files[0]);
    } else {
      message.error(translate('upload_image_size_rule_5'));
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRefForCrop(image);
    setCrop(initialCropValue);
  };

  const getCroppedImg = (image: HTMLImageElement, crop: ReactCrop.Crop) => {
    const mimeString = image.src.split(',')[0].split(':')[1].split(';')[0];
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = PROFILE_IMAGE_SIZE;
    canvas.height = PROFILE_IMAGE_SIZE;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      (crop.x as number) * scaleX,
      (crop.y as number) * scaleY,
      (crop.width as number) * scaleX,
      (crop.height as number) * scaleY,
      0,
      0,
      PROFILE_IMAGE_SIZE,
      PROFILE_IMAGE_SIZE
    );
    mine.current = mimeString;
    return canvas.toDataURL(mimeString);
  };

  const logo = form.getFieldValue('logo') || profile?.logo;

  const getLogoDefaultContent = logo ? (
    <img src={logo} alt="Business Logo" className="w-full h-full" />
  ) : (
    <div>
      <T value="drag_and_drop_business_logo_or" />
      <hr className="my-2" />
      <span className="cursor-pointer text-primary">
        <T value="search_a_file" />
      </span>
    </div>
  );

  const dropHintContent = (
    <div>
      <UploadOutlined className="text-gray-700" />
      <div className="mt-2 text-primary">
        <T value="upload_file" />
      </div>
    </div>
  );

  const rejectHintContent = (
    <div className="cursor-not-allowed">
      <StopOutlined className="text-3xl text-gray-700" />
      <div className="mt-2 text-red-500">
        <T value="unsupported_file_type" />
      </div>
    </div>
  );

  const uploadBusinessLogo = React.useCallback(
    async (data: File) => {
      try {
        const formData = new FormData();
        formData.append('FileUpload', data);
        return await requestWithErrorLogging({
          url: '/profile-service/api/profile/image',
          method: 'post',
          data: formData,
          mutateConfig: {
            mutatePath: ['/profile-service/api/profile/image'],
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('upload_business_logo_error'),
          translate('upload_business_logo_error_description')
        );
      }
    },
    [showError, translate]
  );

  const getLogoUrl = React.useCallback(async () => {
    if (form.getFieldValue('logo') === profile?.logo) return;
    let extension = '';
    switch (mine.current) {
      case 'image/jpeg':
      case 'image/jpg':
        extension = '.jpg';
        break;
      case 'image/png':
        extension = '.png';
        break;
      default:
        break;
    }
    const dataUrl = await dataUrlToFile(logo, `businessLogo${extension}`);
    const businessLogo = await uploadBusinessLogo(dataUrl);
    return businessLogo?.data;
  }, [form, logo, profile, uploadBusinessLogo]);

  const handleSubmit = React.useCallback(async () => {
    const values = await form.validateFields();
    const commonTrackingEventData = generateCommonTrackingData();
    setUpdating(true);
    try {
      let data = {};
      const logoUrl = await getLogoUrl();
      if (logoUrl) {
        data = {
          ...values,
          logoUrl,
        };
      } else {
        data = { ...values };
      }
      await requestWithErrorLogging({
        url: `/profile-service/api/profile`,
        method: 'post',
        data,
        mutateConfig: {
          mutatePath: ['/profile-service/api/profile'],
        },
      });
      message.success(translate('profile_update_successfully'));
      analyticsTrack(isEmpty(profile) ? 'Created Profile Settings' : 'Updated Profile Settings', {
        business_profile_id: profile?.id,
        business_name: values.businessName,
        email: values.email,
        link_to_contact: values.linkToContact,
        estimated_streaming_time: values.estimatedStreamingTime,
        ...commonTrackingEventData,
      });
      onAfterSubmit?.();
    } catch (err) {
      const genericErrors = showErrorAtFormFields(form, err);
      showError(genericErrors, translate('save_profile_error'), translate('save_profile_error_description'));
    } finally {
      setUpdating(false);
    }
  }, [getLogoUrl, translate, analyticsTrack, profile, onAfterSubmit, form, showError]);

  const handleValuesChange = useCallback(
    (values: Store) => {
      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [form]
  );

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        logo: profile.logo,
        name: profile.name,
        businessName: profile.businessName,
        mobileNumber: profile.mobileNumber,
        email: profile.email,
        linkToContact: profile.linkToContact,
        description: profile.description,
        companyUENNumber: profile.companyUENNumber,
      });
    }
  }, [profile, form]);

  return (
    <Spin spinning={isValidating}>
      <div className="p-2">
        <Typography className="text-xl font-medium">
          <T value="profile" />
        </Typography>
        <Typography className="text-neutral-500">
          <T value="profile_description" />
        </Typography>
        <Form
          form={form}
          layout="vertical"
          initialValues={profile || {}}
          className="mt-6"
          validateTrigger={['onSubmit', 'onBlur']}
          onValuesChange={handleValuesChange}
        >
          <Row gutter={12}>
            <Col xs={24} md={13}>
              <Row gutter={24}>
                <Col flex="150px">
                  <Form.Item name="logo" label={<T value="logo_title" />}>
                    <Dropzone
                      onDrop={onFileSelect}
                      accept="image/jpg, image/jpeg, image/png"
                      minSize={1}
                      maxSize={5 * 1024 * 1024}
                    >
                      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                        <div
                          {...getRootProps({
                            className: `rounded
                        focus:outline-none
                        focus:shadow-outline
                        focus:border-primary
                        border-solid
                        border
                        border-gray-400
                        flex
                        justify-center
                        items-center
                        text-center
                        w-[118px]
                        h-[118px]
                        p-2
                        `,
                          })}
                        >
                          <input {...getInputProps()} />
                          {isDragReject ? rejectHintContent : isDragActive ? dropHintContent : getLogoDefaultContent}
                        </div>
                      )}
                    </Dropzone>
                  </Form.Item>
                </Col>
                <Col flex="auto">
                  <Form.Item name="name" label={<T value="shop_name" />} hidden={isPH}>
                    <Input placeholder={translate('input')} />
                  </Form.Item>
                  <Form.Item
                    name="businessName"
                    label={<T value="business_name" />}
                    rules={[
                      {
                        required: true,
                        message: <T value="this_field_is_required" />,
                      },
                    ]}
                  >
                    <Input placeholder={translate('input')} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={<T value="UEN_number" />} name="companyUENNumber">
                    <Input placeholder={translate('input')} maxLength={UEN_NUMBER_MAX_LENGTH} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="mobileNumber"
                    label={<T value="mobile_number" />}
                    rules={[
                      {
                        required: true,
                        message: <T value="this_field_is_required" />,
                      },
                      {
                        pattern: /^\+*\d+$/gi,
                        message: <T value="please_input_a_phone_number" />,
                      },
                    ]}
                    normalize={(value) => value.replace(/[^+\d]/g, '')}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label={<T value="email" />}
                    rules={[
                      {
                        required: true,
                        message: <T value="this_field_is_required" />,
                      },
                      {
                        type: 'email',
                        message: <T value="email_error" />,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={1} className="text-center">
              <Divider type="vertical" className="h-full" />
            </Col>
            <Col xs={24} md={10}>
              {!isSubadmin() && (
                <Space direction="vertical" className="mb-6">
                  <Typography>
                    <T value="connected_facebook_account" />
                  </Typography>
                  <Spin spinning={isLoadingUser}>
                    <Space>
                      <CustomerAvatar
                        avtSrc={user?.profile?.picture}
                        avatarProps={{ size: 'default' }}
                        badgeSrc={fbIcon}
                        badgeProps={{ offset: [-4, 28] }}
                      />
                      <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
                    </Space>
                  </Spin>
                </Space>
              )}
              <Form.Item name="description" label={<T value="description" />} hidden={isPH}>
                <TextArea autoSize={{ minRows: 1, maxRows: 5 }} />
              </Form.Item>
              <Form.Item name="linkToContact" label={<T value={isPH ? 'fb_Insta_page_link' : 'link_to_contact'} />}>
                <Input placeholder="http://www.example-url.com" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {onAfterSubmit && !hideSubmit && (
          <div className="text-right mt-4">
            <Button onClick={() => handleSubmit()} type="primary" loading={updating}>
              {translate('save')}
            </Button>
          </div>
        )}
      </div>
      {previewSrc && (
        <Modal
          title={<T value="crop_image" />}
          open={!!previewSrc}
          onOk={() => {
            if (imageRefForCrop) {
              form.setFieldsValue({
                logo: getCroppedImg(imageRefForCrop, crop),
              });
            }
            setPreviewSrc(null);
          }}
          onCancel={() => setPreviewSrc(null)}
          okText={translate('ok')}
          cancelText={translate('cancel')}
          width={500}
        >
          <ReactCrop
            src={previewSrc}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onChange={(newCrop: ReactCrop.Crop) => setCrop(newCrop)}
          />
        </Modal>
      )}
      {!onAfterSubmit && !hideSubmit && <CancelSaveFooter onOk={handleSubmit} noMaxWidth isLoading={updating} />}
    </Spin>
  );
};

export default ProfileSection;
