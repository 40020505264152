import moment, { Moment } from 'moment';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import ParseDate from 'date-fns/parse';
import add from 'date-fns/add';

export function formatUnixTimeFacebook(unix: number, format = 'MMMM DD YYYY [at] HH:mm'): string {
  return moment.unix(unix).local().format(format);
}

export function formatTimeFacebook(date: string, format = 'MMMM DD YYYY [at] HH:mm'): string {
  return moment(date).local().format(format);
}

export function formatUnixTime(unix: number, format = 'YYYY-MM-DD HH:mm'): string {
  return moment.unix(unix).local().format(format);
}

export function formatTime(date?: string, format = 'DD/MM/YYYY HH:mm'): string {
  if (!date) {
    return '-';
  }
  return moment(date).local().format(format);
}

export function formatTimeWithSecond(date?: string, format = 'YYYY-MM-DD HH:mm:ss'): string {
  if (!date) {
    return '-';
  }
  return moment(date).local().format(format);
}

export const formatIOSString = (date: string): string => {
  return new Date(date).toISOString();
};

export const disabledToDate =
  (maxDay?: Moment | null) =>
  (current: Moment): boolean => {
    const cloneDay = moment(maxDay).add(1, 'd');
    return !!(maxDay && current && current >= cloneDay.startOf('day'));
  };

export const disabledFromDate =
  (minDay?: Moment | null) =>
  (current: Moment): boolean => {
    const cloneDay = moment(minDay).subtract(1, 'd');
    return !!(minDay && current && current <= cloneDay.endOf('day'));
  };

export const getPreviousMonths = (count: number) => {
  const result = [];
  const currentDate = new Date();
  for (let i = 0; i < count; i++) {
    const value = sub(currentDate, {
      months: i,
    });
    result.push({
      value,
      label: format(value, 'MMM, yyyy'),
    });
  }
  return result;
};

export const getStartAndEndDateOfMonth = (date: Date) => {
  const firstDate = format(startOfMonth(date), 'yyyy-MM-dd');
  const lastDate = format(endOfMonth(date), 'yyyy-MM-dd');
  return {
    firstDate,
    lastDate,
  };
};

export const getOlderStartAndEndDate = (totalPreviousMonth: number) => {
  const currentDate = new Date();
  const date = sub(currentDate, {
    months: totalPreviousMonth,
  });
  const firstDate = format(new Date(1970, 1, 0), 'yyyy-MM-dd');

  const lastDate = format(endOfMonth(date), 'yyyy-MM-dd');
  return {
    firstDate,
    lastDate,
  };
};

export const getDateInNYearAfter = (dateStr: string, year: number): string => {
  const currentDate = convertStringtoDate(dateStr, 'yyyy-MM-dd');
  const endDate = add(currentDate, {
    years: year,
  });
  return format(endDate, 'yyyy-MM-dd');
};

export const convertStringtoDate = (dateStr: string, format: string): Date => {
  return ParseDate(dateStr, format, new Date());
};

export const convertDateToString = (date: Date, formatStr: string): string => {
  return format(date, formatStr);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTimeDuration = (translate: any, start?: string, end?: string) => {
  if (!start || !end) return '';
  const startTime = moment(start);
  const endTime = moment(end);

  const durationAsDays = Number(moment(endTime).diff(startTime, 'days', true).toFixed(1));
  if (durationAsDays >= 1) return `${durationAsDays} ${translate('days')}`;

  const durationAsHours = Number(moment(endTime).diff(startTime, 'hours', true).toFixed(1));
  if (durationAsHours >= 1) return `${durationAsHours} ${translate('hours')}`;

  const durationAsMinutes = Number(moment(endTime).diff(startTime, 'minutes', true).toFixed(1));
  return `${durationAsMinutes} ${translate('minutes')}`;
};

export const convertTo12HourFormat = (time24: string) => {
  const [hours, minutes] = time24.split(':');
  let period = 'AM';

  let hour = parseInt(hours, 10);
  if (hour >= 12) {
    period = 'PM';
    if (hour > 12) {
      hour -= 12;
    }
  } else if (hour === 0) {
    hour = 12;
  }

  if (minutes) return `${hour.toString()}:${minutes} ${period}`;

  return `${hour.toString()} ${period}`;
};

export const convertHourToUTC = (hour: number) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeUTC = hour - timeZoneOffset / -60;
  if (timeUTC < 0) return 24 + timeUTC;
  if (timeUTC > 24) return timeUTC - 24;
  return timeUTC;
};

export const convertHourUTCToHourLocal = (timeUTC: number) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const hour = timeUTC + timeZoneOffset / -60;
  return hour >= 24 ? hour - 24 : hour;
};

export const getRanges = (
  translate: (
    key: string,
    params?:
      | {
          [x: string]: string | number;
        }
      | undefined
  ) => string
): Record<string, [Moment, Moment]> => ({
  [translate('today_range')]: [moment().startOf('d'), moment().endOf('d')],
  [translate('last_n_days', {
    '[NUMBER]': 7,
  })]: [moment().add(-6, 'd'), moment()],
  [translate('last_n_days', {
    '[NUMBER]': 30,
  })]: [moment().add(-29, 'd'), moment()],
  [translate('this_month')]: [moment().startOf('month'), moment().endOf('month')],
  [translate('last_month')]: [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
});

export const getRangesPayment = (
  translate: (
    key: string,
    params?:
      | {
          [x: string]: string | number;
        }
      | undefined
  ) => string
): Record<string, [Moment, Moment]> => ({
  [translate('this_week')]: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  [translate('this_month')]: [moment().startOf('month'), moment().endOf('month')],
  [translate('last_month')]: [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
});

export const getCurrentDateFileName = () => {
  return format(new Date(), 'dd_MM_yy-KK_mm_a');
};
