import { Button, Space, Tag } from 'antd';
import { isEmpty, max } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { LanguageContext } from 'context/language';
import { useFeatureGuide } from 'hooks/featureGuide';
import { getSeeFeatureGuide, setSeeFeatureGuide } from 'utils/storage';

import T from 'shared-components/Translator';

import './styles.css';

type FeatureGuideProps = {
  start: boolean;
};

const DEFAULT_RECT = {
  top: -5000,
  bottom: 0,
  left: 0,
  right: 0,
  width: 0,
  height: 0,
};

type RectType = {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
};

const TIME = 1;

const FeatureGuide = ({ start }: FeatureGuideProps) => {
  const { translate } = useContext(LanguageContext);
  const location = useLocation();

  const { steps, numOfStep } = useFeatureGuide();

  const popoverRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [nodeRects, setNodeRects] = useState<RectType[]>([DEFAULT_RECT]);

  const handleNextStep = useCallback(() => {
    if (currentStep < steps.length - 1) return setCurrentStep((s) => s + 1);
    setCurrentStep(0);
    setOpen(false);
    setSeeFeatureGuide(numOfStep > steps.length ? steps.length : -1, TIME);
  }, [currentStep, numOfStep, steps.length]);

  const step = useMemo(() => {
    const data = steps[currentStep];
    return {
      ...data,
      position: data?.position || 'right',
      type: data?.type || 'contain',
      width: data?.width || 300,
    };
  }, [currentStep, steps]);

  const maskPaths = useMemo(() => {
    switch (step.type) {
      case 'cover':
        return nodeRects
          .map(
            (rect) =>
              `M${rect.left - 16},${rect.top - 8} h${rect.width + 32} v${rect.height + 16} h-${rect.width + 32} Z`
          )
          .join(' ');

      default:
        return nodeRects
          .map((rect) => `M${rect.left},${rect.top} h${rect.width} v${rect.height} h-${rect.width} Z`)
          .join(' ');
    }
  }, [nodeRects, step.type]);

  useEffect(() => {
    const seenStep = getSeeFeatureGuide(TIME);
    if (seenStep === -1 || seenStep >= steps.length) return setOpen(false);
    if (start) {
      setCurrentStep(seenStep);
      setTimeout(() => setOpen(true), 1000);
    }
  }, [location.pathname, start, steps.length]);

  const handleUpdateRect = useCallback(() => {
    const rects =
      step?.selector?.reduce((cur: RectType[], prev: string) => {
        const rootNode = document.querySelector(prev);
        const rect = rootNode?.getBoundingClientRect();
        if (!rect) return cur;
        return [
          ...cur,
          {
            top: rect.top,
            bottom: rect.bottom,
            left: rect.left,
            right: rect.right,
            width: rect.width,
            height: rect.height,
          },
        ];
      }, []) || [];

    setNodeRects(rects);
  }, [step?.selector]);

  useEffect(() => {
    handleUpdateRect();
    window.addEventListener('resize', handleUpdateRect);

    return () => window.removeEventListener('resize', handleUpdateRect);
  }, [handleUpdateRect, isOpen]);

  useEffect(() => {
    const popover = popoverRef.current;

    const resizeObserver = new ResizeObserver(() => {
      if (isOpen && popover) {
        popover.style.width = `${step.width}px`;
        let top, left;
        switch (step.position) {
          case 'bottom':
            {
              top =
                (max(nodeRects.map((item) => item.top)) as number) +
                (max(nodeRects.map((item) => item.height)) as number) +
                24;
              left =
                nodeRects[0].left + (nodeRects[nodeRects.length - 1].right - nodeRects[0].left) / 2 - step.width / 2;
            }
            break;
          case 'bottomRight':
            {
              top =
                (max(nodeRects.map((item) => item.top)) as number) +
                (max(nodeRects.map((item) => item.height)) as number) +
                24;
              left = nodeRects[nodeRects.length - 1].right - step.width;
            }
            break;
          default:
            {
              top =
                nodeRects[0].top +
                (nodeRects[nodeRects.length - 1].bottom - nodeRects[0].top) / 2 -
                popover.clientHeight / 2;
              left = (max(nodeRects.map((item) => item.width)) as number) + 16;
            }
            break;
        }
        popover.style.top = `${top}px`;
        popover.style.left = `${left}px`;
      }
    });

    if (popover) {
      resizeObserver.observe(popover);
    }

    return () => {
      if (popover) {
        resizeObserver.unobserve(popover);
      }
    };
  }, [isOpen, nodeRects, step.position, step.width]);

  if (!isOpen || isEmpty(nodeRects)) return null;

  return createPortal(
    <div className="fixed inset-0 w-full z-[1040] transition-all">
      <svg width="100%" height="100%">
        <mask id="highlightMask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="white" />
          {/* Subtract highlight areas from mask */}
          <path d={maskPaths} fill="black" />
        </mask>
        <rect x="0" y="0" width="100%" height="100%" fill="rgba(0, 0, 0, 0.45)" mask="url(#highlightMask)" />
      </svg>
      <div className="bg-white rounded fixed z-[1060] transition-all" ref={popoverRef}>
        <div className="relative p-4">
          <div
            className={classnames('featureGuide__content', {
              'featureGuide__content-arrow__left': step.position === 'right',
              'featureGuide__content-arrow__top': step.position === 'bottom',
              'featureGuide__content-arrow__topRight': step.position === 'bottomRight',
            })}
          >
            {step.media && (
              <div className="border-b pb-4 mb-4 mx-[-1rem] mt-[-1rem]">
                <video className="mx-auto bg-white w-[300px] h-[300px]" src={step.media} autoPlay loop muted />
              </div>
            )}
            <Space direction="vertical" className="w-full">
              <Tag color="error">
                <T value="new" />
              </Tag>
              {step.content}
              <div className="text-right">
                <Space>
                  {step.learnMore && (
                    <a href={step.learnMore} target="_blank" rel="noreferrer">
                      <Button>{translate('learn_more')}</Button>
                    </a>
                  )}
                  <Button type="primary" onClick={handleNextStep}>
                    {translate('got_it')}
                  </Button>
                </Space>
              </div>
            </Space>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FeatureGuide;
