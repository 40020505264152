import React from 'react';

import { Me } from 'models/Me';

const UserSpaceContext = React.createContext<{
  data?: Me;
  setData?: (data: Me) => void;
}>({
  data: undefined,
  setData: undefined,
});

export default UserSpaceContext;
