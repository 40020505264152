/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { Button, message, Modal, Upload } from 'antd';
import ReactCrop from 'react-image-crop';
import { Dictionary, isEmpty, isEqual, keyBy, reverse } from 'lodash';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { LanguageContext } from 'context/language';
import { useShowError } from 'utils/hooks';
import { getGenericErrors } from 'utils/error';
import { OptionDataType, ProductVariant, VariantAttributeType } from 'models/ProductVariant';
import { ProductImageSize, getOptimizeProductImgUrl, proxyGetImage } from 'utils/productImage';

import T from 'shared-components/Translator';
import {
  checkExtension,
  dataUrlToFile,
  formatAttributeDataToEdit,
  generateVariantFromAttribute,
  getBase64,
  getCroppedImg,
  getImage,
} from 'utils/functions';
import { MANUALLY_ADDED, TEMP_ID } from 'utils/constants';
import { uploadProductImage } from 'apis/product';
import useSWR from 'swr';
import {
  FormattedFreqUsedVariation,
  FreqUsedVariation,
  FreqUsedVariationReq,
  ProductAttribute,
} from 'models/ProductAttribute';
import Product, { ProductImage, PrepareUploadProductImage } from 'models/Product';
import { updateFreqUsedVariation } from 'apis/productVariant';

import { ReactComponent as CropIcon } from 'assets/crop_icon.svg';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';

export const MAX_PRODUCT_ATTRIBUTES = 2;
export const MAX_PRODUCT_OPTIONS = 30;
export const MAX_PRODUCT_ATTRIBUTE_NAME = 20;

export interface UploadFileProps extends UploadFile {
  index: number;
  id: string | null;
  test?: string;
}
export interface PreviewProps {
  image: string;
  title: string;
}

const initialCropValue: ReactCrop.Crop = {
  unit: '%',
  height: 100,
  width: 100,
};

export const useProductAttributeForm = ({
  currentVariants,
  currentAttributes,
  product,
  manuallyAddedVariantIds,
  onSetManuallyAddedVariantIds,
}: {
  currentVariants: ProductVariant[];
  currentAttributes: any;
  product?: Product;
  manuallyAddedVariantIds: string[];
  onSetManuallyAddedVariantIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [deletedVariant, setDeletedVariants] = useState<string[]>([]);

  const attributesData = useMemo(() => {
    if (Array.isArray(currentAttributes)) {
      return currentAttributes.map((attribute) => ({
        id: attribute?.id || '',
        selectedAttribute: attribute?.selectedAttribute,
        options: attribute?.options?.map((option: OptionDataType) => ({
          optionId: option?.optionId || '',
          optionName: option?.optionName || '',
        })),
      }));
    }
    return [];
  }, [currentAttributes]);

  useEffect(() => {
    if (currentAttributes && !isEqual(formatAttributeDataToEdit(product)?.attributes, currentAttributes)) {
      setDeletedVariants([]);
      onSetManuallyAddedVariantIds([]);
    }
  }, [currentAttributes, product, onSetManuallyAddedVariantIds]);

  const manuallyAddedVariants = useMemo(() => {
    // filter new manually added ids from current variants
    const currentVariantIds = currentVariants?.map((variant) => variant?.id || '') || [];
    const newManuallyAddedIds = manuallyAddedVariantIds?.filter((id) => !currentVariantIds.includes(id)) || [];
    const newManuallyAddedVariants: ProductVariant[] =
      newManuallyAddedIds?.map((id) => ({
        id,
        productCode: undefined,
      })) || [];
    return [
      ...(currentVariants?.filter((variant) => variant?.id?.includes(MANUALLY_ADDED)) || []),
      ...newManuallyAddedVariants,
    ];
  }, [currentVariants, manuallyAddedVariantIds]);

  const existVariant = useMemo(() => {
    const variantList = keyBy(currentVariants, (o: ProductVariant) => {
      return o?.id?.includes(MANUALLY_ADDED)
        ? o?.id
        : o?.productVariantValues?.map((o: VariantAttributeType) => o.optionId).join('-') || '';
    });

    return variantList;
  }, [currentVariants]);

  useEffect(() => {
    if (existVariant) {
      const deletedVariantIds = Object.keys(existVariant).filter((key) => existVariant[key].isDelete);
      setDeletedVariants((prev) => [...prev, ...deletedVariantIds]);
    }
  }, [existVariant]);

  const formattedVariants = useMemo(() => {
    const variants: VariantAttributeType[][] = generateVariantFromAttribute(attributesData);
    return variants?.filter((attribute) => {
      const combinedOptionId: string = attribute?.map((o: any) => o.optionId).join('-');
      return !deletedVariant.includes(combinedOptionId);
    });
  }, [attributesData, deletedVariant]);

  const existedImage = useMemo(() => {
    const images = Object.keys(existVariant).reduce((cur: Dictionary<ProductImage>, prev) => {
      const item = existVariant[prev];
      const firstOption = item.productVariantValues?.[0]?.optionName || '';
      return { ...cur, [firstOption]: item.image || cur[firstOption] };
    }, {});
    return images;
  }, [existVariant]);

  const updatedVariants = useMemo(
    () =>
      formattedVariants?.map((attribute) => {
        const combinedOptionId: string = attribute?.map((o: any) => o.optionId).join('-');
        //change position of variations
        const reversedString: string = reverse([...(attribute || [])])
          ?.map((o: any) => o.optionId)
          .join('-');

        const formattedAttributes = attribute?.map((attributeItem) => {
          const variantValueId =
            existVariant[combinedOptionId]?.productVariantValues?.find(
              (item) => item.attributeId === attributeItem.attributeId
            )?.id || '';

          return {
            ...attributeItem,
            ...(variantValueId ? { id: variantValueId } : {}),
          };
        });

        if (Object.prototype.hasOwnProperty.call(existVariant, combinedOptionId)) {
          let image = null;
          if (existVariant[combinedOptionId]?.image) {
            image = existVariant[combinedOptionId].image;
          } else if (existedImage[attribute?.[0]?.optionName]) {
            image = {
              ...existedImage[attribute?.[0]?.optionName],
              id: null,
            };
          }

          return {
            ...existVariant[combinedOptionId],
            productVariantValues: formattedAttributes,
            image,
          };
        }
        //change position of variations
        if (Object.prototype.hasOwnProperty.call(existVariant, reversedString)) {
          return {
            ...existVariant[reversedString],
            productVariantValues: formattedAttributes,
            image: null,
          };
        }
        return {
          productCode: undefined,
          productVariantValues: formattedAttributes,
          image: null,
          price: undefined,
          costPrice: undefined,
          stock: undefined,
          maximumQuantityPerPost: undefined,
          sku: null,
          id: '',
        };
      }) || [],
    [existVariant, existedImage, formattedVariants]
  );

  const combinedVariants = useMemo(() => {
    if (isEmpty(manuallyAddedVariantIds)) {
      return updatedVariants.filter((variant) => !variant?.id?.includes(MANUALLY_ADDED));
    }
    const addedVariants = manuallyAddedVariants.filter((variant) => !deletedVariant.includes(variant?.id || ''));
    return [...updatedVariants, ...addedVariants];
  }, [updatedVariants, deletedVariant, manuallyAddedVariantIds, manuallyAddedVariants]);

  return {
    attributesData,
    updatedVariants: combinedVariants,
  };
};

export const useUploadProductImage = (handleUpdateImgToForm?: (img: any) => void) => {
  const { translate } = useContext(LanguageContext);
  const { showError } = useShowError();

  const [fileList, setFileList] = React.useState<UploadFileProps[]>([]);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [preview, setPreview] = React.useState<PreviewProps>({
    image: '',
    title: '',
  });
  const [previewCropSrc, setPreviewCropSrc] = React.useState<UploadFile | null>(null);
  const [crop, setCrop] = React.useState<ReactCrop.Crop>(initialCropValue);
  const [imageRefForCrop, setImageRefForCrop] = React.useState<HTMLImageElement>();
  const [uploading, setUploading] = React.useState<boolean>(false);

  const beforeUploadFiles = React.useRef<RcFile[]>([]);

  const handlePreview = useCallback(async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const previewImg = (file.url || file.preview || '').replace('/small/', '/');
    const optimizePreviewImg = getOptimizeProductImgUrl(previewImg, ProductImageSize.MEDIUM);

    setPreviewVisible(true);
    setPreview({
      image: optimizePreviewImg,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  }, []);

  const handleUploadProductImageWithCrop = useCallback(
    async (imageRefForCrop: HTMLImageElement, { uid, name }: any) => {
      const file: any = await getCroppedImg(imageRefForCrop, crop, true);
      const data = [...fileList];
      const fmData = new FormData();
      fmData.append('FileUpload', file);
      const item: UploadFileProps = {
        index: fileList.length,
        uid: uid,
        id: null,
        name: name,
        status: 'uploading',
        type: file.type,
        size: file.size,
      };
      data.forEach((file) => {
        if (file.uid === uid) {
          return item;
        }
        return file;
      });

      try {
        setUploading(true);
        const resData = await uploadProductImage(fmData);
        const formattedData: UploadFileProps[] = data.map((item: UploadFileProps) => {
          if (item.uid === uid) {
            return {
              ...item,
              status: 'done',
              url: resData,
            };
          }
          return item;
        });
        setFileList(formattedData);
        handleUpdateImgToForm?.(formattedData);
      } catch (err) {
        const formattedData: UploadFileProps[] = data.map((item: UploadFileProps) => {
          if (item.uid === uid) {
            return {
              ...item,
              status: 'error',
            };
          }
          return item;
        });
        setFileList(formattedData);
        handleUpdateImgToForm?.(formattedData);
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('file_uploaded_unsuccessfully', { '[NAME]': name }),
          translate('error_occurred_try_again')
        );
      } finally {
        setUploading(false);
      }
    },
    [crop, fileList, handleUpdateImgToForm, showError, translate]
  );

  const handleUploadMultiProductImages = useCallback(
    async (images: PrepareUploadProductImage[]) => {
      const getFiles = images.map((img) => getCroppedImg(img.imageRefForCrop, crop, false));
      const files: any[] = await Promise.all(getFiles);

      const prepareFileToUpload: UploadFileProps[] = files.map((file, idx) => ({
        index: (fileList[fileList.length - 1]?.index || 0) + idx + 1,
        uid: images[idx].uid,
        id: null,
        name: images[idx].name,
        status: 'uploading',
        type: file.type,
        size: file.size,
      }));

      try {
        setUploading(true);
        const uploadImgs = files.map((file) => {
          const fmData = new FormData();
          fmData.append('FileUpload', file);
          return uploadProductImage(fmData);
        });
        const resDataUrls: any[] = await Promise.all(uploadImgs);
        const uploadedFiles: UploadFileProps[] = prepareFileToUpload.map((file, idx) => ({
          ...file,
          status: 'done',
          url: resDataUrls[idx],
        }));
        const currentFiles: UploadFileProps[] = [...fileList, ...uploadedFiles];
        setFileList(currentFiles);
        handleUpdateImgToForm?.(currentFiles);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('upload_product_image_error'), translate('error_occurred_try_again'));
      } finally {
        setUploading(false);
      }
    },
    [crop, fileList, handleUpdateImgToForm, showError, translate]
  );

  const handleRemove = useCallback(
    (file: UploadFile) => {
      const newFileList: UploadFileProps[] = fileList.filter((item) => item.uid !== file.uid);
      const fileIndex = fileList.findIndex((item) => item.uid === file.uid);
      if (fileIndex < newFileList.length) {
        const formattedNewFileList = newFileList.map((item, index) => ({
          ...item,
          [index]: index,
        }));
        handleUpdateImgToForm?.(formattedNewFileList);
        return setFileList(formattedNewFileList);
      }
      handleUpdateImgToForm?.(newFileList);
      return setFileList(newFileList);
    },
    [fileList, handleUpdateImgToForm]
  );

  const handleValidateImg = useCallback(
    async (newImgs: RcFile[]) => {
      const getUrlAndTypeOfImgs = newImgs.map((img) => Promise.all([getBase64(img), checkExtension(img)]));
      // urlAndTypeOfImgs : [url, type][]
      const urlAndTypeOfImgs: any[][] = await Promise.all(getUrlAndTypeOfImgs);

      const formattedNewImgs = newImgs.map((img, idx) => ({
        ...img,
        uid: img.uid,
        name: img.name,
        size: img.size,
        url: urlAndTypeOfImgs[idx][0],
        type: urlAndTypeOfImgs[idx][1],
      }));

      const acceptedImgs = formattedNewImgs.filter((img) => {
        const isJpgOrPngOrJpeg = ['image/jpg', 'image/png', 'image/jpeg'].includes(img.type);
        if (!isJpgOrPngOrJpeg) {
          message.error(translate('upload_image_rule'));
          return false;
        }
        const is10Mb = img.size / 1024 / 1024 < 10;
        if (!is10Mb) {
          message.error(translate('upload_image_size_rule'));
          return false;
        }

        return true;
      });

      if (acceptedImgs.length) {
        const getImages: any = acceptedImgs.map((img) => getImage(img.url));
        const images = await Promise.all(getImages);

        const prepareUploadImg: any[] = acceptedImgs.map((img, idx) => ({
          ...img,
          imageRefForCrop: images[idx],
        }));

        await handleUploadMultiProductImages(prepareUploadImg);
      }
    },
    [handleUploadMultiProductImages, translate]
  );

  const handleBeforeUpload = useCallback(
    (maxImgs: number) => async (file: RcFile, list: RcFile[]) => {
      const maxNumberImg = maxImgs || 1;
      const currentImgNumber = fileList?.length || 0;
      const maxNumberImgsCanUpload = currentImgNumber >= maxNumberImg ? 0 : maxImgs - currentImgNumber;
      const indexOfTheLastItemCanUpload =
        list.length < maxNumberImgsCanUpload ? list.length - 1 : maxNumberImgsCanUpload - 1;
      const indexOfFile = list.findIndex((item: RcFile) => file.uid === item.uid);

      // Reject with items which are out of limit
      if (list.length > maxNumberImgsCanUpload && indexOfFile > indexOfTheLastItemCanUpload) return;

      // Just run the Upload method only once when handleBeforeUpload runs on the last item
      beforeUploadFiles.current = [...beforeUploadFiles.current, file];
      if (indexOfFile < indexOfTheLastItemCanUpload) {
        return;
      } else {
        const newImgs = [...beforeUploadFiles.current];
        await handleValidateImg(newImgs);
        beforeUploadFiles.current = [];
      }
    },
    [fileList?.length, handleValidateImg]
  );

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRefForCrop(image);
  };

  const handleSaveCropImg = useCallback(() => {
    if (imageRefForCrop) {
      handleUploadProductImageWithCrop(imageRefForCrop, previewCropSrc);
    }
    setPreviewCropSrc(null);
    setCrop(initialCropValue);
  }, [handleUploadProductImageWithCrop, imageRefForCrop, previewCropSrc]);

  const handleCancelCropImg = useCallback(() => {
    setPreviewCropSrc(null);
    setCrop(initialCropValue);
  }, []);

  const handleClickSquareRatio = useCallback(() => {
    if (imageRefForCrop) {
      if (imageRefForCrop.width > imageRefForCrop.height) {
        const xPosition = Math.round((imageRefForCrop.width - imageRefForCrop.height) / 2);
        setCrop((prev) => ({ ...prev, width: imageRefForCrop.height, height: imageRefForCrop.height, x: xPosition }));
      } else {
        const yPosition = Math.round((imageRefForCrop.height - imageRefForCrop.width) / 2);
        setCrop((prev) => ({ ...prev, width: imageRefForCrop.width, height: imageRefForCrop.width, y: yPosition }));
      }
    }
  }, [imageRefForCrop]);

  const renderCropComponent = useCallback(
    () => (
      <>
        {!isEmpty(previewCropSrc) && (
          <Modal
            title={<T value="crop_image" />}
            visible={!isEmpty(previewCropSrc)}
            footer={
              <div className="flex justify-between items-between">
                <Button onClick={handleClickSquareRatio}>{translate('set_square_ratio')}</Button>
                <div>
                  <Button onClick={handleCancelCropImg}>{translate('cancel')}</Button>
                  <Button type="primary" onClick={handleSaveCropImg}>
                    {translate('save')}
                  </Button>
                </div>
              </div>
            }
            onCancel={handleCancelCropImg}
            width={500}
          >
            <ReactCrop
              src={previewCropSrc?.url || ''}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onChange={(newCrop: ReactCrop.Crop) => setCrop(newCrop)}
              keepSelection
            />
          </Modal>
        )}
      </>
    ),
    [crop, handleCancelCropImg, handleClickSquareRatio, handleSaveCropImg, previewCropSrc, translate]
  );

  const renderPreviewComponent = useCallback(() => {
    return (
      <Modal
        visible={previewVisible}
        title={<T value="preview" />}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        cancelText={translate('cancel')}
      >
        <img alt={preview.title} src={preview.image} className="mx-auto" />
      </Modal>
    );
  }, [preview.image, preview.title, previewVisible, translate]);

  const handleClickPreviewCrop = useCallback(async (file: UploadFile) => {
    let dataUrl: File;
    try {
      dataUrl = await dataUrlToFile(file.url || '', file.fileName || '');
    } catch (_) {
      dataUrl = await dataUrlToFile(proxyGetImage({ type: 'image', url: file.url || '' }), file.fileName || '');
    }
    const url: any = await getBase64(dataUrl);
    const newImg = { ...file, url };
    setPreviewCropSrc(newImg);
  }, []);

  const renderImageItem = useCallback(
    (
      _originNode: React.ReactElement,
      file: UploadFile,
      _fileList: any,
      actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
      }
    ) => {
      const url = getOptimizeProductImgUrl(file.url || '', ProductImageSize.SMALL);
      return (
        <div className="image-item-container border rounded-sm">
          <div className="image-item">
            <div className="w-full h-full flex items-center justify-center relative">
              <img className="w-full h-full object-contain" src={url} />
              <div className="overlay">
                <div className="img-action-group">
                  <EyeOutlined className="cursor-pointer text-white" onClick={actions.preview} />
                  <CropIcon
                    className="cursor-pointer"
                    width={14}
                    height={14}
                    onClick={() => handleClickPreviewCrop(file)}
                  />
                  <DeleteOutlined className="cursor-pointer text-white" onClick={actions.remove} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [handleClickPreviewCrop]
  );

  const renderUploadComponent = useCallback(
    ({
      maxImgs,
      className = '',
      placehoder = 'image',
    }: {
      maxImgs: number;
      className?: string;
      placehoder?: string;
    }) => (
      <Upload
        className={classnames(className, 'upload-image')}
        accept=".png, .jpg, .jpeg"
        listType="picture-card"
        fileList={fileList}
        customRequest={() => null}
        onPreview={handlePreview}
        onRemove={handleRemove}
        beforeUpload={handleBeforeUpload(maxImgs)}
        maxCount={maxImgs}
        multiple
        itemRender={renderImageItem}
      >
        {fileList.length < maxImgs && (
          <div className="image-item-container border rounded-sm text-primary">
            <div className="image-item flex flex-col justify-center items-center">
              <PlusOutlined />
              <T value={placehoder} />
            </div>
          </div>
        )}
      </Upload>
    ),
    [fileList, handleBeforeUpload, handlePreview, handleRemove, renderImageItem]
  );

  return {
    renderUploadComponent,
    renderPreviewComponent,
    fileList,
    setFileList,
    renderCropComponent,
    uploading,
  };
};

export const useFetchAttributes = (categoryId?: string) => {
  const { data: attributes, isValidating } = useSWR<ProductAttribute[]>(
    categoryId ? `product-service/api/categories/attributes/${categoryId}` : null
  );

  return { attributes, isValidating };
};

export const useFetchFreqUsedVariations = (enabled: boolean) => {
  const {
    data: variations,
    isValidating,
    revalidate,
  } = useSWR<FreqUsedVariation[]>(enabled ? 'product-service/api/variations/frequently-used' : '');

  const formattedVariations: FormattedFreqUsedVariation[] = useMemo(
    () =>
      variations?.map((item) => ({
        ...item,
        attributeOptions:
          item.attributeOptions?.map((option) => ({
            optionName: option,
            optionId: `${TEMP_ID}-${uuidv4()}`,
          })) || [],
      })) || [],
    [variations]
  );

  return { variations: formattedVariations, isValidating, revalidateVariation: revalidate };
};

export const useUpdateFreqUsedVariations = () => {
  const { showError } = useShowError();
  const [isLoading, setIsLoading] = React.useState(false);

  const { translate } = useContext(LanguageContext);

  const handleUpdateFreqUsedVariations = useCallback(
    async (data: FreqUsedVariationReq[], onSuccess?: () => void) => {
      setIsLoading(true);
      try {
        await updateFreqUsedVariation({ items: data });
        onSuccess?.();
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('update_freq_used_variation_error'),
          translate('update_freq_used_variation_error_description')
        );
      } finally {
        setIsLoading(false);
      }
    },
    [showError, translate]
  );

  return {
    isLoading,
    updateFreqUsedVariation: handleUpdateFreqUsedVariations,
  };
};

export const usePreviewProductImage = () => {
  const [preview, setPreview] = useState<PreviewProps>({
    image: '',
    title: '',
  });

  const handleInitPreviewData = useCallback(async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const previewImg = (file.url || file.preview || '').replace('/small/', '/');
    const optimizePreviewImg = getOptimizeProductImgUrl(previewImg, ProductImageSize.MEDIUM);

    setPreview({
      image: optimizePreviewImg,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  }, []);

  return {
    preview,
    handleInitPreviewData,
  };
};

export const useUpdateProductImage = (handleUpdateImgToForm?: (img: any) => void) => {
  const { translate } = useContext(LanguageContext);
  const { showError } = useShowError();

  const [fileList, setFileList] = React.useState<UploadFileProps[]>([]);
  const [previewCropSrc, setPreviewCropSrc] = React.useState<UploadFile | null>(null);
  const [crop, setCrop] = React.useState<ReactCrop.Crop>(initialCropValue);
  const [imageRefForCrop, setImageRefForCrop] = React.useState<HTMLImageElement>();
  const [uploading, setUploading] = React.useState<boolean>(false);

  const beforeUploadFiles = React.useRef<RcFile[]>([]);

  const handleUploadProductImageWithCrop = useCallback(
    async (imageRefForCrop: HTMLImageElement, { uid, name }: any) => {
      const file: any = await getCroppedImg(imageRefForCrop, crop, true);
      const data = [...fileList];
      const fmData = new FormData();
      fmData.append('FileUpload', file);
      const item: UploadFileProps = {
        index: fileList.length,
        uid: uid,
        id: null,
        name: name,
        status: 'uploading',
        type: file.type,
        size: file.size,
      };
      data.forEach((file) => {
        if (file.uid === uid) {
          return item;
        }
        return file;
      });

      try {
        setUploading(true);
        const resData = await uploadProductImage(fmData);
        const formattedData: UploadFileProps[] = data.map((item: UploadFileProps) => {
          if (item.uid === uid) {
            return {
              ...item,
              status: 'done',
              url: resData,
            };
          }
          return item;
        });
        setFileList(formattedData);
        handleUpdateImgToForm?.(formattedData);
      } catch (err) {
        const formattedData: UploadFileProps[] = data.map((item: UploadFileProps) => {
          if (item.uid === uid) {
            return {
              ...item,
              status: 'error',
            };
          }
          return item;
        });
        setFileList(formattedData);
        handleUpdateImgToForm?.(formattedData);
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('file_uploaded_unsuccessfully', { '[NAME]': name }),
          translate('error_occurred_try_again')
        );
      } finally {
        setUploading(false);
      }
    },
    [crop, fileList, handleUpdateImgToForm, showError, translate]
  );

  const handleUploadMultiProductImages = useCallback(
    async (images: PrepareUploadProductImage[]) => {
      const getFiles = images.map((img) => getCroppedImg(img.imageRefForCrop, crop, false));
      const files: any[] = await Promise.all(getFiles);

      const prepareFileToUpload: UploadFileProps[] = files.map((file, idx) => ({
        index: (fileList[fileList.length - 1]?.index || 0) + idx + 1,
        uid: images[idx].uid,
        id: null,
        name: images[idx].name,
        status: 'uploading',
        type: file.type,
        size: file.size,
      }));

      try {
        setUploading(true);
        const uploadImgs = files.map((file) => {
          const fmData = new FormData();
          fmData.append('FileUpload', file);
          return uploadProductImage(fmData);
        });
        const resDataUrls: any[] = await Promise.all(uploadImgs);
        const uploadedFiles: UploadFileProps[] = prepareFileToUpload.map((file, idx) => ({
          ...file,
          status: 'done',
          url: resDataUrls[idx],
        }));
        const currentFiles: UploadFileProps[] = [...fileList, ...uploadedFiles];
        setFileList(currentFiles);
        handleUpdateImgToForm?.(currentFiles);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('upload_product_image_error'), translate('error_occurred_try_again'));
      } finally {
        setUploading(false);
      }
    },
    [crop, fileList, handleUpdateImgToForm, showError, translate]
  );

  const handleRemove = useCallback(
    (file: UploadFile) => {
      const newFileList: UploadFileProps[] = fileList.filter((item) => item.uid !== file.uid);
      const fileIndex = fileList.findIndex((item) => item.uid === file.uid);
      if (fileIndex < newFileList.length) {
        const formattedNewFileList = newFileList.map((item, index) => ({
          ...item,
          [index]: index,
        }));
        handleUpdateImgToForm?.(formattedNewFileList);
        return setFileList(formattedNewFileList);
      }
      handleUpdateImgToForm?.(newFileList);
      return setFileList(newFileList);
    },
    [fileList, handleUpdateImgToForm]
  );

  const handleValidateImg = useCallback(
    async (newImgs: RcFile[]) => {
      const getUrlAndTypeOfImgs = newImgs.map((img) => Promise.all([getBase64(img), checkExtension(img)]));
      // urlAndTypeOfImgs : [url, type][]
      const urlAndTypeOfImgs: any[][] = await Promise.all(getUrlAndTypeOfImgs);

      const formattedNewImgs = newImgs.map((img, idx) => ({
        ...img,
        uid: img.uid,
        name: img.name,
        size: img.size,
        url: urlAndTypeOfImgs[idx][0],
        type: urlAndTypeOfImgs[idx][1],
      }));

      const acceptedImgs = formattedNewImgs.filter((img) => {
        const isJpgOrPngOrJpeg = ['image/jpg', 'image/png', 'image/jpeg'].includes(img.type);
        if (!isJpgOrPngOrJpeg) {
          message.error(translate('upload_image_rule'));
          return false;
        }
        const is10Mb = img.size / 1024 / 1024 < 10;
        if (!is10Mb) {
          message.error(translate('upload_image_size_rule'));
          return false;
        }

        return true;
      });

      if (acceptedImgs.length) {
        const getImages: any = acceptedImgs.map((img) => getImage(img.url));
        const images = await Promise.all(getImages);

        const prepareUploadImg: any[] = acceptedImgs.map((img, idx) => ({
          ...img,
          imageRefForCrop: images[idx],
        }));

        await handleUploadMultiProductImages(prepareUploadImg);
      }
    },
    [handleUploadMultiProductImages, translate]
  );

  const handleBeforeUpload = useCallback(
    (maxImgs: number) => async (file: RcFile, list: RcFile[]) => {
      const maxNumberImg = maxImgs || 1;
      const currentImgNumber = fileList?.length || 0;
      const maxNumberImgsCanUpload = currentImgNumber >= maxNumberImg ? 0 : maxImgs - currentImgNumber;
      const indexOfTheLastItemCanUpload =
        list.length < maxNumberImgsCanUpload ? list.length - 1 : maxNumberImgsCanUpload - 1;
      const indexOfFile = list.findIndex((item: RcFile) => file.uid === item.uid);

      // Reject with items which are out of limit
      if (list.length > maxNumberImgsCanUpload && indexOfFile > indexOfTheLastItemCanUpload) return;

      // Just run the Upload method only once when handleBeforeUpload runs on the last item
      beforeUploadFiles.current = [...beforeUploadFiles.current, file];
      if (indexOfFile < indexOfTheLastItemCanUpload) {
        return;
      } else {
        const newImgs = [...beforeUploadFiles.current];
        await handleValidateImg(newImgs);
        beforeUploadFiles.current = [];
      }
    },
    [fileList?.length, handleValidateImg]
  );

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRefForCrop(image);
  };

  const handleSaveCropImg = useCallback(async () => {
    if (imageRefForCrop) {
      await handleUploadProductImageWithCrop(imageRefForCrop, previewCropSrc);
    }
    setPreviewCropSrc(null);
    setCrop(initialCropValue);
  }, [handleUploadProductImageWithCrop, imageRefForCrop, previewCropSrc]);

  const handleCancelCropImg = useCallback(() => {
    setPreviewCropSrc(null);
    setCrop(initialCropValue);
  }, []);

  const handleClickSquareRatio = useCallback(() => {
    if (imageRefForCrop) {
      if (imageRefForCrop.width > imageRefForCrop.height) {
        const xPosition = Math.round((imageRefForCrop.width - imageRefForCrop.height) / 2);
        setCrop((prev) => ({ ...prev, width: imageRefForCrop.height, height: imageRefForCrop.height, x: xPosition }));
      } else {
        const yPosition = Math.round((imageRefForCrop.height - imageRefForCrop.width) / 2);
        setCrop((prev) => ({ ...prev, width: imageRefForCrop.width, height: imageRefForCrop.width, y: yPosition }));
      }
    }
  }, [imageRefForCrop]);

  const handleClickPreviewCrop = useCallback(async (file: UploadFile) => {
    let dataUrl: File;
    try {
      dataUrl = await dataUrlToFile(file.url || '', file.fileName || '');
    } catch (_) {
      dataUrl = await dataUrlToFile(proxyGetImage({ type: 'image', url: file.url || '' }), file.fileName || '');
    }
    const url: any = await getBase64(dataUrl);
    const newImg = { ...file, url };
    setPreviewCropSrc(newImg);
  }, []);

  return {
    fileList,
    setFileList,
    uploading,
    handleBeforeUpload,
    handleRemove,
    handleClickPreviewCrop,
    previewCropSrc,
    crop,
    setCrop,
    onImageLoaded,
    handleClickSquareRatio,
    handleCancelCropImg,
    handleSaveCropImg,
  };
};
