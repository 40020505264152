import { ProductImage } from './Product';
import { VariantAttributeType } from './ProductVariant';

export const enum BundleDealStatus {
  All = -1,
  Scheduled = 0,
  Active,
  Expired,
}

export const enum BundleDealType {
  BundlePrice = 1,
  PercentageOff = 2,
  FixedAmountOff = 3,
  FreeGift,
}

export const enum BundleDealCondition {
  Quantity = 1,
  MinSpend,
  MinQuantity,
}

export interface BundleDealProduct {
  bundleProductQuantity?: number;
  isEligible?: boolean;
  postId?: string;
  price?: number;
  productCode?: string;
  productId?: string;
  productName?: string;
  productQuantity?: number;
  subTotal?: number;
  freeGiftProductId?: string;
}

export interface ProductFreeGift {
  id?: string;
  price?: number;
  productCode?: string;
  productImages?: ProductImage[];
  productName?: string;
  productVariantValues?: VariantAttributeType[];
  productRemainStock?: number;
}

export interface BundleDealTier {
  minimumPurchaseQuantity?: number;
  discountNewPrice?: number;
  discountPercentage?: number;
  discountFixAmount?: number;
  tierOrder?: number;
  minimumSpending?: number;
  freeGiftProduct?: ProductFreeGift;
  freeGiftProductId?: string;
  freeGiftRemainingStock?: number;
  freeGiftTotalStock?: number;
  isEligible?: boolean;
  discount?: number;
  freeGiftRedeemed?: number;
  id?: string;
}

export default interface BundleDeal {
  activatedAt?: string;
  buyerReachedPurchaseLimit?: boolean;
  conditions?: BundleDealTier[];
  createdAt?: string;
  deActivatedAt?: string;
  id?: string;
  isActive?: boolean;
  name?: string;
  purchaseLimit?: number | null;
  status?: BundleDealStatus;
  type?: BundleDealType;
  productCount?: number;
  productImages?: string[];
  products?: BundleDealProduct[];
  discount?: number;
  productIds?: string[];
  paidRedeemed?: number;
  conditionType?: BundleDealCondition;
  limit?: number;
  isEligible?: boolean;
}

export interface BundleDealReqData {
  id?: string;
  name?: string;
  type?: BundleDealType;
  purchaseLimit: number | null;
  conditions: {
    minimumPurchaseQuantity: number;
    minimumSpending: number;
    tierOrder: number;
    discountNewPrice?: number;
    discountPercentage?: number;
    discountFixAmount?: number;
    freeGiftProductId?: string;
    freeGiftTotalStock?: number;
    newFreeGiftTotalStock?: number;
  }[];
  activatedAt: string;
  deActivatedAt: string;
  productIds: string[];
}

export interface BundleDealES {
  activated_at?: string;
  bundle_id?: string;
  bundle_name?: string;
  bundle_name_display?: string;
  deactivated_at?: string;
  index_id?: string;
  index_time?: string;
  is_deleted?: boolean;
  product_codes?: string[];
  product_ids?: string[];
  product_images?: string[];
  product_names?: string[];
  type?: BundleDealType;
  user_id?: string;
  status?: BundleDealStatus;
  paid_redeemed?: number;
  condition_type?: BundleDealCondition;
  conditions?: {
    bundle_id?: string;
    discount_fix_amount?: number;
    discount_new_price?: number;
    discount_percentage?: number;
    minimum_purchase_quantity?: number;
    tier_id?: string;
    tier_order?: number;
    minimum_spending?: number;
    free_gift_product_id?: string;
    free_gift_product?: {
      id: string;
      product_code: string;
    };
  }[];
  total_usage_limit?: number;
}

export type FreeGift = {
  bundleId: string;
  bundleName: string;
  freeGiftTotalStock: number;
  freeGiftRedeemed: number;
  freeGiftRemainingStock?: number;
};
