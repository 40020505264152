import React, { CSSProperties, FunctionComponent } from 'react';
import { primaryColor } from 'utils/colors';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
export interface BlockSpinnerProps {
  height?: number | string;
  size?: number | string;
  color?: string;
  style?: CSSProperties;
}

const BlockSpinner: FunctionComponent<BlockSpinnerProps> = ({ height, size, color, style }: BlockSpinnerProps) => {
  const blockHeight = height || 150;
  const iconSize = size || 36;
  const iconColor = color || primaryColor;
  const additionalStyles = style || {};
  return (
    <div
      style={{
        height: blockHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...additionalStyles,
      }}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: iconSize, color: iconColor }} spin={true} />} />
    </div>
  );
};

export default BlockSpinner;
