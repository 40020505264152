export default interface DeliveryService {
  id?: string;
  option?: string;
  surcharge?: number;
  minimumSpending?: number | null;
  isDeleted?: boolean;
  isEnabled?: boolean;
  isAvailable?: boolean;
  applicable?: boolean;
}

export enum ShippingCalculation {
  PER_ORDER_FLAT_RATE = 1,
  WEIGHT_BASED_SHIPPING = 3,
  ITEM_BASED_SHIPPING = 2,
  PRICE_BASED_SHIPPING = 99,
  PRODUCT_BASED_RATE,
}

export const mappingLabelShippingCalculationType = {
  [ShippingCalculation.PER_ORDER_FLAT_RATE]: 'flat_rate',
  [ShippingCalculation.WEIGHT_BASED_SHIPPING]: 'first_&_additional_weight',
  [ShippingCalculation.ITEM_BASED_SHIPPING]: 'item_based_shipping',
  [ShippingCalculation.PRICE_BASED_SHIPPING]: 'price_based_shipping',
  [ShippingCalculation.PRODUCT_BASED_RATE]: 'product_based_rate',
};

export enum ShippingTimeType {
  FIXED = 1,
  FLEXIBLE = 2,
}

export enum WeightBaseType {
  BASE = 1,
  TIER = 2,
}

export enum ItemBaseType {
  BASE = 1,
  TIER = 2,
}

export interface ShippingFeeItem {
  surcharge: number | null;
  lowerBound: number | null;
  upperBound: number | null;
  order?: number;
}

export interface DeliveryMethod {
  option: string;
  shippingRegions?: number[];
  allRegion?: boolean;
  globalShipment?: boolean;
  shippingTime?: {
    shippingTimeType?: number;
    numberOfShippingDays?: number;
    shippingTimeFrom?: number;
    shippingTimeTo?: number;
  };
  shippingCalculationType: ShippingCalculation;
  minimumSpending?: number;
  flatRateSurcharge?: {
    surcharge: number;
  };
  adjustableTierBasedSurcharge?: {
    surchargeItems: ShippingFeeItem[];
  };
  adjustableValueBasedSurcharge?: AdjustableValueBasedSurcharge;
  active?: boolean;
  additionalInformation?: string;
}

export interface AdjustableValueBasedSurcharge {
  surcharge: number;
  value: number;
  additionalSurcharge: number;
  additionalValue?: number;
}

export interface DeliveryServiceV2 {
  id: string;
  option: string;
  shippingRegions?: number[];
  shippingTime?: {
    shippingTimeType?: ShippingTimeType;
    numberOfShippingDays?: number;
    shippingTimeFrom?: number;
    shippingTimeTo?: number;
  };
  minimumSpending?: number;
  active?: boolean;
  allRegion?: boolean;
  globalShipment?: boolean;
  shippingCalculationType: ShippingCalculation;
  flatRateSurcharge?: {
    surcharge: number;
  };
  adjustableTierBasedSurcharge?: {
    surchargeItems: ShippingFeeItem[];
  };
  adjustableValueBasedSurcharge?: AdjustableValueBasedSurcharge;
  surcharge?: number;
  applicable?: boolean;
  additionalInformation?: string;
  // generate on FE side
  shippingGroupId?: string;
}

export interface DeliveryServiceV2WithAdditionalData extends DeliveryServiceV2 {
  isNew?: boolean;
}

export interface CustomDeliveryGroupReq {
  groupName?: string;
  productIds: string[];
  note?: string;
  methods: DeliveryMethod[];
}

export interface CustomDeliveryGroupData {
  created?: string;
  deliveryOptions?: DeliveryServiceV2[];
  groupName?: string;
  id?: string;
  lastModified?: string;
  note?: string;
  numberOfProducts?: number;
}
