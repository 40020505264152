import { News, NewsResponse } from 'models/News';

const BASE_URL = process.env.REACT_APP_PATCH_NOTE_API;

const apiClient = async (path: string, init?: RequestInit) => {
  const res = await fetch(`${BASE_URL}/${path}`, init);
  if (res.status === 200) {
    return await res.json();
  } else {
    return null;
  }
};
export const fetcher = async (url: string): Promise<NewsResponse> => {
  const response = await fetch(url);
  const totalPage = response.headers.get('x-wp-totalpages') || '0';
  const responseData: NewsResponse = {
    news: (await response.json()) as News[],
    totalPage: parseInt(totalPage) || 0,
  };
  return responseData;
};

export const getMediaInfo = async (mediaId: string) => {
  const path = `/wp-json/wp/v2/media/${mediaId}`;
  return await apiClient(path);
};
