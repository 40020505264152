import { groupBy, isEqual } from 'lodash';
import Cart from 'models/Cart';
import CartProduct from 'models/CartProduct';
import CartStatus from 'models/CartStatus';

export const cartStatuses = [
  CartStatus.Pending,
  CartStatus.WaitingForPayment,
  CartStatus.Failed,
  CartStatus.PendingForVerification,
  CartStatus.Completed,
];

export const formatProductListByShippingGroup = (products: CartProduct[]) => {
  const shippingGroupProducts = products.filter((o) => o.shippingGroupId);
  const groupByShippingGroup = groupBy(shippingGroupProducts, (o) => o.shippingGroupId);

  return groupByShippingGroup;
};

export const checkCartHasBeenEdited = (cartBefore: Cart, cartAfter: Cart) => {
  // check general products
  if (!isEqual(cartBefore.products, cartAfter.products)) {
    return true;
  }
  // check shipping groups (products, delivery)
  if (!isEqual(cartBefore.shippingGroups, cartAfter.shippingGroups)) {
    return true;
  }
  // check general delivery
  if (!isEqual(cartBefore.deliveryOptionId, cartAfter.deliveryOptionId)) {
    return true;
  }
  // check address
  if (!isEqual(cartBefore.selectedAddress, cartAfter.selectedAddress)) {
    return true;
  }
  // check summary & address
  const fields = [
    'paidAmount',
    'checkoutAmount',
    'subTotal',
    'recipient',
    'recipientPhoneNumber',
    'referenceNumber',
    'deliveryAddress',
    'emailAddress',
  ];
  const ignoredUndefined = ['paidAmount', 'checkoutAmount'];
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isEqual(cartBefore[field], cartAfter[field])) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (cartBefore[field] !== undefined || !ignoredUndefined.includes(field)) {
        return true;
      }
    }
  }

  return false;
};
