/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from 'axios';
import { UpdateAddressInOrderRequest } from 'models/Address';
import { UpdateDeliveryRequest } from 'models/DeliveryStatus';
import { UpdateReasonRequest, UpdateNoteRequest } from 'models/Requests';

export const getCartDetailList =
  ({ Ids = [] }: { Ids?: string[] }) =>
  async () => {
    const res = await Axios.post('/cart-service/api/search/carts/ids', {
      Ids,
    });
    return res.data;
  };

export const updateDeliveryStatus = async (req: UpdateDeliveryRequest) => {
  const res = await Axios.patch(`/cart-service/api/shipments/${req.shipmentIds?.[0]}`, {
    newDeliveryStatus: req.newDeliveryStatus,
    isSendShippingNoti: !!req.isSendShippingNoti,
    ...(req.archivedStatusDisplayOption ? { updateShipmentArchiveOption: req.archivedStatusDisplayOption } : {}),
  });
  return res.data;
};

export const massUpdateDeliveryStatus = async (req: UpdateDeliveryRequest) => {
  const res = await Axios.patch(`/cart-service/api/shipments/bulk`, {
    shipmentIds: req.shipmentIds,
    newDeliveryStatus: req.newDeliveryStatus,
    isSendShippingNoti: !!req.isSendShippingNoti,
    ...(req.archivedStatusDisplayOption ? { updateShipmentArchiveOption: req.archivedStatusDisplayOption } : {}),
  });
  return res.data;
};

export const updateShipmentReason = async (req: UpdateReasonRequest) => {
  const res = await Axios.patch(`/cart-service/api/shipments/${req.shipmentId}/reason`, {
    reason: req.reason,
  });
  return res.data;
};

export const updateShipmentNote = async (req: UpdateNoteRequest) => {
  const res = await Axios.patch(`/cart-service/api/shipments/note/bulk`, {
    shipmentIds: req.ids,
    note: req.note,
  });
  return res.data;
};

export const getCustomerAvatar = (customer_ids: string[]) => async (): Promise<{ [id: string]: string }> => {
  const theRestCustomerIds = [...customer_ids];
  const requestAll = [];

  while (theRestCustomerIds.length > 0) {
    const chunkProductIds = theRestCustomerIds.splice(0, 30);
    const queryString = chunkProductIds.map((o) => `customerIds=${o}`).join('&');

    requestAll.push(Axios.get(`/facebook-service/api/facebook/profile-avatars?${queryString}`));
  }

  const promiseAllRes = await Promise.all(requestAll);

  const formattedRes =
    promiseAllRes?.reduce((acc, res) => {
      return {
        ...acc,
        ...res.data,
      };
    }, {}) || {};

  return formattedRes;
};

export const updateAddressInOrder = async (orderId: string, req: UpdateAddressInOrderRequest) => {
  const res = await Axios.patch(`/cart-service/api/orders/${orderId}/delivery-address`, req);
  return res.data;
};

export const mergeOrders = async (
  data: { deliveryFee: number; recipientName: string; deliveryInstruction: string; childIds: string[] }[]
) => {
  const res = await Axios.post(`/cart-service/api/orders/merge`, {
    mergeOrders: data,
  });
  return res.data;
};

export const updateDeliveryInstruction = async (data: { orderId: string; deliveryInstruction: string }) => {
  const res = await Axios.put('/cart-service/api/orders/delivery-instruction', data);
  return res.data;
};

export const unMergeOrders = async (shipmentId: string) => {
  const res = await Axios.post('/cart-service/api/shipments/un-merge', {
    masterShipmentId: shipmentId,
  });
  return res.data;
};

export const updateInternalNote = async (data: { orderId: string; internalNote: string }) => {
  const res = await Axios.post('/cart-service/api/orders/internal-note', data);
  return res.data;
};
