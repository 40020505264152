import { Layout } from 'antd';
import React from 'react';

import UserSpace from 'models/UserSpace';
import { CURRENT_SHOP_STORAGE_KEY } from 'utils/constants';
import { useHeader } from './hooks';
import TopActions from 'shared-components/layout/TopActions';
import UserSpaceSelect from 'shared-components/UserSpaceSelect';
import { removeCurrentAnalyticsFilter, removeCurrentLiveSessionFilter } from 'utils/functions';
import { removeCurrentItemsSoldFilterField } from 'utils/storage';

const { Header: AHeader } = Layout;

const handleChangeUserSpace = (newUserSpage?: UserSpace, noReload = false) => {
  if (!newUserSpage) {
    localStorage.removeItem(CURRENT_SHOP_STORAGE_KEY);
  } else {
    localStorage.setItem(CURRENT_SHOP_STORAGE_KEY, JSON.stringify(newUserSpage));
  }
  removeCurrentLiveSessionFilter();
  removeCurrentItemsSoldFilterField('pageIds');
  removeCurrentAnalyticsFilter();

  !noReload && window.location.reload();
};

const Header: React.FC = () => {
  const { title } = useHeader();

  return (
    <>
      <AHeader className="bg-white text-xl flex-none flex justify-between items-center px-5">
        <span className="flex items-center text-base font-medium">{title}</span>
        <span className="flex items-center gap-4">
          <UserSpaceSelect onChange={handleChangeUserSpace} />
          <TopActions />
        </span>
      </AHeader>
    </>
  );
};

export default Header;
